export const openLocationOnMap = (lat = '', lon = ''): void => {
  if (
    /* if we're on iOS, open in Apple Maps */
    navigator.platform.indexOf('iPhone') !== -1 ||
    navigator.platform.indexOf('iPad') !== -1 ||
    navigator.platform.indexOf('iPod') !== -1
  )
    window.location.href = `maps://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`;
  /* else use Google */ else
    window.location.href = `https://maps.google.com/?q=${lat},${lon}`;
};
