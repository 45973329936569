import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RightArrow } from '../../../assets/svgs';
import { useIsDesktop } from '../../../hooks/useIsDesktop';
import { Container } from '../components';
import {
  formatDate,
  LAST_PAYOUT_RANGE_DATE_FORMAT,
} from '../../../utils/format-date';

interface IWeekPayoutBlockProps {
  data: any;
  label: string;
}

const WeekPayoutBlock: React.FC<IWeekPayoutBlockProps> = ({ data, label }) => {
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();

  return (
    <Container
      theme={theme}
      style={{ minHeight: isDesktop ? '380px' : '120px' }}
      onClick={() => {
        navigate('/perfil/all-payouts');
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={isDesktop ? 'center' : 'start'}
        sx={{ marginBottom: isDesktop ? '93px' : '10px' }}
      >
        <Typography fontWeight="bold">{label}</Typography>

        <Box height="16px" width="16px">
          <RightArrow />
        </Box>
      </Stack>

      <Stack spacing="0.5rem">
        {data.amount ? (
          <Typography
            variant="h2"
            color={'#20D73E'}
          >{`+${data.amount}€`}</Typography>
        ) : (
          <Typography variant="h2">{'0€'}</Typography>
        )}
        <Typography variant="caption">{`${formatDate(
          data.startTimestamp,
          LAST_PAYOUT_RANGE_DATE_FORMAT
        )} - ${formatDate(
          data.endTimestamp,
          LAST_PAYOUT_RANGE_DATE_FORMAT
        )}`}</Typography>
      </Stack>
    </Container>
  );
};

export default WeekPayoutBlock;
