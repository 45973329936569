import Http from './http.common';

class NotificationsService {
  apiUrl = '/notifications';

  async getAllNotifications(chunkSize = 20, maxRecords = 1000) {
    const notifications = [];
    let page = 1;

    while (true) {
      if (notifications.length >= maxRecords) {
        break;
      }

      const { data: batch } = await this.getNotifications(page, chunkSize);

      if (!batch.length) {
        break;
      }

      notifications.push(batch);
      page += 1;
    }

    return {
      data: notifications.flat(),
    };
  }

  getNotifications(page?: number, limit?: number) {
    return Http.get(this.apiUrl, {
      params: {
        page,
        limit,
      },
    });
  }

  readMany(ids: number[]) {
    return Http.patch(`${this.apiUrl}/read`, {
      ids,
    });
  }

  deleteNotification(id: string) {
    return Http.delete(this.apiUrl + `/${id}`);
  }
}

export default new NotificationsService();
