import { Box, Skeleton } from '@mui/material';
import { useIsMobile } from '../../hooks/useIsMobile';

const ChatMembersSkeleton = () => {
  const isMobile = useIsMobile();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={2}
      marginTop={2}
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        gap={1}
        flex={1}
      >
        <Skeleton height={42} width={42} variant="circular" />
        <div style={{ flex: 1 }}>
          <Skeleton height={30} />
        </div>
      </Box>
      <Box
        display="flex"
        width="40%"
        flexDirection="row"
        gap={1}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Skeleton height={35} width={25} />
        {!isMobile && <Skeleton height={50} width={200} />}
      </Box>
    </Box>
  );
};

const ChatMembersLoading = () => {
  return (
    <>
      <ChatMembersSkeleton />
      <ChatMembersSkeleton />
      <ChatMembersSkeleton />
      <ChatMembersSkeleton />
      <ChatMembersSkeleton />
    </>
  );
};
export default ChatMembersLoading;
