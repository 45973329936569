import {
  Button,
  Stack,
  Typography,
  useTheme,
  Link as MuiLink,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import BlurredImg from '../../assets/pngs/BlurredBgForVideoLoop.png';
import WhiteLogo from '../../assets/svgs/WhiteLogo.svg';
// @ts-ignore
import BgVideo from '../../assets/videos/bg.mp4';
import { VideoContainer } from './components';

const PreSignUp = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('landing');

  return (
    <Stack
      height="100dvh"
      width="100dvw"
      alignItems="center"
      justifyContent="center"
      padding="3.19rem"
      spacing="1.25rem"
    >
      <VideoContainer
        autoPlay
        loop
        muted
        playsInline
        preload="none"
        poster={BlurredImg}
      >
        <source src={BgVideo} type="video/mp4" />
      </VideoContainer>

      <Link to={`/`} replace className="navbar-brand w-nav-brand">
        <img src={WhiteLogo} loading="lazy" alt="logo" />
      </Link>

      <Typography
        sx={{ zIndex: 1, color: '#fff' }}
        variant="h2"
        textAlign="center"
      >
        {t('Title')}
      </Typography>

      <Stack spacing="0.69rem">
        <Button
          sx={{
            padding: '1.19rem 5.38rem',
            fontSize: '1rem !important',
            fontWeight: 700,
            maxHeight: '3.5rem',
            borderRadius: '0.5rem',
            width: '100%',
          }}
          color="primary"
          onClick={() => navigate('/login')}
          size="small"
        >
          {t('Login')}
        </Button>
        <Button
          color="secondary"
          sx={{
            padding: '1rem 1.5rem',
            borderColor: '#fff',
            borderWidth: '0.06rem !important',
            fontSize: '1rem !important',
            fontWeight: 600,
            maxHeight: '3.5rem',
            borderRadius: '0.5rem',
            width: '100%',
            color: '#fff',
          }}
          onClick={() => navigate('/signup')}
        >
          {t('Sign Up')}
        </Button>
        <MuiLink
          href="#"
          sx={{
            color: '#B1B3B3',
            width: '100%',
            textAlign: 'center',
            zIndex: 1,
            fontWeight: 600,
            fontSize: '1rem',
            textDecorationColor: 'rgba(177, 179, 179, 0.4)',
          }}
        >
          {t('Pre Sign Up Invites')}
        </MuiLink>
      </Stack>
    </Stack>
  );
};

export default PreSignUp;
