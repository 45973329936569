// import IUser from "../types/user.type";
import Http from './http.common';

class FriendsService {
  apiUrl = '/invites';

  getReferralCode() {
    return Http.get(this.apiUrl + '/referral-code');
  }

  sendEmail(email: string) {
    return Http.post(this.apiUrl + '/send', { email });
  }

  getPendingCount() {
    return Http.get(this.apiUrl + '/', {
      params: { page: 1, limit: 3, status: 'PENDING' },
    });
  }

  getAcceptedCount() {
    return Http.get(this.apiUrl + '/', {
      params: { page: 1, limit: 3, status: 'ACCEPTED' },
    });
  }

  resendEmail(inviteId: number) {
    return Http.post(this.apiUrl + `/${inviteId}/re-send`);
  }

  getPending() {
    return Http.get(this.apiUrl + '/', {
      params: { page: 1, limit: 100, status: 'PENDING' },
    });
  }

  getReferrals() {
    return Http.get('/referrals', {
      params: { page: 1, limit: 100 },
    });
  }

  getAccepted() {
    return Http.get(this.apiUrl + '/', {
      params: { page: 1, limit: 100, status: 'ACCEPTED' },
    });
  }
}

export default new FriendsService();
