import { useMediaQuery } from 'react-responsive';

import breakpoints from '../theme/breakpoints';

export const mobileOnly = `@media (max-width: ${breakpoints.mobile})`;

export const useIsMobile = () =>
  useMediaQuery({
    query: `(max-width: ${breakpoints.mobile})`,
  });
