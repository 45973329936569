import { Box, useTheme } from '@mui/material';
import React, { createContext, FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavHeader from '../components/NavHeader';
import { useIsDesktop } from '../hooks/useIsDesktop';

interface IAuthLayout {
  children: React.ReactElement;
  withBorders?: boolean;
  withBackArrow?: boolean;
  withHeader?: boolean;
}

interface IMobileLogoAppBarContext {
  shouldShowArrow?: boolean;
  setShouldShowArrow?: (prop: boolean) => void;
  setFunctionOnBack?: any;
}

const MobileLogoAppBarContext = createContext<IMobileLogoAppBarContext>({});
export { MobileLogoAppBarContext };

const AuthLayout: FC<IAuthLayout> = ({
  children,
  withBorders = false,
  withHeader = true,
  withBackArrow = false,
}: IAuthLayout) => {
  const isDesktop = useIsDesktop();
  const theme = useTheme();
  const navigate = useNavigate();
  const [shouldShowArrow, setShouldShowArrow] = useState(false);
  const [functionOnBack, setFunctionOnBack] = useState(() => navigate(-1));

  return (
    <MobileLogoAppBarContext.Provider
      value={{
        shouldShowArrow: shouldShowArrow,
        setShouldShowArrow: setShouldShowArrow,
        setFunctionOnBack: setFunctionOnBack,
      }}
    >
      {withHeader && (
        <NavHeader
          withBackArrow={withBackArrow && shouldShowArrow}
          functionOnBack={functionOnBack}
        />
      )}
      <Box
        sx={{
          marginTop: withHeader ? '110px' : '48px',
          padding: '0 16px',
          marginRight: 'auto',
          marginLeft: 'auto',
          maxWidth: '640px',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          display: 'flex',
          ...(isDesktop &&
            withBorders && {
              border: `2px solid ${theme.palette.customGrey.dark}`,
              borderRadius: '24px',
              padding: '48px 80px 80px 80px',
              position: 'relative',
            }),
        }}
      >
        {children}
      </Box>
    </MobileLogoAppBarContext.Provider>
  );
};

export default AuthLayout;
