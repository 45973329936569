import { Avatar, Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { RightArrow } from '../../../assets/svgs';
import { useIsDesktop } from '../../../hooks/useIsDesktop';
import { nameToAvatar, nameToColor } from '../../../utils/avatar';
import { Container } from '../components';
import { formatDate, REFERRAL_DATE_FORMAT } from '../../../utils/format-date';
import { useNavigate } from 'react-router-dom';

interface IReferralUsersBlock {
  users: any[];
  label: string;
}

const UserBlock: React.FC<any> = ({ user }) => {
  const isDesktop = useIsDesktop();

  return (
    <Box display="flex" marginBottom="16px">
      <Avatar
        className="small"
        sx={{
          marginRight: isDesktop ? '16px' : '-7px',
          bgcolor: nameToColor(user.referral.name),
        }}
        src={user.referral.avatarUrl}
        {...nameToAvatar(user.referral.name)}
      />
      {isDesktop && (
        <Box textAlign="left" display="flex" flexDirection="column">
          <Typography fontWeight="bold">{user.referral.name}</Typography>
          <Typography variant="caption" color="neutral.main">
            {formatDate(user.createdAt, REFERRAL_DATE_FORMAT)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const ReferralUsersBlock: React.FC<IReferralUsersBlock> = ({
  users,
  label,
}) => {
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();

  return (
    <Container
      theme={theme}
      style={{ minHeight: isDesktop ? '380px' : '120px', cursor: 'pointer' }}
      onClick={() => {
        navigate('/perfil/estadisticas');
      }}
    >
      <Stack direction="row" alignItems="start">
        <Typography fontWeight="bold" lineHeight="1.05rem">
          {label}
        </Typography>

        <Box height="16px" width="16px">
          <RightArrow />
        </Box>
      </Stack>
      <Box
        sx={{ overflow: 'scroll', maxHeight: '230px' }}
        display="flex"
        marginTop={isDesktop ? '32px' : '12px'}
        flexDirection={isDesktop ? 'column' : 'row'}
      >
        {users?.length > 0 &&
          users.map((user, id) => <UserBlock user={user} key={`user-${id}`} />)}
      </Box>
    </Container>
  );
};

export default ReferralUsersBlock;
