import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import SendDMButton from '../Chats/SendDMButton';

const AvatarWrapper = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const CloseIconWrapper = styled(IconButton)`
  position: absolute !important;
  right: 5px;
  top: 5px;
  height: auto !important;
  width: auto !important;
  cursor: pointer;
  padding: 0 !important;
`;

const BigAvatarDialog = ({
  isOpen,
  onClose,
  avatar,
  title,
  userId,
  letter,
  isDesktop,
  showSendDmButton,
  onSendDm,
}: {
  isOpen: boolean;
  onClose: () => void;
  avatar?: string;
  title: string;
  userId: number;
  letter?: string;
  isDesktop: boolean;
  showSendDmButton: boolean;
  onSendDm: (chatId: number) => void;
}) => {
  const width = isDesktop ? '325px !important' : '175px !important';
  const height = isDesktop ? '325px !important' : '175px !important';
  const fontSize = isDesktop ? '6rem' : '4rem';
  const iconSize = `${isDesktop ? '44px' : '40px'} !important`;

  const handleSendDmSuccess = (chatId: number) => {
    onClose();
    onSendDm(chatId);
  };

  return (
    <Dialog maxWidth="xs" open={isOpen} onClose={onClose}>
      <DialogTitle
        sx={{
          padding: 0,
        }}
      >
        <CloseIconWrapper aria-label="close" onClick={onClose} disableRipple>
          <CloseIcon sx={{ width: iconSize, height: iconSize }} />
        </CloseIconWrapper>
      </DialogTitle>
      <div style={{ padding: '10px' }}>
        <DialogContent>
          <AvatarWrapper>
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              gap="8px"
              alignItems="center"
              justifyContent="center"
            >
              <Avatar
                src={avatar}
                sx={{
                  width: width,
                  height: height,
                  fontSize: fontSize,
                }}
              >
                {letter}
              </Avatar>
              <Typography
                variant="h3"
                color="secondary.contrastText"
                sx={{
                  fontSize: `${isDesktop ? '30px' : '22px'} !important`,
                  fontWeight: 700,
                  width: '100%',
                  overflowX: 'clip',
                  textOverflow: 'ellipsis',
                  textAlign: 'center',
                }}
              >
                {title}
              </Typography>
            </Box>
            {showSendDmButton && (
              <SendDMButton
                userId={userId}
                onSuccess={handleSendDmSuccess}
                iconOnly={false}
              />
            )}
          </AvatarWrapper>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default BigAvatarDialog;
