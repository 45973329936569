import { ArrowBackIos } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import LogoBlack from '../assets/svgs/logo-black.svg';
import LogoWhite from '../assets/svgs/logo-white.svg';
import { useIsDesktop } from '../hooks/useIsDesktop';

interface INavHeaderProps {
  withBackArrow?: boolean;
  functionOnBack?: any;
}

const NavHeader: React.FC<INavHeaderProps> = ({
  withBackArrow = false,
  functionOnBack,
}) => {
  const theme = useTheme();
  const isDesktop = useIsDesktop();

  return (
    <div className="navbar wf-section">
      <div
        data-animation="default"
        data-collapse="medium"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar-container w-nav"
      >
        <div className="container-regular">
          <div className="navbar-wrapper">
            {!isDesktop && withBackArrow && (
              <ArrowBackIos
                sx={{
                  position: 'absolute',
                  margin: 'auto 0',
                  left: 0,
                  cursor: 'pointer',
                }}
                onClick={functionOnBack}
              />
            )}
            <Link to={`/`} replace className="navbar-brand w-nav-brand">
              <img
                src={theme.palette.mode === 'dark' ? LogoWhite : LogoBlack}
                loading="lazy"
                alt=""
                className="doss-logo-black"
              />
            </Link>
            <div className="menu-button w-nav-button">
              <div className="hide--display w-icon-nav-menu"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
