import { Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BackIcon, BackIconDark } from '../../assets/svgs';
import { PayoutsSkeleton } from '../../components/Payouts';
import { desktopOnly } from '../../hooks/useIsDesktop';
import payoutsService from '../../services/payouts.service';

import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {
  formatDate,
  LAST_PAYOUT_RANGE_DATE_FORMAT,
} from '../../utils/format-date';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 48px 16px;

  ${desktopOnly} {
    padding: 64px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
`;

enum EStatus {
  Created = 'created',
  Failed = 'failed',
  Succeeded = 'succeeded',
  Action_Needed = 'action_needed',
}

interface IPayoutsData {
  id: number;
  amount: number;
  status: EStatus;
  createdAt: string;
}

interface IPayouts {
  data: IPayoutsData[];
  total: number;
}

const initialPayouts = { data: [], total: 0 };

const setStatusIcon = (status: string) => {
  switch (status) {
    case EStatus.Succeeded:
      return <CheckCircleIcon sx={{ color: '#4cb542' }} />;
    case EStatus.Failed:
      return <ErrorIcon sx={{ color: '#D10000' }} />;
    case EStatus.Created:
      return <HourglassEmptyIcon sx={{ color: '#147EFB' }} />;
    default:
      return <ReportProblemIcon sx={{ color: '#ff9c29' }} />;
  }
};

const AllPayouts = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('profile');

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [payouts, setPayouts] = useState<IPayouts>(initialPayouts);
  const theme = useTheme();

  const setStatusText = (status: string) => {
    switch (status) {
      case EStatus.Succeeded:
        return t('influencer.payouts.statuses.succeeded');
      case EStatus.Failed:
        return t('influencer.payouts.statuses.failed');
      case EStatus.Created:
        return t('influencer.payouts.statuses.created');
      default:
        return t('influencer.payouts.statuses.action_needed');
    }
  };

  const getData = async () => {
    try {
      const res = await payoutsService.getAllPayouts();
      if (res.data) {
        setPayouts(res.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <RowContainer>
        <div style={{ flex: 1 }}>
          {theme.palette.mode === 'dark' ? (
            <BackIconDark
              onClick={() => navigate('/perfil/influencer-dashboard')}
            />
          ) : (
            <BackIcon
              onClick={() => navigate('/perfil/influencer-dashboard')}
            />
          )}
        </div>
        <Typography variant="h1" sx={{ fontSize: '24px', fontWeight: 800 }}>
          {t('influencer.allPayouts')}
        </Typography>
        <div style={{ flex: 1 }} />
      </RowContainer>
      {isLoading ? (
        <PayoutsSkeleton />
      ) : payouts.total === 0 ? null : (
        payouts.data.map(({ id, status, amount, createdAt }) => (
          <RowContainer key={id}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Stack direction="row" alignItems="center" spacing="20px">
                {setStatusIcon(status)}
                <Stack>
                  {setStatusText(status)}

                  <Typography variant="caption" color="#B3B3B3">
                    {formatDate(
                      new Date(createdAt).getTime(),
                      LAST_PAYOUT_RANGE_DATE_FORMAT
                    )}
                  </Typography>
                </Stack>
              </Stack>

              <Typography>{`+ ${amount} EUR`}</Typography>
            </Stack>
          </RowContainer>
        ))
      )}
    </Container>
  );
};

export default AllPayouts;
