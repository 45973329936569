import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Timer = ({ endDate }: { endDate: string }) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const getTime = () => {
    // @ts-ignore
    const time = new Date(endDate) - Date.now();

    setHours(Math.floor(time / (1000 * 60 * 60)));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    //@ts-ignore
    const interval = setInterval(() => getTime(endDate), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {!!hours ? (
        <Typography
          variant="h5"
          sx={{
            fontSize: '16px !important',
            padding: '4px 12px',
            borderRadius: '24px',
          }}
          color="customRed.contrastText"
          bgcolor="customRed.main"
        >
          {hours}:{minutes}:{seconds}
        </Typography>
      ) : null}
    </>
  );
};

export default Timer;
