import Http from './http.common';

class LocationsService {
  apiUrl = '/locations';

  getLocationsList() {
    const options: any = { params: { page: 1, limit: 100 } };

    return Http.get(this.apiUrl + '/', options);
  }
}

export default new LocationsService();
