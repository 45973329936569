import { Box, Skeleton, SkeletonProps, SxProps } from '@mui/material';
import React, { FC } from 'react';
import { useIsDesktop } from '../../hooks/useIsDesktop';

interface IProfileMenuLoadingItem {
  renderThird?: boolean;
  sx?: SxProps;
  thirdProps?: SkeletonProps;
}

export const ProfileMenuLoadingItem: FC<IProfileMenuLoadingItem> = ({
  renderThird = false,
  sx,
  thirdProps,
}: IProfileMenuLoadingItem) => {
  const isDesktop = useIsDesktop();

  const modifiedSx = sx || {};

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        gap: '10px',
        alignItems: 'center',
        height: isDesktop ? '60px' : '45px',
        paddingLeft: '15px',
        ...modifiedSx,
      }}
    >
      <Skeleton
        variant="circular"
        width={isDesktop ? 35 : 20}
        height={isDesktop ? 35 : 20}
      />
      <Skeleton width={200} height={isDesktop ? 40 : 20} />
      {renderThird ? (
        <Skeleton
          width={60}
          height={40}
          {...thirdProps}
          sx={{ marginLeft: 'auto' }}
        />
      ) : !isDesktop ? (
        <Skeleton width={20} height={40} sx={{ marginLeft: 'auto' }} />
      ) : null}
    </Box>
  );
};
