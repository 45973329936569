import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import countryOptions from '../MuiPhoneInput/data.json';
import MuiPhone from '../MuiPhoneInput/MuiPhone';
import { useTranslation } from 'react-i18next';

const PhoneInput = () => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');
  let phoneNumber = searchParams.get('phoneNumber');
  if (phoneNumber && !phoneNumber.startsWith('+')) {
    phoneNumber = `+${phoneNumber}`;
  }

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .min(11, t('validation.wrongPhone') as any)
      .max(13, t('validation.wrongPhone') as any)
      .matches(/^\+\d+$/, t('validation.wrongPhone') as any)
      .required(t('validation.phoneRequired') as any),
  });

  const handleSubmit = (values: { phone: string }) => {
    navigate(`/verification?phoneNumber=${values.phone || phoneNumber}`);
  };

  return (
    <>
      <Box
        sx={{
          textAlign: 'center',
          marginBottom: '56px',
        }}
      >
        <Typography variant={'h1'}>{t('login.title')}</Typography>
        <Typography variant={'h3'}>{t('login.description')}</Typography>
      </Box>
      <Formik
        initialValues={{
          phone: phoneNumber || '+34',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => handleSubmit(values)}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isValid,
          dirty,
          setFieldValue,
        }) => (
          <form
            style={{ width: '100%' }}
            onSubmit={handleSubmit}
            id="wf-form-Registration-form---Telephone"
            name="wf-form-Registration-form---Telephone"
            data-name="Registration form - Telephone"
          >
            <FormControl
              fullWidth
              error={
                (values.phone === phoneNumber && !!error) ||
                (!!errors.phone && !!touched.phone)
              }
            >
              <MuiPhone
                name="phone"
                value={values.phone}
                label={t('login.phoneInputLabel')}
                countryOptions={countryOptions}
                onChange={setFieldValue}
                error={
                  (values.phone === phoneNumber && error) ||
                  (!!errors.phone && !!touched.phone)
                }
              />
              {((values.phone === phoneNumber && error) ||
                (errors.phone && touched.phone)) && (
                <FormHelperText id="phone" error>
                  {error || errors.phone}
                </FormHelperText>
              )}
            </FormControl>
            <Button
              type="submit"
              disabled={!(isValid && dirty)}
              fullWidth
              color="primary"
            >
              {t('login.signInButtonText')}
            </Button>
            {!isDesktop && (
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h3" sx={{ marginTop: '16px' }}>
                  {t('login.notRegisteredText')}{' '}
                  <a href="/signup?step=1">{t('login.register')}</a>
                </Typography>
              </Box>
            )}
          </form>
        )}
      </Formik>
    </>
  );
};

export default PhoneInput;
