import { Avatar, Box, Badge, useTheme } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EColorModes } from '../App';
import LogoBlack from '../assets/svgs/logo-black.svg';
import LogoWhite from '../assets/svgs/logo-white.svg';
import {
  BellIcon,
  ChatsIcon,
  EventsIcon,
  FriendsIcon,
  TicketIcon,
} from '../assets/svgs';
import LanguagesSelect from '../components/localizations/LanguagesSelect';
import { useTypedSelector } from '../store/store';
import { nameToAvatar, nameToColor } from '../utils/avatar';
import { UNREAD_COUNT_BADGE_LIMIT } from '../utils/notifications';

enum ERoutesNames {
  offers = '/ofertas',
  events = '/eventos',
  chats = '/chats',
  friends = '/invitaramigos',
  notifications = '/notificaciones',
}

const TopAppLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const { user } = useTypedSelector((state) => state.auth);
  const { unreadCount } = useTypedSelector((state) => state.notification);

  return (
    <Paper
      sx={{
        boxShadow: 'none',
        backgroundImage: 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'row',
        gap: '36px',
        width: '100%',
        padding: '16px 64px',
        alignItems: 'center',
        maxHeight: 96,
        zIndex: 999,
      }}
      elevation={3}
    >
      <Box flex={1}>
        <img
          src={theme.palette.mode === EColorModes.light ? LogoBlack : LogoWhite}
          alt="DOSS Logo"
          style={{ maxHeight: '56px', maxWidth: '147px', paddingLeft: '16px' }}
          onClick={() => navigate('/ofertas')}
        />
      </Box>
      <Box flex={3}></Box>
      <Box
        flex={2}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <BottomNavigation
          value={pathname}
          sx={{
            alignItems: 'center',
            '& .MuiBottomNavigationAction-root': {
              minWidth: 'unset',
              paddingBottom: '0',
            },
          }}
          onChange={(event, newValue) => {
            navigate(newValue);
          }}
        >
          <LanguagesSelect variant="desktop" />
          <BottomNavigationAction
            value={ERoutesNames.offers}
            icon={<TicketIcon />}
          />
          <BottomNavigationAction
            value={ERoutesNames.events}
            icon={<EventsIcon />}
          />
          <BottomNavigationAction
            value={ERoutesNames.chats}
            icon={<ChatsIcon />}
          />
          <BottomNavigationAction
            value={ERoutesNames.friends}
            icon={<FriendsIcon />}
          />
          <BottomNavigationAction
            value={ERoutesNames.notifications}
            icon={
              unreadCount ? (
                <Badge
                  sx={{
                    '& .MuiBadge-badge': {
                      color: theme.palette.customRed.contrastText,
                      backgroundColor: theme.palette.customRed.main,
                    },
                  }}
                  badgeContent={unreadCount}
                  max={UNREAD_COUNT_BADGE_LIMIT}
                >
                  <BellIcon />
                </Badge>
              ) : (
                <BellIcon />
              )
            }
          />
        </BottomNavigation>
        <div onClick={() => navigate('/perfil')}>
          <Avatar
            sx={{
              width: '56px !important',
              height: '56px !important',
              marginLeft: '20px !important',
              cursor: 'pointer',
              bgcolor: nameToColor(user?.name),
            }}
            src={user?.avatarUrl}
            {...nameToAvatar(user?.name)}
          />
        </div>
      </Box>
    </Paper>
  );
};

export default TopAppLayout;
