import { Avatar, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BackIcon, BackIconDark, PresentIcon } from '../../assets/svgs';
import FriendsLoading from '../../components/Friends/FriendsLoading';
import { desktopOnly } from '../../hooks/useIsDesktop';
import friendsService from '../../services/friends.service';
import { useTranslation } from 'react-i18next';
import { nameToAvatar, nameToColor } from '../../utils/avatar';
import { formatDate, REFERRAL_DATE_FORMAT } from '../../utils/format-date';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 48px 16px;

  ${desktopOnly} {
    padding: 64px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
`;

const InfluencerAcceptedInvitationsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('profile');

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [accepted, setAccepted] = useState<any>([]);
  const theme = useTheme();

  const getData = async () => {
    try {
      const res = await friendsService.getReferrals();
      if (res.data) {
        setAccepted(res.data?.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <RowContainer>
        <div style={{ flex: 1 }}>
          {theme.palette.mode === 'dark' ? (
            <BackIconDark
              onClick={() => navigate('/perfil/influencer-dashboard')}
            />
          ) : (
            <BackIcon
              onClick={() => navigate('/perfil/influencer-dashboard')}
            />
          )}
        </div>
        <Typography variant="h1" sx={{ fontSize: '24px', fontWeight: 800 }}>
          {t('influencer.acceptedInvitations')}
        </Typography>
        <div style={{ flex: 1 }} />
      </RowContainer>
      {isLoading ? (
        <FriendsLoading />
      ) : (
        accepted.map(
          (
            usr: {
              avatarUrl: string | undefined;
              referral: any;
              email: string;
              createdAt: string;
            },
            id: number
          ) => (
            <RowContainer key={'accepted-' + id}>
              <Avatar
                alt="Avatar"
                src={usr?.referral?.avatarUrl}
                sx={{
                  width: '40px !important',
                  height: '40px !important',
                  bgcolor: nameToColor(usr?.referral?.name),
                }}
                {...nameToAvatar(usr?.referral?.name)}
              />
              <div style={{ flex: 1 }}>
                <Typography
                  variant="h3"
                  color="secondary.contrastText"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                  }}
                >
                  {usr?.referral?.name || usr.email}
                </Typography>
                <Typography
                  variant="h5"
                  color="neutral.dark"
                  sx={{
                    fontSize: '12px',
                    fontWeight: 700,
                  }}
                >
                  {formatDate(usr?.createdAt, REFERRAL_DATE_FORMAT)}
                </Typography>
              </div>
            </RowContainer>
          )
        )
      )}
    </Container>
  );
};

export default InfluencerAcceptedInvitationsPage;
