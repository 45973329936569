import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import PrevArrow from '../../assets/svgs/Arrow Left.svg';
import NextArrow from '../../assets/svgs/Arrow right.svg';
import Case1 from '../../assets/pngs/Rectangle 1271.png';
import Case2 from '../../assets/pngs/Rectangle 1272.png';
import Case3 from '../../assets/pngs/Rectangle 1273.png';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import './index.css';

const options = {
  type: 'loop',
  pagination: false,
  perPage: 3,
  arrows: false,
  gap: '32px',
  breakpoints: {
    1250: {
      arrows: false,
      gap: '22px',
    },
    400: {
      arrows: false,
      gap: '22px',
    },
  },
};

const mobileOptions = {
  type: 'loop',
  pagination: false,
  perPage: 1,
  arrows: false,
  gap: '32px',
  breakpoints: {
    1250: {
      arrows: false,
      gap: '22px',
    },
    400: {
      arrows: false,
      gap: '22px',
    },
  },
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    paddingLeft: '64px',
    paddingRight: '64px',
  },
  element: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderRadius: '22px',
    borderColor: '#ffffff',
  },
  image: {
    width: '100%',
    height: '269px',
    borderRadius: '22px',
    objectFit: 'cover',
    marginBottom: '14px',
  },
  buttonsContainer: {
    display: 'flex',
    paddingTop: 32,
    gap: 24,
    justifyContent: 'center',
    marginBottom: 96,
  },
}));

const SliderElement = (props: any) => {
  const { Img } = props;
  const { t } = useTranslation('landing');
  const classes = useStyles();

  return (
    <div className={classes.element}>
      <div>
        <Typography
          sx={{
            fontSize: '16px !important',
            fontWeight: 800,
            position: 'relative',
            top: '10%',
            left: '3%',
            width: 'fit-content',
            backgroundColor: '#1D1D1D',
            borderRadius: '34px',
            padding: '8px 12px',
          }}
          color="primary"
        >
          {t('Slider Label')}
        </Typography>
        <img src={Img} loading="lazy" className={classes.image} />
        <Typography
          sx={{
            fontSize: '28px !important',
            fontWeight: 800,
            marginBottom: '8px',
          }}
          variant="h2"
        >
          {t('Slider Title')}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px !important',
            fontWeight: 400,
            marginBottom: '16px',
          }}
          color="neutral.dark"
          variant="h2"
        >
          {t('Slider Subtitle')}
        </Typography>
        <Typography
          sx={{ fontSize: '20px', fontWeight: 700, marginBottom: '8px' }}
          color="primary"
          variant="h3"
        >
          {t('Slider Text1')}
        </Typography>
        <Typography
          sx={{ fontSize: '20px', fontWeight: 600, marginBottom: '8px' }}
          variant="h3"
        >
          {t('Slider Text2')}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px !important',
            fontWeight: 400,
            marginBottom: '8px',
          }}
          color="neutral.dark"
          variant="h2"
        >
          <Trans i18nKey="Slider Text3" t={t} />
        </Typography>
      </div>
    </div>
  );
};

const OffersSlider = () => {
  const classes = useStyles();
  const isDesktop = useIsDesktop();

  return (
    <Splide
      className="section-blog-slider"
      hasTrack={false}
      options={isDesktop ? options : mobileOptions}
    >
      <SplideTrack
        className="w-dyn-list"
        style={{ width: '100%', maxWidth: 'none' }}
      >
        <SplideSlide className="w-dyn-item">
          <SliderElement Img={Case1} />
        </SplideSlide>
        <SplideSlide className="w-dyn-item">
          <SliderElement Img={Case2} />
        </SplideSlide>
        <SplideSlide className="w-dyn-item">
          <SliderElement Img={Case3} />
        </SplideSlide>
      </SplideTrack>
      {isDesktop && (
        <div className={classes.buttonsContainer}>
          <button>
            <img src={PrevArrow} alt="Prev" />
          </button>
          <button>
            <img src={NextArrow} alt="Next" />
          </button>
        </div>
      )}
    </Splide>
  );
};

export default OffersSlider;
