import { Typography } from '@mui/material';
import * as React from 'react';
import { useContext, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import OfferListItem from '../../components/Offers/OfferListItem';
import OffersListLoading from '../../components/Offers/OffersLoading';
import OfferListItemSkeleton from '../../components/Offers/OffersLoading';
import Search from '../../components/Search';
import { SearchContext } from '../../contexts/UserContext';
import { EOfferType } from '../../enums/offer-type.enum';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import TopAuthMobileLayout from '../../layouts/TopAuthMobileLayout';
import offersService from '../../services/offers.service';
import { useTypedSelector } from '../../store/store';
import { Container } from './components';
import { useTranslation } from 'react-i18next';
import Filters from './Filters';

enum EOffersList {
  Offers = 'offers',
  Filters = 'filters',
  IsLoading = 'isLoading',
  SelectedFilters = 'selectedFilters',
  UnselectFilter = 'unselectFilter',
}

type OffersListAction =
  | {
      type: EOffersList.Offers;
      payload: IOfferListItem[];
    }
  | {
      type: EOffersList.Filters | EOffersList.UnselectFilter;
      payload: string[];
    }
  | {
      type: EOffersList.SelectedFilters;
      payload: string;
    }
  | { type: EOffersList.IsLoading; payload: boolean };

interface OffersListState {
  filters: string[];
  offers: IOfferListItem[];
  isLoading: boolean;
  selectedFilters: string[];
}

const offersReducer = (state: OffersListState, action: OffersListAction) => {
  const { type, payload } = action;
  switch (type) {
    case EOffersList.Offers:
      return {
        ...state,
        offers: payload,
      };
    case EOffersList.Filters:
      return {
        ...state,
        filters: payload,
      };
    case EOffersList.IsLoading:
      return {
        ...state,
        isLoading: payload,
      };
    case EOffersList.SelectedFilters:
      return {
        ...state,
        selectedFilters: [...state.selectedFilters, payload],
      };
    case EOffersList.UnselectFilter:
      return {
        ...state,
        selectedFilters: payload,
      };

    default:
      return state;
  }
};

export interface IRestaurant {
  id: string;
  type: string;
  name: string;
}

export interface IOfferListItem {
  imageUrl?: string;
  title: string;
  restaurant: IRestaurant;
  userOffer: any;
  id: string;
  description: string;
  discount?: string;
  like?: string;
  offerType?: EOfferType;
}

const OffersList = () => {
  const { user } = useTypedSelector((state) => state.auth);
  const isDesktop = useIsDesktop();
  const { searchValue, updateSearchValue } = useContext<any>(SearchContext);
  const { t } = useTranslation('offers');

  const [state, dispatchReducer] = useReducer(offersReducer, {
    offers: [],
    filters: [],
    isLoading: false,
    selectedFilters: [],
  });

  const handleSetFilter = (filter: string) => {
    dispatchReducer({ type: EOffersList.SelectedFilters, payload: filter });
  };

  const handleRemoveFilter = (filter: string) => {
    const newSelectedFilters = state.selectedFilters.filter(
      (el) => el !== filter
    );
    dispatchReducer({
      type: EOffersList.UnselectFilter,
      payload: newSelectedFilters,
    });
  };

  const getData = async () => {
    dispatchReducer({ type: EOffersList.IsLoading, payload: true });
    try {
      const res = await offersService.getOffersList(
        searchValue,
        state.selectedFilters,
        user?.locationId
      );

      if (res.data) {
        dispatchReducer({ type: EOffersList.Offers, payload: res.data?.data });
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatchReducer({ type: EOffersList.IsLoading, payload: false });
    }
  };

  const getFilters = async () => {
    dispatchReducer({ type: EOffersList.IsLoading, payload: true });
    try {
      const res = await offersService.getFilters();

      if (res.data) {
        dispatchReducer({ type: EOffersList.Filters, payload: res.data });
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatchReducer({ type: EOffersList.IsLoading, payload: false });
    }
  };

  useEffect(() => {
    getData();

    return () => {
      updateSearchValue({ ...searchValue, title: '' });
    };
  }, []);

  useEffect(() => {
    if (state.offers) {
      getFilters();
    }
  }, [state.selectedFilters]);

  useEffect(() => {
    getData();
  }, [searchValue, state.selectedFilters]);

  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('meta.title')}</title>
        <meta name="description" content={t('meta.description') as any} />
        <link rel="canonical" href="https://app.doss.es/ofertas/" />
      </Helmet>
      {!isDesktop && <TopAuthMobileLayout />}
      <Search />

      <Filters
        filters={state.filters}
        handleSetFilter={handleSetFilter}
        handleRemoveFilter={handleRemoveFilter}
        selectedFilters={state.selectedFilters}
      />

      <>
        <Typography
          variant="h1"
          align={isDesktop ? 'center' : 'left'}
          sx={{
            width: isDesktop ? '100%' : 'auto',
          }}
        >
          {t('title')}
        </Typography>
        {state.isLoading ? (
          <OffersListLoading />
        ) : (
          state.offers.map((el, id) => {
            return state.isLoading ? (
              <OfferListItemSkeleton key={'offer-' + id} />
            ) : (
              <OfferListItem el={el} key={'offer-' + id} />
            );
          })
        )}
      </>
    </Container>
  );
};

export default OffersList;
