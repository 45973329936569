import { Chip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import styled from 'styled-components';
import { desktopOnly } from '../../hooks/useIsDesktop';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 16px;
  padding-bottom: 46px;

  ${desktopOnly} {
    padding: 96px;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
  }
`;

export const LikeImg = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 32px;
  cursor: pointer;
  display: none;
`;

export const ActivatedOfferContainer = styled.div`
  position: absolute;
  top: 120px;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  align-items: start;
`;

export const OfferImage = styled.img`
  width: 100%;
  min-height: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 15px;
  cursor: pointer;

  ${desktopOnly} {
    height: 220px;
  }
`;

export const OfferContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 32px;


  ${desktopOnly} {
    flex-basis: 22%;
    min-width: 216px;
    max-width: 216px;
  }
`;

export const FilterChip = styled(Chip)<{ isVisible: boolean }>`
  padding: 0.375rem 0.75rem !important;
  font-size: 1.5rem !important;
  font-weight: 600;

  transition: all .3s ease-out !important;

  border-color: ${({ isVisible, theme }) =>
          isVisible
                  ? `transparent !important`
                  : `${theme.palette.filterChip.borderColor} !important`};

  background-color: ${({ isVisible, theme }) =>
          isVisible
                  ? `${theme.palette.filterChip.active} !important`
                  : `${theme.palette.filterChip.disabled} !important`};
  color: ${({ isVisible, theme }) =>
          isVisible
                  ? `${theme.palette.filterChip.activeTextColor} !important`
                  : `${theme.palette.filterChip.disabledTextColor} !important`};

  border-radius: 2.25rem !important;
  min-height: 3rem;

  & .MuiChip-label {
    padding: 0;
    margin-left: 0.5rem;
  }

  & svg {
    margin: 0 !important;

    & path {
      transition: fill .3s ease-out !important;
      fill: ${({ isVisible, theme }) =>
              isVisible
                      ? `${theme.palette.filterChip.activeTextColor} !important`
                      : `${theme.palette.filterChip.disabledTextColor} !important`};
    }
  }
`;
