import { MenuItem, Select, SelectProps, SxProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import locationsService from '../../services/locations.service';

type ILocationSelectProps = SelectProps & { sx?: SxProps; itemSx?: SxProps };

const LocationSelect = (props: ILocationSelectProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);

  const { itemSx, ...restProps } = props;

  let modifiedItemSx: SxProps = {
    fontSize: `inherit !important`,
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  };

  if (itemSx) {
    modifiedItemSx = { ...modifiedItemSx, ...itemSx };
  }

  const getLocations = async () => {
    try {
      setLoading(true);
      const results = await locationsService.getLocationsList();

      setOptions(results.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <Select {...restProps} disabled={loading}>
      {options.map((item) => (
        <MenuItem
          key={`location-${item.id}`}
          value={`${item.id}`}
          sx={modifiedItemSx}
        >
          {`${item.city}, ${item.country}`}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LocationSelect;
