import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BackgroundImage from '../../assets/pngs/main-cover.png';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '768px',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  },
  copyButtonContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '48px',
    marginRight: '48px',
    marginTop: '-4%',
    alignItems: 'center',
    '@media (min-width:1007px)': {
      width: '50%',
      alignItems: 'start',
    },
  },
  title: {
    fontSize: '2.5rem !important',
    lineHeight: '2.5rem !important',
    marginBottom: '24px !important',
    fontWeight: '800 !important',
    textAlign: 'center',
    '@media (min-width:1007px)': {
      fontSize: '56px !important',
      lineHeight: '67.2px !important',
      marginBottom: '24px !important',
      fontWeight: '800 !important',
      textAlign: 'left',
    },
  },
  description: {
    fontSize: '20px !important',
    lineHeight: '30px !important',
    marginBottom: '22px !important',
    maxWidth: '507px',
  },
}));

const TextWithCopyButton = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation('landing');

  return (
    <div className={classes.container}>
      <div className={classes.copyButtonContainer}>
        <Typography
          className={classes.title}
          color="primary.contrastText"
          variant="h1"
        >
          {t('Title')}
        </Typography>
        <Typography
          className={classes.description}
          color="primary.contrastText"
          variant="h3"
        >
          {t('Subtitle')}
        </Typography>
        <div>
          <Button
            sx={{ marginTop: '10px', padding: '20px 32px', width: 'auto' }}
            color="primary"
            style={{ backgroundColor: '#1D1D1D' }}
            onClick={() => navigate('/signup')}
          >
            {t('Sign Up')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TextWithCopyButton;
