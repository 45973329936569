import { Chip, Stack } from '@mui/material';
import * as React from 'react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsDesktop } from '../../hooks/useIsDesktop';

import TuneIcon from '@mui/icons-material/Tune';
import { FilterChip } from './components';

interface IFilters {
  filters: string[];
  handleSetFilter: (filter: string) => void;
  handleRemoveFilter: (filter: string) => void;
  selectedFilters: string[];
}

const Filters: FC<IFilters> = ({
  filters,
  handleSetFilter,
  handleRemoveFilter,
  selectedFilters,
}) => {
  const isDesktop = useIsDesktop();
  const { t } = useTranslation('offers');

  const [isVisible, setIsVisible] = useState(!isDesktop);

  const selectedFiltersObject = new Set(selectedFilters);

  const handleChipClick = (filter: string) => {
    if (selectedFiltersObject.has(filter)) {
      handleRemoveFilter(filter);
    } else {
      handleSetFilter(filter);
    }
  };

  return (
    <Stack
      direction="row"
      sx={{
        mt: '0.5rem',
        overflowY: 'hidden',
        overflowX: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      spacing="5px"
    >
      {isDesktop && (
        <FilterChip
          isVisible={isVisible}
          label={t('filters')}
          icon={<TuneIcon sx={{ fontSize: '24px' }} />}
          variant="outlined"
          onClick={() => setIsVisible((prevState) => !prevState)}
        />
      )}

      {isVisible &&
        filters.map((el) => (
          <Chip
            sx={{ minHeight: isDesktop ? '3rem' : '1.56rem' }}
            key={el}
            label={t(`placeType.${el.toLowerCase()}`, { defaultValue: el })}
            className={selectedFiltersObject.has(el) ? 'active' : 'disabled'}
            onClick={() => handleChipClick(el)}
          />
        ))}
    </Stack>
  );
};

export default Filters;
