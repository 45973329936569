import { Box, Skeleton, useTheme } from '@mui/material';
import React from 'react';
import { BackIcon } from '../../assets/svgs';
import {
  Container,
  DesktopContainer,
  ProviderContainer,
  TitleContainer,
} from '../../pages/Events/EventPage/components';

export const DesktopEventLoading = () => {
  return (
    <DesktopContainer>
      <Box sx={{ display: 'flex', width: '100%', gap: '32px' }}>
        <Box flex={0.5}>
          <Skeleton
            width="100%"
            height="420px"
            sx={{ borderRadius: '15px', marginTop: '-100px' }}
            component="div"
          />
        </Box>
        <Box flex={0.5} display="flex" flexDirection="column" gap="10px">
          <Skeleton height={65} width="80%" sx={{ marginTop: '-10px' }} />
          <Box display="flex" gap="10px" alignItems="center">
            <Skeleton
              component="div"
              width="48px"
              height="75px"
              sx={{ marginTop: '-15px', marginBottom: '-15px' }}
            />
            <Skeleton width="50%" />
          </Box>
          <Box display="flex" gap="10px" alignItems="center">
            <Skeleton
              component="div"
              width="48px"
              height="75px"
              sx={{ marginTop: '-15px', marginBottom: '-15px' }}
            />
            <Skeleton width="50%" />
          </Box>
          <Box display="flex" gap="10px" alignItems="center">
            <Skeleton
              component="div"
              width="48px"
              height="75px"
              sx={{ marginTop: '-15px', marginBottom: '-10px' }}
            />
            <Skeleton width="50%" />
          </Box>
          <Skeleton height={120} sx={{ marginTop: '-30px' }} />
        </Box>
      </Box>
      <Skeleton width="70%" sx={{ marginTop: '-25px' }} />
      <Skeleton width="20%" sx={{ marginTop: '-10px' }} />
      <Skeleton width="50%" sx={{ marginTop: '-10px' }} />
      <Box display="flex" flexDirection="row" gap="16px" marginBottom="48px">
        <Skeleton
          width={48}
          height={48}
          variant="circular"
          sx={{ marginTop: '-10px' }}
        />
        <Skeleton
          width={48}
          height={48}
          variant="circular"
          sx={{ marginTop: '-10px' }}
        />
        <Skeleton
          width={48}
          height={48}
          variant="circular"
          sx={{ marginTop: '-10px' }}
        />
      </Box>
    </DesktopContainer>
  );
};

export const MobileEventLoading = () => {
  const theme = useTheme();

  return (
    <Container
      style={{
        backgroundColor: theme.palette.primary.contrastText,
      }}
    >
      <BackIcon
        style={{
          position: 'absolute',
          zIndex: 2,
          margin: '32px 16px',
        }}
      />
      <Skeleton
        width="100%"
        height="470px"
        sx={{
          borderRadius: '15px',
          marginTop: '-120px',
          marginBottom: '-120px',
        }}
        component="div"
      />
      <ProviderContainer
        style={{
          backgroundColor: theme.palette.primary.contrastText,
        }}
      >
        <TitleContainer
          style={{
            backgroundColor: theme.palette.primary.contrastText,
          }}
        >
          <Skeleton width={250} />
          <Skeleton
            component="div"
            width="48px"
            height="75px"
            sx={{
              marginTop: '-15px',
              marginBottom: '-10px',
              marginLeft: 'auto',
            }}
          />
          <Skeleton
            component="div"
            width="48px"
            height="75px"
            sx={{ marginTop: '-15px', marginBottom: '-10px' }}
          />
        </TitleContainer>
        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <Skeleton
            component="div"
            width="48px"
            height="75px"
            sx={{ marginTop: '-15px', marginBottom: '-15px' }}
          />
          <Skeleton width={250} />
        </div>
        <Skeleton />
        <Skeleton width="80%" sx={{ marginTop: '-20px' }} />
        <Skeleton width="20%" />
        <Skeleton width="40%" />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            marginBottom: '48px',
          }}
        >
          <Skeleton
            width={48}
            height={48}
            variant="circular"
            sx={{ marginTop: '-10px' }}
          />
          <Skeleton
            width={48}
            height={48}
            variant="circular"
            sx={{ marginTop: '-10px' }}
          />
          <Skeleton
            width={48}
            height={48}
            variant="circular"
            sx={{ marginTop: '-10px' }}
          />
        </div>
        <Skeleton height={95} sx={{ marginTop: '-30px' }} />
      </ProviderContainer>
    </Container>
  );
};
