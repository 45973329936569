import { ArrowBackIos } from '@mui/icons-material';
import { Box, Icon, Stack, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Avatar, Input } from 'react-chat-elements';
import ChatView from 'react-chatview';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SendIcon, SmileIcon } from '../../assets/svgs';
import BigAvatarDialog from '../../components/BigAvatarDialog';
import LoadingScreen from '../../components/LoadingScreen';
import { ChatType } from '../../enums/chat-type.enum';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import { useIsMobile } from '../../hooks/useIsMobile';
import { BottomBarContext } from '../../layouts/DefaultLayout';
import chatsService from '../../services/chats.service';
import { WebSocketContext } from '../../sockets/WebSocket';
import { useTypedSelector } from '../../store/store';
import {
  ChatContainer,
  ChatHeader,
  MessagesContainer,
  MobileChatContainer,
  MobileChatHeader,
  MobileMessagesContainer,
} from './components';
import { dateStringForDate, messageAdapter, userNickname } from './functions';
import ChatMessage from './Message';
import { IAvatarModalProps } from './types';

interface IChatBlockProps {
  currentChatId: number | null;
  setCurrentChatId: (prop: any) => void;
  chats: any[];
  setChats: (prop: any) => void;
  generateLetterItem: (
    id: string,
    color: string,
    name: string
  ) => { id: string; letter: JSX.Element };
}
const ChatBlock: React.FC<IChatBlockProps> = ({
  currentChatId,
  setCurrentChatId,
  chats,
  setChats,
  generateLetterItem,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const inputStackRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const { t, i18n } = useTranslation('chats');
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const { socket, isReconnecting, addReconnectEmit, removeReconnectEmit } =
    useContext(WebSocketContext);
  const { user } = useTypedSelector((state) => state.auth);
  const { setShouldShow } = useContext(BottomBarContext);

  const [currentChat, setCurrentChat] = useState<any>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(false);
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
  const [paginationState, setPaginationState] = useState({
    total: 0,
    currentCount: 0,
    page: 1,
  });
  const navigate = useNavigate();

  const [avatarModalOptions, setAvatarModalOptions] =
    useState<IAvatarModalProps>({
      open: false,
    });

  const handleChatOpenBigAvatar = () => {
    const title = currentChat?.dmRecipient?.nickname;

    setAvatarModalOptions({
      open: true,
      title: title,
      avatar: currentChat?.imageUrl,
      userId: currentChat?.dmRecipient?.userId,
      showSendDmButton: false,
      letter: title && title[0].toUpperCase(),
    });
  };

  useEffect(() => {
    let isActive = true;

    if (isActive && currentChat) {
      setCurrentChat((chat: any) => {
        const messages = chat.messages.map((item: any) => ({
          ...item,
          dateString: dateStringForDate(item.date, i18n.language),
        }));

        return { ...chat, messages };
      });
    }

    return () => {
      isActive = false;
    };
  }, [i18n.language]);

  const handleScroll = async () => {
    if (!currentChatId) {
      return;
    }

    setIsLoadingMessages(true);
    const { data: messagesData } = await chatsService.getCurrentChatMessages(
      currentChatId,
      30,
      paginationState.page + 1
    );

    if (!messagesData) {
      return setIsLoadingMessages(false);
    }

    const newMessages = [
      ...messagesData.data.map((message: any) =>
        messageAdapter(message, i18n.language, t('justNow'), user?.id)
      ),
      ...currentChat.messages,
    ];

    setCurrentChat((prev: any) => ({ ...prev, messages: newMessages }));
    setPaginationState((prev) => ({
      ...prev,
      page: prev.page + 1,
      currentCount: newMessages.length,
    }));
    setIsLoadingMessages(false);
  };

  const getChat = async () => {
    if (!currentChatId) {
      return;
    }
    setIsLoading(true);

    const { data: chatData } = await chatsService.getCurrentChat(currentChatId);
    const { data: messagesData } = await chatsService.getCurrentChatMessages(
      currentChatId
    );

    if (!chatData || !messagesData) {
      toast.error(t('messageLoadingError'));
      return;
    }

    setPaginationState((prev) => ({
      ...prev,
      total: messagesData.total,
      currentCount: messagesData.data.length,
    }));

    const adaptedMessages = messagesData.data.map((message: any) =>
      messageAdapter(message, i18n.language, t('justNow'), user?.id)
    );

    if (chatData.type === ChatType.Direct) {
      const userForName = (chatData.users as any[]).find(
        (item) => user?.id !== item.user.id
      );

      const userData = userForName.user;

      const name = userNickname(userData?.id, userData?.nickname);
      const imageUrl = userData?.avatarUrl;

      let letterItem: { letter: JSX.Element; id: string } | undefined;

      if (!imageUrl) {
        letterItem = generateLetterItem(userData?.id, userData?.color, name);
      }

      setCurrentChat({
        dmRecipient: { userId: userData?.id, nickname: name },
        ...chatData,
        name,
        imageUrl,
        letterItem,
        messages: adaptedMessages,
      });
    } else {
      setCurrentChat({
        ...chatData,
        messages: adaptedMessages,
      });
    }

    const selectedChat: any = chats.find(
      (chat: any) => chat.id == currentChatId
    );

    if (selectedChat && selectedChat.lastMessageId) {
      await chatsService.readChatMessages(
        currentChatId,
        Number(adaptedMessages[0].id)
      );

      selectedChat.unread = undefined;
      selectedChat.lastMessageId = adaptedMessages[0].id;
      selectedChat.subtitle = `${adaptedMessages[0].title}: ${adaptedMessages[0].text}`;

      setChats([...chats]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (currentChatId) {
      getChat();

      if (!socket) {
        return;
      }

      addReconnectEmit?.call(this, 'join-chat', { id: currentChatId });

      socket.emit('join-chat', { id: currentChatId });

      return () => {
        removeReconnectEmit?.call(this, 'join-chat');
      };
    }
  }, [socket, socket?.connected, currentChatId, isReconnecting]);

  const scrollToBottom = () => {
    lastMessageRef.current?.scrollIntoView({ behavior: 'auto' });
  };

  useEffect(() => {
    if (!socket || !socket.connected) {
      return;
    }

    const newMessageListener = async (message: string) => {
      const parsed = JSON.parse(message);

      if (!currentChatId || String(parsed.chatId) !== String(currentChatId)) {
        return;
      }

      if (String(user?.id) !== parsed.userId) {
        setCurrentChat((prevChat: any) => {
          if (!prevChat) {
            return prevChat;
          }

          const { messages, ...rest } = prevChat;

          return {
            ...rest,
            messages: [
              messageAdapter(parsed, i18n.language, t('justNow'), user?.id),
              ...messages.map((m: any) => ({
                ...m,
                dateString: dateStringForDate(m.date, i18n.language),
              })),
            ],
          };
        });
      }

      await chatsService.readChatMessages(currentChatId, parsed.id);

      setChats((chats: any) => {
        const chatById = chats.find((chat: any) => chat.id === currentChatId);

        if (chatById) {
          chatById.unread -= 1;
          if (chatById.unread === 0) {
            chatById.unread = undefined;
          }
        }

        return [...chats];
      });

      setPaginationState((prev) => ({
        ...prev,
        total: prev.total + 1,
        currentCount: prev.currentCount + 1,
      }));
    };
    socket.on('new-message', newMessageListener);

    return () => {
      socket.off('new-message', newMessageListener);
    };
  }, [socket, socket?.connected, currentChatId]);

  const handleSendMessage = async () => {
    if (inputValue.length === 0) {
      (inputRef?.current as any).focus();
    }

    try {
      setIsSendingMessage(true);

      if (inputRef.current) {
        inputRef.current.value = '';
        inputRef.current.focus();
      }

      setInputValue('');

      await chatsService.sendMessage(currentChatId, inputValue);
    } catch (e: any) {
      toast.error(String(e.message));
    } finally {
      setIsSendingMessage(false);
    }
  };

  const handleMembersCountClick = () => {
    navigate(`/chats/${currentChat?.id}/miembros`);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{currentChat?.name || t('title')}</title>
        <meta name="description" content={currentChat?.name || t('title')} />
        <link rel="canonical" href={'https://app.doss.es/chats/'} />
      </Helmet>
      {isDesktop ? (
        <ChatContainer>
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <>
              <ChatHeader theme={theme}>
                {!isDesktop && (
                  <ArrowBackIos
                    sx={{
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setCurrentChatId(null);
                      if (!isDesktop && setShouldShow) {
                        setShouldShow(true);
                      }
                    }}
                  />
                )}
                <Box
                  sx={{ display: 'flex' }}
                  onClick={
                    currentChat?.type === ChatType.Direct
                      ? () => handleChatOpenBigAvatar()
                      : undefined
                  }
                >
                  <Avatar
                    src={currentChat?.imageUrl}
                    size="xlarge"
                    type="rounded"
                    className="avatar"
                    letterItem={currentChat?.letterItem}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  maxWidth="calc(100% - 100px)"
                >
                  <Typography
                    variant="h3"
                    width="100%"
                    sx={{ overflowX: 'clip', textOverflow: 'ellipsis' }}
                    fontWeight={800}
                    lineHeight="24px"
                  >
                    {currentChat?.name}
                  </Typography>
                  {currentChat.type !== ChatType.Direct && (
                    <Typography
                      variant="caption"
                      fontSize="0.875rem"
                      sx={{
                        cursor: 'pointer',
                      }}
                      color={theme.palette.neutral.main}
                      onClick={handleMembersCountClick}
                    >
                      {t('members', { count: currentChat?.users.length })}
                    </Typography>
                  )}
                </Box>
              </ChatHeader>
              <MessagesContainer>
                <ChatView
                  className="message-list"
                  scrollLoadThreshold={10}
                  flipped={true}
                  shouldTriggerLoad={() =>
                    paginationState.currentCount < paginationState.total &&
                    !isLoadingMessages
                  }
                  onInfiniteLoad={handleScroll}
                >
                  {currentChat?.messages
                    .sort((a: any, b: any) => b.id - a.id)
                    .map((message: any, id: number) => {
                      let shouldRenderAvatar = false;

                      if (id === 0) {
                        shouldRenderAvatar = true;
                      }

                      if (
                        id !== 0 &&
                        message.userId !== currentChat?.messages[id - 1].userId
                      ) {
                        shouldRenderAvatar = true;
                      }

                      return (
                        <React.Fragment key={`message-${id}`}>
                          <ChatMessage
                            shouldRenderAvatar={shouldRenderAvatar}
                            key={`message-${id}`}
                            chatType={currentChat?.type}
                            setCurrentChatId={setCurrentChatId}
                            setAvatarModalOptions={setAvatarModalOptions}
                            {...message}
                          />
                          {id === 0 && (
                            <div id="last-message-div" ref={lastMessageRef} />
                          )}
                        </React.Fragment>
                      );
                    })}
                </ChatView>
              </MessagesContainer>
              <Input
                referance={inputRef}
                rightButtons={
                  <SendIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (isSendingMessage) {
                        return;
                      }
                      handleSendMessage();
                      (inputRef?.current as any).focus();
                    }}
                  />
                }
                onChange={(e: {
                  target: { value: React.SetStateAction<string> };
                }) => setInputValue(e?.target?.value)}
                leftButtons={
                  <Icon
                    sx={{
                      padding: '2px',
                      '& path': {
                        fill:
                          theme.palette.mode === 'light'
                            ? theme.palette.primary.main
                            : 'white',
                      },
                    }}
                  >
                    <SmileIcon
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </Icon>
                }
                maxHeight={300}
                onKeyPress={(e) => {
                  if (e.code === 'Enter' && !isSendingMessage) {
                    handleSendMessage();
                  }
                }}
                clear={() => {
                  setInputValue('');
                }}
                placeholder={t('writeMessage') as any}
                multiline={false}
              />
            </>
          )}
        </ChatContainer>
      ) : (
        <MobileChatContainer>
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <>
              <MobileChatHeader theme={theme}>
                <Stack
                  direction="row"
                  maxHeight="40px"
                  alignSelf="end"
                  width="100%"
                >
                  <ArrowBackIos
                    sx={{
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      cursor: 'pointer',
                      width: '20px',
                      height: '20px',
                      marginRight: '8px',
                    }}
                    onClick={() => {
                      setCurrentChatId(null);
                      if (!isDesktop && setShouldShow) {
                        setShouldShow(true);
                      }
                    }}
                  />
                  <Box
                    sx={{ display: 'flex' }}
                    onClick={
                      currentChat?.type === ChatType.Direct
                        ? () => handleChatOpenBigAvatar()
                        : undefined
                    }
                  >
                    <Avatar
                      src={currentChat?.imageUrl}
                      size="small"
                      type="rounded"
                      className="avatar"
                      letterItem={currentChat?.letterItem}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    width="calc(100% - 100px)"
                  >
                    <Typography
                      width="100%"
                      fontSize="1rem"
                      fontWeight={800}
                      lineHeight="24px"
                      sx={{ overflowX: 'clip', textOverflow: 'ellipsis' }}
                    >
                      {currentChat?.name}
                    </Typography>
                    {currentChat.type !== ChatType.Direct && (
                      <Typography
                        variant="caption"
                        fontSize="0.875rem"
                        sx={{
                          cursor: 'pointer',
                        }}
                        color={theme.palette.neutral.main}
                        onClick={handleMembersCountClick}
                      >
                        {t('members', { count: currentChat?.users.length })}
                      </Typography>
                    )}
                  </Box>
                </Stack>
              </MobileChatHeader>

              <MobileMessagesContainer>
                <ChatView
                  className="message-list"
                  scrollLoadThreshold={10}
                  flipped={true}
                  shouldTriggerLoad={() =>
                    paginationState.currentCount < paginationState.total &&
                    !isLoadingMessages
                  }
                  onInfiniteLoad={handleScroll}
                >
                  {currentChat?.messages
                    .sort((a: any, b: any) => b.id - a.id)
                    .map((message: any, id: number) => {
                      let shouldRenderAvatar = false;
                      if (id === 0) {
                        shouldRenderAvatar = true;
                      }

                      if (
                        id !== 0 &&
                        message.userId !== currentChat?.messages[id - 1].userId
                      ) {
                        shouldRenderAvatar = true;
                      }

                      return (
                        <React.Fragment key={`message-${id}`}>
                          <ChatMessage
                            shouldRenderAvatar={shouldRenderAvatar}
                            key={`message-${id}`}
                            chatType={currentChat?.type}
                            setCurrentChatId={setCurrentChatId}
                            setAvatarModalOptions={setAvatarModalOptions}
                            {...message}
                          />
                          {id === 0 && (
                            <div id="last-message-div" ref={lastMessageRef} />
                          )}
                        </React.Fragment>
                      );
                    })}
                </ChatView>
              </MobileMessagesContainer>

              <Stack
                width="100%"
                justifyContent="center"
                alignItems="center"
                marginBottom="6px"
                ref={inputStackRef}
              >
                <Input
                  referance={inputRef}
                  rightButtons={
                    <SendIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (isSendingMessage) {
                          return;
                        }
                        handleSendMessage();
                        (inputRef?.current as any).focus();
                      }}
                    />
                  }
                  onFocus={() => scrollToBottom()}
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> };
                  }) => setInputValue(e?.target?.value)}
                  leftButtons={
                    <Icon
                      sx={{
                        padding: '2px',
                        '& path': {
                          fill:
                            theme.palette.mode === 'light'
                              ? theme.palette.primary.main
                              : 'white',
                        },
                      }}
                    >
                      <SmileIcon
                        style={{
                          cursor: 'pointer',
                        }}
                      />
                    </Icon>
                  }
                  maxHeight={300}
                  onKeyPress={(e) => {
                    if (e.code === 'Enter' && !isSendingMessage) {
                      handleSendMessage();
                    }
                  }}
                  clear={() => {
                    setInputValue('');
                  }}
                  placeholder={t('writeMessage') as any}
                  multiline={false}
                />
              </Stack>
            </>
          )}
        </MobileChatContainer>
      )}
      {avatarModalOptions?.open && (
        <BigAvatarDialog
          isOpen={avatarModalOptions.open}
          onClose={() => setAvatarModalOptions({ open: false })}
          avatar={avatarModalOptions?.avatar}
          title={avatarModalOptions?.title as string}
          userId={avatarModalOptions?.userId as number}
          showSendDmButton={!!avatarModalOptions.showSendDmButton}
          onSendDm={setCurrentChatId}
          letter={avatarModalOptions?.letter}
          isDesktop={!isMobile}
        />
      )}
    </>
  );
};

export default ChatBlock;
