import { Button, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { TadaIcon } from '../../assets/svgs';
import LoadingScreen from '../LoadingScreen';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import offersService from '../../services/offers.service';
import { useTranslation } from 'react-i18next';
import { formatDate, USER_OFFER_DATE_FORMAT } from '../../utils/format-date';
import { useTypedSelector } from '../../store/store';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const OfferActivated = () => {
  const navigate = useNavigate();
  const { offerId } = useParams();
  const isDesktop = useIsDesktop();
  const { t, i18n } = useTranslation(['offers', 'common']);
  const { user } = useTypedSelector((state) => state.auth);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [offer, setOffer] = useState<{
    id: string;
    title: string;
    restaurant: any;
    description: string;
    recommendations: any;
    discount: number | string;
    userOffer: any;
  }>();

  useEffect(() => {
    if (!offerId) {
      return;
    }
    offersService.getOffer(offerId).then((res) => {
      if (res.data) {
        setOffer(res.data);
        setIsLoading(false);
      }
    });
  }, [offerId]);

  const navigateToOffersList = () => navigate(`/ofertas`);

  return (
    <Container>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {isDesktop ? (
            <Paper
              sx={{
                minWidth: isDesktop ? '480px' : '100%',
                padding: '32px',
                marginTop: '32px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <Typography
                variant="h2"
                align="center"
                sx={{ marginBottom: '32px' }}
              >
                {t('activatedTitle')}
              </Typography>
              <TadaIcon />
              <Typography
                sx={{ marginBottom: '16px', marginTop: '16px' }}
                variant="h1"
                align="center"
              >
                {t('nicknameTitle')}
              </Typography>
              <Typography variant="h2" align="center">
                {user?.nickname}
              </Typography>
              <Typography variant="h3" align="center" color="primary.main">
                {formatDate(
                  offer?.userOffer?.createdAt,
                  USER_OFFER_DATE_FORMAT,
                  i18n.language
                )}
              </Typography>
              <Typography variant="h5" align="center">
                {t('activatedDescription')}
              </Typography>
              <Button
                fullWidth
                color="secondary"
                onClick={navigateToOffersList}
              >
                {t('back')}
              </Button>
            </Paper>
          ) : (
            <div
              style={{
                width: '100%',
                marginTop: '32px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <Typography
                variant="h2"
                align="center"
                sx={{ marginBottom: '32px' }}
              >
                {t('activatedTitle')}
              </Typography>
              <TadaIcon />
              <Typography
                sx={{ marginBottom: '16px', marginTop: '16px' }}
                variant="h1"
                align="center"
              >
                {offer?.restaurant?.name}
              </Typography>
              <Typography variant="h2" align="center">
                {user?.nickname}
              </Typography>
              <Typography variant="h3" align="center" color="primary.main">
                {formatDate(
                  offer?.userOffer?.createdAt,
                  USER_OFFER_DATE_FORMAT
                )}
              </Typography>
              <Typography variant="h5" align="center">
                {t('activatedDescription')}
              </Typography>
              <Button
                fullWidth
                color="secondary"
                onClick={navigateToOffersList}
              >
                {t('back')}
              </Button>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default OfferActivated;
