import { Box, Typography, useTheme } from '@mui/material';
import { CategoryScale, ChartOptions } from 'chart.js';
import Chart from 'chart.js/auto';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { ArrowDown } from '../../../assets/svgs';
import { useIsDesktop } from '../../../hooks/useIsDesktop';
import influencerService, {
  EChartGrouping,
} from '../../../services/influencer.service';
import { Container } from '../components';
import { Selector } from './components';
import { useTranslation } from 'react-i18next';

Chart.register(CategoryScale);

interface ChartData {
  period: number[];
  difference: number;
  total: number;
}

const chartOptions: ChartOptions<'line'> = {
  scales: {
    y: {
      ticks: {
        color: '#FFFFFF',
        callback: (value) => {
          return Number(value);
        },
        stepSize: 1,
      },
      grid: {
        color: 'rgba(255, 255, 255, 0.25)',
      },
    },
    x: {
      ticks: {
        color: '#FFFFFF',
      },
      grid: {
        display: false,
        color: 'rgba(255, 255, 255, 0.25)',
      },
    },
  },
  line: {},
  plugins: {
    legend: {
      display: false,
    },
  },
};

const ChartBlock: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation('profile');
  const isDesktop = useIsDesktop();
  const [data, setData] = useState<ChartData>({
    period: [],
    difference: 0,
    total: 0,
  });

  const [xAxisType, setXAxisType] = useState<EChartGrouping>(
    EChartGrouping.day
  );
  const [xAxisLabels, setXAxisLabels] = useState<string[]>([
    'L',
    'M',
    'X',
    'J',
    'V',
    'S',
    'D',
  ]);

  useEffect(() => {
    influencerService.getChart(xAxisType).then((resp) => {
      setData(resp.data);
    });
  }, [xAxisType]);

  const handleXAxisChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const selectedType: EChartGrouping = event.target.value as EChartGrouping;
    setXAxisType(selectedType);
    if (selectedType === EChartGrouping.day) {
      setXAxisLabels(['L', 'M', 'X', 'J', 'V', 'S', 'D']);
    } else if (selectedType === EChartGrouping.week) {
      setXAxisLabels(['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4']);
    } else if (selectedType === EChartGrouping.month) {
      setXAxisLabels([
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ]);
    }
  };

  return (
    <Container
      theme={theme}
      style={{
        minHeight: isDesktop ? '131px' : '83px',
        justifyContent: 'center',
        textAlign: 'center',
        maxHeight: isDesktop ? '380px' : '200px',
        padding: '24px',
      }}
    >
      <Box position="relative" display="flex" width="100%">
        <Typography fontWeight="bold">{t('influencer.registered')}</Typography>
        <Selector
          id="x-axis-selector"
          value={xAxisType}
          onChange={handleXAxisChange}
          theme={theme}
        >
          <option value={EChartGrouping.day}>{t('influencer.day')}</option>
          <option value={EChartGrouping.week}>{t('influencer.week')}</option>
          <option value={EChartGrouping.month}>{t('influencer.month')}</option>
        </Selector>
        <ArrowDown
          style={{ position: 'absolute', right: '10px', top: '16px' }}
        />
      </Box>
      <Box display="flex" width="100%" alignItems="center" gap="5px">
        <Typography fontWeight="bold">{data.total}</Typography>
        <Typography
          color={data.difference >= 0 ? '#20D73E' : '#EF0C0C'}
          variant="caption"
        >
          {data.difference >= 0
            ? `+${Math.round(data.difference).toFixed(0)}%`
            : `-${Math.round(data.difference).toFixed(0)}%`}
        </Typography>
      </Box>
      <Line
        data={{
          labels: xAxisLabels,
          datasets: [
            {
              label: 'Example Data',
              data: data.period,
              fill: false,
              tension: 0.3,
              borderColor: 'rgb(255, 157, 42)',
              pointRadius: 1,
            },
          ],
        }}
        options={chartOptions}
      />
    </Container>
  );
};

export default ChartBlock;
