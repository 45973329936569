import {
  ActiveOffer,
  EventApplied,
  FriendJoined,
  NewEvent,
  NewOffer,
} from '../../assets/svgs/notifications';
import { ENotificationType } from '../../store/slices/notifications.slice';

type TNotification = { icon: any; text: string };

type TNotificationConfig = {
  [key in ENotificationType]?: TNotification;
};
export const notificationConfig: TNotificationConfig = {
  [ENotificationType.OFFER_ACTIVATED]: {
    icon: ActiveOffer,
    text: 'offerActivated',
  },
  [ENotificationType.FRIEND_JOINED]: {
    icon: FriendJoined,
    text: 'friendJoined',
  },
  [ENotificationType.NEW_OFFER]: {
    icon: NewOffer,
    text: 'newOffer',
  },
  [ENotificationType.EVENT_NEW]: {
    icon: NewEvent,
    text: 'newEvent',
  },
  [ENotificationType.EVENT_APPLIED]: {
    icon: EventApplied,
    text: 'eventApplied',
  },
  [ENotificationType.SIGNUP]: {
    icon: EventApplied,
    text: 'signup',
  },
  [ENotificationType.PAYMENT_FAILED]: {
    icon: EventApplied,
    text: 'paymentFailed',
  },
  [ENotificationType.SUBSCRIPTION_CANCELED]: {
    icon: EventApplied,
    text: 'subscriptionCancelled',
  },
  [ENotificationType.EVENT_CANCELED]: {
    icon: EventApplied,
    text: 'Has cancelado el evento ',
  },
};

export const notificationTypesWithMeta = [
  ENotificationType.EVENT_CANCELED,
  ENotificationType.OFFER_ACTIVATED,
  ENotificationType.NEW_OFFER,
  ENotificationType.EVENT_NEW,
  ENotificationType.EVENT_APPLIED,
];
