import React, { createContext, useCallback, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import useNotifications from '../hooks/useNotification';
import NotificationService from '../services/notification.service';
import {
  pushNotification,
  setNotifications,
} from '../store/slices/notifications.slice';
import { useDispatch, useTypedSelector } from '../store/store';
import { getWithExpiry } from '../utils/localStorage';
import omit from 'just-omit';

interface ISocketContext {
  socket?: Socket;
  isReconnecting?: boolean;
  addReconnectEmit?: (name: string, values: any) => void;
  removeReconnectEmit?: (name: string) => void;
}

const MAX_RETRIES = 5;
const RETRY_DELAY = 500;
let RETRIES = 0;

const WebSocketContext = createContext<ISocketContext>({});

export { WebSocketContext };

const socket = io(process.env.REACT_APP_API_URL + '/sockets', {
  transports: ['websocket'],
  auth: (cb) => {
    const { value: token } = getWithExpiry('accessToken');

    if (token) {
      cb({ token });
    }
  },
});

const closeSocket = () => {
  if (!socket) {
    return;
  }

  socket.off('new-notification');
  socket.off('error');
  socket.off('exception');
  socket.off('connect');
  socket.close();
};

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }: any) => {
  const { loggedin, user } = useTypedSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { notify } = useNotifications();
  const [isReconnecting, setIsReconnecting] = useState(false);

  const [reconnectEmitMapping, setReconnectEmitMapping] = useState<
    Record<string, any>
  >({});

  const addReconnectEmit = (name: string, values: any) => {
    reconnectEmitMapping[name] = values;
  };

  const removeReconnectEmit = (name: string) => {
    setReconnectEmitMapping((previousState) => {
      return omit(previousState, name);
    });
  };

  useEffect(() => {
    if (!loggedin) {
      return;
    }

    NotificationService.getAllNotifications()
      .then((resp) => {
        dispatch(setNotifications(resp.data));
      })
      .catch((e) => console.error(e));
  }, [loggedin]);

  const connectSocket = useCallback(() => {
    if (!loggedin) {
      closeSocket();
      return;
    }

    if (socket.connected) {
      return;
    }

    socket.connect();

    RETRIES = 0;

    socket.on('new-notification', (notification: any) => {
      if (user?.isNotificationEnabled) {
        notify(
          `New DOSS notification`,
          true,
          require('../assets/audio/simple_notification.mp3'),
          true
        );
      }

      if (notification) {
        return dispatch(
          pushNotification(JSON.parse(notification).notification)
        );
      }

      NotificationService.getAllNotifications()
        .then((resp) => {
          dispatch(setNotifications(resp.data));
        })
        .catch((e) => console.error(e));
    });

    socket.on('error', (msg: any) => {
      console.error(msg);
    });

    socket.on('exception', (msg: any) => {
      console.error(msg);

      if (RETRIES < MAX_RETRIES && !socket.active) {
        console.log(`Retrying socket connection manually ${RETRIES} time`);

        setTimeout(() => {
          connectSocket();

          RETRIES += 1;
        }, RETRY_DELAY);
      }
    });

    socket.on('disconnect', function (reason) {
      console.error('Socket disconnected');
    });

    socket.on('connect', function () {
      console.log('Socket connected');
      setIsReconnecting((prev) => !prev);

      const emits = Object.keys(reconnectEmitMapping);
      if (!emits.length) {
        return;
      }

      console.log('Emitting after reconnection');

      for (const emit of emits) {
        if (reconnectEmitMapping[emit]) {
          socket.emit(emit, reconnectEmitMapping[emit]);
        }
      }
    });
  }, [loggedin]);

  useEffect(() => {
    if (socket) {
      closeSocket();
    }

    if (loggedin) {
      connectSocket();
    }

    return () => {
      closeSocket();
    };
  }, [loggedin, connectSocket]);

  return (
    <WebSocketContext.Provider
      value={{ socket, addReconnectEmit, removeReconnectEmit, isReconnecting }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
