import * as React from 'react';
import VerificationCodeInput from './components/login/VerificationCodeInput';
import OfferActivated from './components/Offers/OfferActivated';
import { AuthGuard, NotAuthGuard } from './guards';
import AuthLayout from './layouts/AuthLayout';
import DefaultLayout from './layouts/DefaultLayout';
import MuiStyledLayout from './layouts/MuiStyledLayout';
import ProfileLayout from './layouts/ProfileLayout';
import Login from './pages/Auth/Login';
import PreSignUp from './pages/Auth/PreSignUp';
import ProfileSetup from './pages/Auth/ProfileSetup';
import Signup from './pages/Auth/Signup';
import ChatMembers from './pages/Chats/ChatMembers';
import Chats from './pages/Chats/Chats';
import Event from './pages/Events/EventPage/Event';
import EventsList from './pages/Events/EventsList';
import Friends from './pages/Friends/Friends';
import FriendsAccepted from './pages/Friends/FriendsAccepted';
import FriendsPending from './pages/Friends/FriendsPending';
import NewLanding from './pages/NewLanding';
import Notifications from './pages/Notifications/Notifications';
import Offer from './pages/Offers/OfferPage/Offer';
import OffersList from './pages/Offers/OffersList';
import PaymentSuccess from './pages/PaymentSuccess';
import AllPayouts from './pages/Profile/AllPayouts';
import InfluencerAcceptedInvitationsPage from './pages/Profile/InfluencerAcceptedInvitationsPage';
import InfluencerDashboardPage from './pages/Profile/InfluencerDashboardPage';
import ProfileEditPage from './pages/Profile/ProfileEditPage';
import ProfilePage from './pages/Profile/ProfilePage';
import StripePayoutRefreshPage from './pages/Profile/StripePayoutRefreshPage';
import RenewSubscription from './pages/RenewSubscription';

const authRoutes = [
  {
    path: '/login',
    element: (
      <MuiStyledLayout>
        <AuthLayout withBorders={true}>
          <NotAuthGuard>
            <Login />
          </NotAuthGuard>
        </AuthLayout>
      </MuiStyledLayout>
    ),
  },
  {
    path: '/verification',
    element: (
      <MuiStyledLayout>
        <AuthLayout withBorders={true}>
          <VerificationCodeInput />
        </AuthLayout>
      </MuiStyledLayout>
    ),
  },
  {
    path: '/signup',
    element: (
      <MuiStyledLayout>
        <AuthLayout withBorders={true} withBackArrow={true}>
          <NotAuthGuard>
            <Signup option="" />
          </NotAuthGuard>
        </AuthLayout>
      </MuiStyledLayout>
    ),
  },
  {
    path: '/complete-signup',
    element: (
      <MuiStyledLayout>
        <AuthLayout withBorders={true}>
          <NotAuthGuard>
            <ProfileSetup />
          </NotAuthGuard>
        </AuthLayout>
      </MuiStyledLayout>
    ),
  },
  {
    path: '/payment-success',
    element: (
      <MuiStyledLayout>
        <AuthLayout withBorders={true}>
          <PaymentSuccess />
        </AuthLayout>
      </MuiStyledLayout>
    ),
  },
  {
    path: '/renew-subscription',
    element: (
      <MuiStyledLayout>
        <AuthLayout withBorders={true}>
          <RenewSubscription />
        </AuthLayout>
      </MuiStyledLayout>
    ),
  },
];

const friendsRoutes = [
  {
    path: '/invitaramigos',
    children: [
      {
        path: '',
        element: (
          <MuiStyledLayout>
            <DefaultLayout>
              <AuthGuard>
                <Friends />
              </AuthGuard>
            </DefaultLayout>
          </MuiStyledLayout>
        ),
      },
      {
        path: 'accepted',
        element: (
          <MuiStyledLayout>
            <DefaultLayout withBottomBar={false}>
              <AuthGuard>
                <FriendsAccepted />
              </AuthGuard>
            </DefaultLayout>
          </MuiStyledLayout>
        ),
      },
      {
        path: 'pending',
        element: (
          <MuiStyledLayout>
            <DefaultLayout withBottomBar={false}>
              <AuthGuard>
                <FriendsPending />
              </AuthGuard>
            </DefaultLayout>
          </MuiStyledLayout>
        ),
      },
    ],
  },
];

const profileRoutes = [
  {
    path: '/perfil',
    children: [
      {
        path: '',
        element: (
          <MuiStyledLayout>
            <ProfileLayout>
              <AuthGuard>
                <ProfilePage />
              </AuthGuard>
            </ProfileLayout>
          </MuiStyledLayout>
        ),
      },
      {
        path: 'editar',
        element: (
          <MuiStyledLayout>
            <ProfileLayout>
              <AuthGuard>
                <ProfileEditPage />
              </AuthGuard>
            </ProfileLayout>
          </MuiStyledLayout>
        ),
      },
      {
        path: 'influencer-dashboard',
        element: (
          <MuiStyledLayout>
            <AuthGuard>
              <InfluencerDashboardPage />
            </AuthGuard>
          </MuiStyledLayout>
        ),
      },
      {
        path: 'stripe-payout-refresh',
        element: (
          <MuiStyledLayout>
            <AuthGuard>
              <StripePayoutRefreshPage />
            </AuthGuard>
          </MuiStyledLayout>
        ),
      },
      {
        path: 'estadisticas',
        element: (
          <MuiStyledLayout>
            <DefaultLayout withBottomBar={false}>
              <AuthGuard>
                <InfluencerAcceptedInvitationsPage />
              </AuthGuard>
            </DefaultLayout>
          </MuiStyledLayout>
        ),
      },
      {
        path: 'all-payouts',
        element: (
          <MuiStyledLayout>
            <DefaultLayout withBottomBar={false}>
              <AuthGuard>
                <AllPayouts />
              </AuthGuard>
            </DefaultLayout>
          </MuiStyledLayout>
        ),
      },
    ],
  },
];

const offersRoutes = [
  {
    path: '/ofertas',
    children: [
      {
        path: '',
        element: (
          <MuiStyledLayout>
            <DefaultLayout>
              <AuthGuard>
                <OffersList />
              </AuthGuard>
            </DefaultLayout>
          </MuiStyledLayout>
        ),
      },
      {
        path: ':offerId',
        element: (
          <MuiStyledLayout>
            <DefaultLayout>
              <AuthGuard>
                <Offer />
              </AuthGuard>
            </DefaultLayout>
          </MuiStyledLayout>
        ),
      },
      {
        path: ':offerId/activated',
        element: (
          <MuiStyledLayout>
            <ProfileLayout>
              <AuthGuard>
                <OfferActivated />
              </AuthGuard>
            </ProfileLayout>
          </MuiStyledLayout>
        ),
      },
    ],
  },
];

const eventsRoutes = [
  {
    path: '/eventos',
    children: [
      {
        path: '',
        element: (
          <MuiStyledLayout>
            <DefaultLayout>
              <AuthGuard>
                <EventsList />
              </AuthGuard>
            </DefaultLayout>
          </MuiStyledLayout>
        ),
      },
      {
        path: ':eventId',
        element: (
          <MuiStyledLayout>
            <DefaultLayout>
              <AuthGuard>
                <Event />
              </AuthGuard>
            </DefaultLayout>
          </MuiStyledLayout>
        ),
      },
    ],
  },
];

const rootRotes = [
  {
    path: '/',
    element: (
      <MuiStyledLayout>
        <NotAuthGuard>
          <PreSignUp />
        </NotAuthGuard>
      </MuiStyledLayout>
    ),
  },
  { path: '/landing', element: <NewLanding /> },
  {
    path: '/chats',
    children: [
      {
        path: '',
        element: (
          <MuiStyledLayout>
            <DefaultLayout>
              <AuthGuard>
                <Chats />
              </AuthGuard>
            </DefaultLayout>
          </MuiStyledLayout>
        ),
      },
      {
        path: ':chatId/miembros',
        element: (
          <MuiStyledLayout>
            <DefaultLayout withBottomBar={false}>
              <AuthGuard>
                <ChatMembers />
              </AuthGuard>
            </DefaultLayout>
          </MuiStyledLayout>
        ),
      },
    ],
  },
  {
    path: '/notificaciones',
    element: (
      <MuiStyledLayout>
        <DefaultLayout withBottomBar={false}>
          <AuthGuard>
            <Notifications />
          </AuthGuard>
        </DefaultLayout>
      </MuiStyledLayout>
    ),
  },
  {
    path: '*',
    element: (
      <main style={{ padding: '1rem' }}>
        <p>404 | There's nothing here!</p>
      </main>
    ),
  },
];

const routes = [
  ...rootRotes,
  ...authRoutes,
  ...profileRoutes,
  ...friendsRoutes,
  ...offersRoutes,
  ...eventsRoutes,
];

export default routes;
