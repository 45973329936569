import { getWithExpiry, setWithExpiry } from './localStorage';

// set session needs to storage access token and refresh token in the local storage
type ISession = {
  accessToken: string;
  accessTokenExpiresAt: number;
  refreshToken?: string;
  refreshTokenExpiresAt?: number;
};

const setSession = (session: ISession) => {
  // set access token;
  setWithExpiry(
    'accessToken',
    session.accessToken,
    session.accessTokenExpiresAt
  );
  // set refresh token
  if (session.refreshToken && session.refreshTokenExpiresAt) {
    setWithExpiry(
      'refreshToken',
      session.refreshToken,
      session.refreshTokenExpiresAt
    );
  }
};

export { setSession };
