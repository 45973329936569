import { Box, Skeleton } from '@mui/material';
import { useIsDesktop } from '../../hooks/useIsDesktop';

const ChatSkeleton = () => {
  const isDesktop = useIsDesktop();

  return (
    <Box
      display="flex"
      gap={1}
      marginTop={2}
      marginBottom={isDesktop ? 0 : 3.5}
    >
      <Skeleton
        height={isDesktop ? '65px' : '55px'}
        width={isDesktop ? '65px' : '55px'}
        variant="circular"
        component="div"
        sx={{
          margin: `0 ${isDesktop ? '10px' : '5px'} 0 ${
            isDesktop ? '15px' : '13px'
          }`,
        }}
      />
      <Box width="40%">
        <Skeleton height={45} sx={{ marginTop: '-5px' }} />
        <Skeleton height={25} sx={{ marginTop: '-5px' }} />
      </Box>
      <Box width={isDesktop ? '35%' : '20%'} marginLeft="auto">
        <Skeleton height={25} sx={{ marginTop: '5px' }} />
      </Box>
    </Box>
  );
};

const ChatsLoading = () => {
  return (
    <>
      <ChatSkeleton />
      <ChatSkeleton />
      <ChatSkeleton />
      <ChatSkeleton />
    </>
  );
};
export default ChatsLoading;
