import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { createRef, useEffect, useState } from 'react';
import VerificationInput from 'react-verification-input';
import { GeneralFuctionType } from '../../@types/props.types';
import useAuthContext from '../../hooks/useAuthContext';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import authService from '../../services/auth.service';
import { userLoggedin } from '../../store/slices/api.slice';
import { useDispatch } from '../../store/store';
import { setSession } from '../../utils';
import BlockHeader from '../BlockHeader';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../../@types/user';
import { useNavigate } from 'react-router-dom';
import { RESEND_SMS_DELAY_SECONDS, SMS_RESEND_ATTEMPTS } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    gap: '14px',
  },
  character: {
    width: '48px',
    height: '56px',
    marginBottom: 0,
    border: `1px none ${(theme as Theme).palette.border.main}`,
    borderRadius: '8px',
    backgroundColor: (theme as Theme).palette.customGrey.dark,
    color: (theme as Theme).palette.customGrey.contrastText,
    fontWeight: 600,
    textAlign: 'center',
    display: 'block',
    padding: '5px 12px',
    fontSize: 14,
    verticalAlign: 'middle',
    boxShadow: 'none',
    '@media (min-width:700px)': {
      width: '68px',
      height: '80px',
      fontSize: 20,
      padding: '15px 12px',
    },
  },
  characterSelected: {
    outline: `0 solid ${(theme as Theme).palette.border.main}`,
    borderStyle: 'solid',
    borderColor: (theme as Theme).palette.border.main,
    boxShadow: 'none',
  },
}));

const SignupTwo = ({
  handleActiveSectionChange,
}: {
  handleActiveSectionChange: GeneralFuctionType;
}) => {
  const theme = useTheme();
  const { t } = useTranslation('login');
  const { userSignupData, setUserSignupData } = useAuthContext();
  const [count, setCount] = useState(RESEND_SMS_DELAY_SECONDS);
  const [error, setError] = useState(null);
  const [codeAttempts, setCodeAttempts] = useState(0);
  const authRef = createRef<HTMLInputElement>();
  const classes = useStyles();
  const isDesktop = useIsDesktop();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (authRef.current !== null) {
      authRef.current.addEventListener('focus', () => {
        // window.scrollTo(0, 100);
      });
      authRef.current.focus();
    }
  }, [authRef.current]);

  useEffect(() => {
    let timer = setInterval(() => {
      if (count > 0) setCount((count) => count - 1);
    }, 1000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    const regData = localStorage.getItem('registration-data');
    if (regData) {
      const prevData = JSON.parse(regData || '{}');
      setUserSignupData({ phoneNumber: prevData.phoneNumber });
    }

    return () => {
      localStorage.removeItem('registration-data');
    };
  }, []);

  const handleResend = () => {
    if (count !== 0 || codeAttempts > SMS_RESEND_ATTEMPTS) {
      return;
    }

    setCodeAttempts((prev) => prev + 1);
    if (userSignupData?.phoneNumber.length) {
      authService
        .sendVerificationCode({
          phoneNumber: userSignupData?.phoneNumber,
        })
        .catch((err) => {
          if (err?.status === 429) {
            return setError(t('validation.tooManyAttempts') as any);
          }

          console.error(err);
          return setError(t('validation.somethingWentWrong') as any);
        })
        .finally(() => {
          setCount(RESEND_SMS_DELAY_SECONDS);
        });
    }
  };

  const onChangeValue = (_value: string) => {
    if (_value.length === 6) {
      setError(null);

      if (_value?.length && userSignupData?.phoneNumber.length) {
        authService
          .verifyCode({
            phoneNumber: userSignupData.phoneNumber,
            code: Number(_value),
          })
          .then((res) => {
            const {
              user,
              accessToken,
              refreshToken,
              accessTokenExpiresAt,
              refreshTokenExpiresAt,
            } = res.data;

            setSession({
              accessToken,
              refreshToken,
              accessTokenExpiresAt,
              refreshTokenExpiresAt,
            });

            dispatch(
              userLoggedin({
                ...user,
                accessToken: accessToken,
                refreshToken: refreshToken,
              })
            );

            if (user.role === UserRole.Influencer) {
              navigate('/ofertas');
            } else if (user.role === UserRole.User) {
              handleActiveSectionChange(3);
            }
          })
          .catch((err) => {
            console.error(err);
            setError(t('validation.notValidCode') as any);
          });
      }
    }
  };

  return (
    <>
      {isDesktop ? (
        <BlockHeader title={t('page', { page: 2 })} />
      ) : (
        <Divider sx={{ color: theme.palette.primary.main, width: '100%' }}>
          {t('page', { page: 2 })}
        </Divider>
      )}
      <Box sx={{ textAlign: 'center', marginBottom: '56px' }}>
        <Typography variant={'h1'}>{t('login.title')}</Typography>
        <Typography variant={'h3'}>
          {t('phoneVerification.inputLabel', {
            phoneNumber: userSignupData?.phoneNumber,
          })}
        </Typography>
      </Box>
      <Box>
        <Box>
          <VerificationInput
            ref={authRef}
            classNames={{
              container: classes.container,
              character: classes.character,
              characterInactive: 'character--inactive',
              characterSelected: classes.characterSelected,
            }}
            onChange={onChangeValue}
            placeholder=""
            validChars="0-9"
            inputProps={{
              autoComplete: 'one-time-code',
              inputMode: 'numeric',
            }}
          />
        </Box>
        <Button
          onClick={handleResend}
          fullWidth
          disabled={count > 0 || codeAttempts > SMS_RESEND_ATTEMPTS}
        >
          {codeAttempts > SMS_RESEND_ATTEMPTS
            ? t('validation.tooManyAttempts')
            : count > 0
              ? t('phoneVerification.buttonTextWithCountdown', { count })
              : t('phoneVerification.buttonText')}
        </Button>
        {error && (
          <Typography sx={{ color: 'primary.main', textAlign: 'center' }}>
            {error}
          </Typography>
        )}
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h3" sx={{ marginTop: '16px' }}>
            {t('phoneVerification.incorrectPhoneText')}{' '}
            <a href="/login">{t('phoneVerification.backButtonText')}</a>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default SignupTwo;
