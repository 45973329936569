import { Box, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { Avatar } from 'react-chat-elements';
import { ChatType } from '../../enums/chat-type.enum';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import { Message, MessageContainer } from './components';

const ChatMessage: FC<any> = ({
  shouldRenderAvatar,
  setCurrentChatId,
  avatar,
  title,
  id,
  chatType,
  setAvatarModalOptions,
  ...rest
}) => {
  const theme = useTheme();
  const isDesktop = useIsDesktop();

  const handleOpenBigAvatar = () => {
    setAvatarModalOptions({
      open: true,
      avatar,
      title,
      letter: title[0].toUpperCase(),
      userId: rest?.userId,
      showSendDmButton: chatType !== ChatType.Direct,
    });
  };

  return (
    <>
      {rest.position === 'left' ? (
        <MessageContainer theme={theme} sx={{ justifyContent: 'flex-start' }}>
          {shouldRenderAvatar && (
            <Box sx={{ display: 'flex' }} onClick={() => handleOpenBigAvatar()}>
              <Avatar
                src={avatar}
                size={isDesktop ? 'xlarge' : 'small'}
                type="rounded"
                className="avatar"
                letterItem={
                  avatar ? undefined : { id, letter: title[0].toUpperCase() }
                }
              />
            </Box>
          )}
          <Message
            className={`friend-message ${
              shouldRenderAvatar && 'should-render-avatar'
            }`}
            theme={theme}
            nicknameColor={rest.nicknameColor}
            title={title}
            {...rest}
          />
        </MessageContainer>
      ) : (
        <MessageContainer theme={theme} sx={{ justifyContent: 'flex-end' }}>
          <Message
            nicknameColor={rest.nicknameColor}
            className={`my-message ${
              shouldRenderAvatar && 'should-render-avatar'
            }`}
            theme={theme}
            title={title}
            {...rest}
          />
          {shouldRenderAvatar && (
            <Box style={{ display: 'flex' }}>
              <Avatar
                src={avatar}
                size={isDesktop ? 'xlarge' : 'small'}
                type="rounded"
                className="avatar"
                letterItem={
                  avatar ? undefined : { id, letter: title[0].toUpperCase() }
                }
              />
            </Box>
          )}
        </MessageContainer>
      )}
    </>
  );
};

export default ChatMessage;
