//took this code from documentation https://mui.com/material-ui/react-avatar/#letter-avatars
export const nameToColor = (name?: string) => {
  if (!name) return;

  let hash = 0;
  let i;

  for (i = 0; i < name.length; i += 1) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const nameToAvatar = (name?: string) => {
  if (!name) return;
  return {
    children: name[0].toUpperCase(),
  };
};
