import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertIcon } from '../assets/svgs/index';
import LoadingScreen from '../components/LoadingScreen';
import { useIsDesktop } from '../hooks/useIsDesktop';
import stripeService from '../services/stripe.service';
import userService from '../services/user.service';
import { userUpdated } from '../store/slices/api.slice';
import { dispatch, useTypedSelector } from '../store/store';
import { SubscriptionStatus } from '../enums/subscription-status.enum';
import { useTranslation } from 'react-i18next';

const RenewSubscription = () => {
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const { t } = useTranslation('login');

  const [details, setDetails] = useState({
    title: '',
    subtitle: '',
    shouldRedirectInStripe: false,
  });
  const [isUserRefreshed, setIsUserRefreshed] = useState(false);

  const { user } = useTypedSelector((state) => state.auth);

  useEffect(() => {
    userService.me().then((res) => {
      dispatch(userUpdated(res.data));
      setIsUserRefreshed(true);
    });
  }, []);

  useEffect(() => {
    if (!user || !user.subscription || !isUserRefreshed) {
      return;
    }

    if (
      user.subscription.status === SubscriptionStatus.Scheduled ||
      user.subscription.status === SubscriptionStatus.Created ||
      user.subscription.status === SubscriptionStatus.Active
    ) {
      navigate('/ofertas');
    }

    if (user.subscription.status === SubscriptionStatus.Cancelled) {
      setDetails({
        title: t('renew.cancelledTitle'),
        subtitle: t('renew.cancelledDescription'),
        shouldRedirectInStripe: false,
      });
    }

    if (user.subscription.status === SubscriptionStatus.ActionRequired) {
      setDetails({
        title: t('renew.actionRequiredTitle'),
        subtitle: String(user.subscription.details),
        shouldRedirectInStripe: true,
      });
    }
  }, [user, isUserRefreshed, t]);

  const handleClick = async () => {
    if (!details.shouldRedirectInStripe) {
      return navigate('/signup?step=3');
    }

    const subRes = await stripeService.getSubscriptionUrl({
      returnUrl: window.location.href,
    });
    if (subRes.data) {
      window.location.replace(subRes.data.url);
    }
  };

  return !isUserRefreshed ? (
    <LoadingScreen />
  ) : (
    <>
      <Box
        sx={{
          textAlign: 'center',
          maxWidth: '90%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <AlertIcon
          style={{
            margin: `0 auto ${isDesktop ? '48px' : '24px'} auto`,
            width: isDesktop ? 'auto' : '94px',
            height: isDesktop ? 'auto' : '94px',
          }}
        />
        <Typography variant="h1">{details.title}</Typography>
        <Typography
          variant="h3"
          sx={{
            paddingTop: isDesktop ? '11px' : '8px',
            paddingBottom: isDesktop ? '32px' : '16px',
          }}
        >
          {details.subtitle}
        </Typography>
        <Button fullWidth onClick={handleClick}>
          {t('renew.buttonText')}
        </Button>
      </Box>
    </>
  );
};

export default RenewSubscription;
