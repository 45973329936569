import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import payoutsService from '../../services/payouts.service';
import { ConnectedAccountUrlType } from '../../enums/connected-account-url-type.enum';

const StripePayoutRefreshPage = () => {
  useEffect(() => {
    const getUrl = async () => {
      const { data: {url} } = await payoutsService.getConnectedAccountUrl(ConnectedAccountUrlType.AccountOnboarding);

      window.location.replace(url);
    };

    getUrl();
  }, [])

  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size={50} />
    </div>
  );
};

export default StripePayoutRefreshPage;
