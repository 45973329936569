import { Box } from '@mui/material';
import React, { FC } from 'react';
import { useIsDesktop } from '../hooks/useIsDesktop';
import TopAppLayout from './TopAppLayout';

interface IAuthLayout {
  children: React.ReactElement;
}

const ProfileLayout: FC<IAuthLayout> = ({ children }: IAuthLayout) => {
  const isDesktop = useIsDesktop();
  return (
    <>
      {isDesktop && <TopAppLayout />}
      <Box
        sx={{
          paddingTop: isDesktop ? '110px' : '48px',
          paddingLeft: '16px',
          paddingRight: '16px',
          marginRight: 'auto',
          marginLeft: 'auto',
          maxWidth: '640px',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default ProfileLayout;
