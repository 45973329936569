import { Box, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ChatList } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ChatsLoading from '../../components/Chats/ChatsLoading';
import { ChatType } from '../../enums/chat-type.enum';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import { BottomBarContext } from '../../layouts/DefaultLayout';
import EmptyStateLayout from '../../layouts/EmptyStateLayout';
import TopAuthMobileLayout, {
  ETopAuthMobileLayoutVariant,
} from '../../layouts/TopAuthMobileLayout';
import chatsService from '../../services/chats.service';
import { WebSocketContext } from '../../sockets/WebSocket';
import { useTypedSelector } from '../../store/store';
import { nameToAvatar, nameToColor } from '../../utils/avatar';

import ChatBlock from './Chat';
import { Container, ListContainer } from './components';
import { dateStringForDate, userNickname } from './functions';

const generateLetterItem = (
  id: string,
  color: string,
  name: string
): { id: string; letter: JSX.Element } => ({
  id: `avatar-${id}`,
  letter: (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: color || nameToColor(name),
      }}
      {...nameToAvatar(name)}
    />
  ),
});

interface IChat {
  imageUrl: string | undefined;
  name: string | undefined;
  type: ChatType;
  id: string;
  lastMessage: any;
  unreadMessagesCount: number;
  dmRecipient?: any;
}

const Chats = () => {
  const { t, i18n } = useTranslation('chats');
  const [currentChatId, setCurrentChatId] = useState<number | null>(null);
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const { socket, isReconnecting, addReconnectEmit, removeReconnectEmit } =
    useContext(WebSocketContext);
  const { setShouldShow } = useContext(BottomBarContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [chats, setChats] = useState<any[]>([]);
  const { user } = useTypedSelector((state) => state.auth);
  const [searchParams] = useSearchParams();

  const mapChatToLibStructure = (el: IChat, language: string) => {
    const baseResult = {
      title: el.name,
      avatar: el.imageUrl,
      includesMessages: !!el?.lastMessage?.text,
      subtitle: el?.lastMessage?.text
        ? `${el?.lastMessage?.user.nickname}: ${el?.lastMessage?.text}`
        : t('noMessages'),
      id: el.id,
      unread: el.unreadMessagesCount,
      date: el?.lastMessage?.createdAt || undefined,
      dateString: dateStringForDate(el?.lastMessage?.createdAt, language),
      lastMessageId: el?.lastMessage?.id || undefined,
    };

    if (el.type === ChatType.Direct) {
      const avatar = el?.dmRecipient?.user?.avatarUrl;
      const name = userNickname(
        el?.dmRecipient?.user?.id,
        el?.dmRecipient?.user?.nickname
      );
      let letterItem: { letter: JSX.Element; id: string } | undefined;

      if (!avatar) {
        letterItem = generateLetterItem(
          el?.dmRecipient?.user?.id,
          el?.dmRecipient?.user?.color,
          name
        );
      }

      return {
        ...baseResult,
        title: name,
        avatar,
        letterItem,
      };
    }

    return baseResult;
  };

  useEffect(() => {
    let isActive = true;

    if (isActive && chats?.length) {
      setChats((currentChats) =>
        currentChats.map((item) => ({
          ...item,
          subtitle: item.lastMessageId ? item.subtitle : t('noMessages'),
          dateString: dateStringForDate(item.date, i18n.language),
        }))
      );
    }

    return () => {
      isActive = false;
    };
  }, [i18n.language]);

  const getChatsList = async () => {
    const res = await chatsService.getChatsList(user?.locationId);

    if (!res.data) {
      setIsLoading(false);
      return;
    }

    const mappedChatsList = res.data?.map((el: IChat) => {
      return mapChatToLibStructure(el, i18n.language);
    });
    setChats(mappedChatsList);

    if (mappedChatsList.length) {
      const chatId = searchParams.get('initialChatId');

      if (chatId) {
        setCurrentChatId(+chatId);
      } else if (isDesktop) {
        setCurrentChatId(mappedChatsList[0]?.id);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (chats.length && socket?.connected) {
      const ids = chats.map((el: any) => el.id);

      addReconnectEmit?.call(this, 'join-many-chats', { ids });

      socket.emit('join-many-chats', { ids });

      return () => {
        removeReconnectEmit?.call(this, 'join-many-chats');
      };
    }
  }, [chats, socket, socket?.connected]);

  useEffect(() => {
    getChatsList();
  }, [isReconnecting]);

  useEffect(() => {
    if (!socket || !socket.connected) {
      return;
    }

    const newMessageListener = async (message: string) => {
      const parsed = JSON.parse(message);

      setChats((chats: any[]) => {
        const chatIndex = chats.findIndex((el: any) => el.id === parsed.chatId);

        if (chatIndex === -1) {
          return chats;
        }

        const userInChat = parsed.chat.users.find(
          (el: any) => el.userId === user?.id
        );

        const chat = chats[chatIndex];
        const newChat = {
          title: chat.title,
          avatar: chat.avatar,
          subtitle: parsed?.text
            ? `${parsed?.user.nickname}: ${parsed?.text}`
            : t('noMessages'),
          id: chat.id,
          unread:
            parsed.chat.messagesCount - userInChat?.readMessagesCount || 0,
          date: parsed?.createdAt || undefined,
          dateString: dateStringForDate(parsed?.createdAt, i18n.language),
          lastMessageId: parsed?.id || undefined,
          letterItem: chat.letterItem,
        };

        return [newChat, ...chats.filter((item) => item.id !== chat.id)];
      });

      // Disabled sound on a new message in the chat

      // if (user?.isNotificationEnabled && String(user.id) !== parsed.userId) {
      //   notify(
      //     `New chat message`,
      //     true,
      //     require('../../assets/audio/new_message.mp3'),
      //     false
      //   );
      // }
    };

    socket.on('new-message', newMessageListener);

    const firstDirectMessageListener = async (message: any) => {
      try {
        const parsed = JSON.parse(message);

        const { data: chatData } = await chatsService.getCurrentChat(
          parsed.chatId
        );

        if (!chatData) {
          return;
        }

        setChats((chats) => {
          const userInChat = parsed.chat.users.find(
            (el: any) => el.userId === user?.id
          );

          const userForName = (chatData.users as any[]).find(
            (item) => user?.id !== item.user.id
          );

          const mappedChat = mapChatToLibStructure(
            {
              imageUrl: undefined,
              name: undefined,
              type: ChatType.Direct,
              id: chatData.id,
              lastMessage: parsed,
              unreadMessagesCount:
                parsed.chat.messagesCount - userInChat?.readMessagesCount || 0,
              dmRecipient: userForName,
            },
            i18n.language
          );

          return [mappedChat, ...chats];
        });
      } catch (error) {
        console.log(error);
      }
    };

    socket.on('first-direct-message', firstDirectMessageListener);

    return () => {
      socket.off('new-message', newMessageListener);
      socket.off('first-direct-message', firstDirectMessageListener);
    };
  }, [socket, socket?.connected]);

  return (
    <Container>
      {!isDesktop && !currentChatId && (
        <TopAuthMobileLayout variant={ETopAuthMobileLayoutVariant.logo} />
      )}
      <Box display="flex" height="100%">
        {(isDesktop || !currentChatId) && (
          <ListContainer theme={theme}>
            <Typography variant="h1" sx={{ marginBottom: '24px' }}>
              {t('connect')}
            </Typography>
            {isLoading ? (
              <ChatsLoading />
            ) : (
              <EmptyStateLayout lengthOfData={chats.length}>
                <ChatList
                  className="chat-list"
                  dataSource={chats}
                  id={1}
                  lazyLoadingImage="https://avatars.githubusercontent.com/u/80540635?v=4"
                  onClick={(chat) => {
                    setCurrentChatId(Number(chat.id));
                    if (!isDesktop && setShouldShow) {
                      setShouldShow(false);
                    }
                  }}
                />
              </EmptyStateLayout>
            )}
          </ListContainer>
        )}
        {currentChatId && (
          <ChatBlock
            currentChatId={currentChatId}
            setCurrentChatId={setCurrentChatId}
            chats={chats}
            setChats={setChats}
            generateLetterItem={generateLetterItem}
          />
        )}
      </Box>
    </Container>
  );
};
export default Chats;
