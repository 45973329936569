import { Box, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { desktopOnly } from '../../hooks/useIsDesktop';

export const BadgeContainer = styled(Box)`
  display: flex;
  position: absolute;
  background-color: white;
  border-radius: 30px;
  z-index: 2;
  top: 10px;
  left: 10px;
  flex-direction: row;
  padding: 4px 12px 4px 0px;
  background: ${({ theme }) => theme.palette.customGrey.dark};
`;

export const AlignedTypography = styled(Typography)`
  display: flex;
  align-items: center;
  font-weight: 700 !important;
`;

export const CustomPaper = styled(Box)`
  display: flex;
  align-items: center;
  border-radius: 15px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 16px);
  margin: 8px;
  flex-direction: row;
  padding: 12px 16px;
  gap: 16px;
  background: ${({ theme }) => theme.palette.customGrey.dark};

  ${desktopOnly} {
    width: calc(100% - 32px);
    margin: 16px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 16px;
  padding-bottom: 46px;

  ${desktopOnly} {
    padding: 96px;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
  }
`;

export const OfferImage = styled.img<{ url?: string }>`
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
  filter: linearGradient(179.64deg, rgba(0, 0, 0, 0) 0.31%, #000000 93.06%);

  ${desktopOnly} {
    filter: none;
  }
`;
export const ImageWrapper = styled(Box)`
  &::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 15px;
    background: linear-gradient(
      179.64deg,
      rgba(0, 0, 0, 0) 0.31%,
      #000000 93.06%
    );
  }
`;
export const OfferContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 32px;

  ${desktopOnly} {
    flex-basis: 22%;
  }
`;

export const LogoWrapper = styled(Box)`
  position: relative;
`;
