import { Box, Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import authService from '../../services/auth.service';
import {
  fetchStarted,
  resultLoaded,
  userLoggedin,
} from '../../store/slices/api.slice';
import { useDispatch } from '../../store/store';
import { setSession } from '../../utils';
import { Trans, useTranslation } from 'react-i18next';
import {
  RESEND_SMS_DELAY_SECONDS,
  SMS_RESEND_ATTEMPTS,
} from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    gap: '14px',
  },
  character: {
    width: '48px',
    height: '56px',
    marginBottom: 0,
    border: `1.5px none ${(theme as Theme).palette.border.main}`,
    borderRadius: '8px',
    backgroundColor: (theme as Theme).palette.customGrey.dark,
    color: (theme as Theme).palette.customGrey.contrastText,
    fontWeight: 600,
    textAlign: 'center',
    display: 'block',
    padding: '5px 12px',
    fontSize: 14,
    verticalAlign: 'middle',
    boxShadow: 'none',
    '@media (min-width:700px)': {
      width: '68px',
      height: '80px',
      fontSize: 20,
      padding: '15px 12px',
      borderRadius: '12px',
    },
  },
  characterSelected: {
    outline: `0 solid ${(theme as Theme).palette.border.main}`,
    borderStyle: 'solid',
    borderColor: (theme as Theme).palette.border.main,
    boxShadow: 'none',
  },
}));

const VerificationCodeInput = () => {
  const [count, setCount] = useState(RESEND_SMS_DELAY_SECONDS);
  const [codeInvalid, setCodeInvalid] = useState(false);
  const [codeAttempts, setCodeAttempts] = useState(0);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const authRef = createRef<HTMLInputElement>();
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('login');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const phoneNumber = searchParams.get('phoneNumber');

  const sendCode = useCallback(() => {
    if (!phoneNumber) {
      return;
    }

    setPhoneError(null);
    authService
      .sendVerificationCode({
        phoneNumber: phoneNumber.replace(' ', '+'),
      })
      .then((res) => {
        if (res.data.status === -1) {
          setPhoneError(t('validation.phoneNotExist'));
          return;
        }

        setCount(RESEND_SMS_DELAY_SECONDS);
      })
      .catch((e) => {
        let error = t('validation.phoneNotExist');

        if (e.status === 429) {
          error = t('validation.tooManyRequests');
        }

        // setPhoneError(t('validation.somethingWentWrong'));
        navigate(`/login?${new URLSearchParams({ error, phoneNumber })}`);
      });
  }, [phoneNumber]);

  useEffect(() => {
    sendCode();
  }, []);

  useEffect(() => {
    if (authRef.current !== null) {
      authRef.current.addEventListener('focus', () => {
        // window.scrollTo(0, 100);
      });
      authRef.current.focus();
    }
  }, [authRef.current]);

  useEffect(() => {
    let timer = setInterval(() => {
      if (count > 0) setCount((count) => count - 1);
    }, 1000);

    return () => clearTimeout(timer);
  });

  const handleResend = () => {
    if (count !== 0) {
      return;
    }

    if (codeAttempts > SMS_RESEND_ATTEMPTS) {
      return setPhoneError(t('validation.tooManyAttempts'));
    }

    setCodeAttempts((prev) => prev + 1);
    sendCode();
  };

  const handleAfterSubmit = async (bool: boolean, _code?: string) => {
    if (!_code?.length || !phoneNumber?.length) {
      return;
    }
    try {
      dispatch(fetchStarted());
      const res = await authService.verifyCode({
        phoneNumber: phoneNumber.replace(' ', '+'),
        code: Number(_code),
      });

      if (res.data.status < 1) {
        return setCodeInvalid(true);
      }

      const {
        user,
        accessToken,
        refreshToken,
        accessTokenExpiresAt,
        refreshTokenExpiresAt,
      } = res.data;

      setSession({
        accessToken,
        refreshToken,
        accessTokenExpiresAt,
        refreshTokenExpiresAt,
      });

      dispatch(
        userLoggedin({
          ...user,
          accessToken: accessToken,
          refreshToken: refreshToken,
        })
      );
      navigate('/ofertas');
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(resultLoaded());
    }
  };

  const onChangeValue = async (_value: string) => {
    if (_value.length === 6) {
      await handleAfterSubmit(true, _value);
    }
  };

  return (
    <>
      <Box sx={{ textAlign: 'center', marginBottom: '56px' }}>
        <Typography variant={'h1'}>{t('login.title')}</Typography>
        <Typography variant={'h3'}>
          <Trans
            i18nKey="phoneVerification.inputLabel"
            t={t}
            values={{
              phoneNumber: phoneNumber?.startsWith('+')
                ? phoneNumber
                : `+${phoneNumber?.trim()}`,
            }}
          />
        </Typography>
      </Box>
      <Box>
        <Box>
          <script src="smsinputjava.js"></script>
          <VerificationInput
            ref={authRef}
            classNames={{
              container: classes.container,
              character: classes.character,
              characterInactive: 'character--inactive',
              characterSelected: classes.characterSelected,
            }}
            onChange={onChangeValue}
            placeholder=""
            validChars="0-9"
            inputProps={{
              autoComplete: 'one-time-code',
              inputMode: 'numeric',
            }}
          />
        </Box>
        <Button
          onClick={handleResend}
          fullWidth
          disabled={count > 0 || codeAttempts > SMS_RESEND_ATTEMPTS}
        >
          {codeAttempts > SMS_RESEND_ATTEMPTS
            ? t('validation.tooManyAttempts')
            : count > 0
            ? t('phoneVerification.buttonTextWithCountdown', { count })
            : t('phoneVerification.buttonText')}
        </Button>
        {codeInvalid && (
          <Typography sx={{ color: 'primary.main', textAlign: 'center' }}>
            {t('validation.notValidCode')}
          </Typography>
        )}
        {phoneError && (
          <Typography sx={{ color: 'primary.main', textAlign: 'center' }}>
            {phoneError}
          </Typography>
        )}
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h3" sx={{ marginTop: '16px' }}>
            {t('phoneVerification.incorrectPhoneText')}{' '}
            <a href="/login">{t('phoneVerification.backButtonText')}</a>
          </Typography>
        </Box>
      </Box>
    </>
  );
};
export default VerificationCodeInput;
