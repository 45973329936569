import {
  ArrowBackIos,
  Insights,
  MyLocationOutlined,
} from '@mui/icons-material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Avatar,
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserRole } from '../../@types/user';
import { ColorModeContext, EColorModes } from '../../App';
import {
  CreditCardIcon,
  ExitIcon,
  ModeIcon,
  NotificationIcon,
  PaymentsIcon,
  ProfileIcon,
} from '../../assets/svgs';
import LocationSelect from '../../components/LocationSelect/LocationSelect';
import { ProfileMenuLoadingItem } from '../../components/Profile/ProfileMenuLoading';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import { useIsMobile } from '../../hooks/useIsMobile';
import stripeService from '../../services/stripe.service';
import userService from '../../services/user.service';
import payoutsService from '../../services/payouts.service';
import { signout, userUpdated } from '../../store/slices/api.slice';
import { dispatch, useTypedSelector } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { ConnectedAccountUrlType } from '../../enums/connected-account-url-type.enum';
import { nameToAvatar, nameToColor } from '../../utils/avatar';
import LoadingScreen from '../../components/LoadingScreen';

const ProfilePage = () => {
  const { user } = useTypedSelector((state) => state.auth);
  const theme = useTheme();
  const { t } = useTranslation('profile');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingUrl, setIsLoadingUrl] = useState<boolean>(false);

  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { toggleColorMode } = React.useContext(ColorModeContext);

  const locationWidth = isMobile ? '150px' : '300px';

  const onMySubscriptionClick = () => {
    setIsLoadingUrl(true);

    stripeService
      .getSubscriptionUrl({
        returnUrl: window.location.href,
      })
      .then(({ data: { url } }) => {
        window.open(url, '_self');
      })
      .catch((e) => {
        setIsLoadingUrl(false);

        console.error(e);
        toast.error(e);
      });
  };

  const getCustomerDetail = async () => {
    setIsLoading(true);
    const res = await userService.me();

    if (res.data) {
      dispatch(userUpdated(res.data));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getCustomerDetail();
  }, []);

  const handleChangeNotification = () => {
    setIsLoading(true);

    userService
      .update({ isNotificationEnabled: !user?.isNotificationEnabled })
      .then((res) => {
        //if (setStripeInfo) setStripeInfo(res.data);
        dispatch(
          userUpdated({ isNotificationEnabled: !user?.isNotificationEnabled })
        );
      })
      .catch((e) => {
        console.error(e);
        toast.error(e);
      })
      .finally(() => setIsLoading(false));
  };

  const handleOpenConnectedAccountUrl = () => {
    setIsLoadingUrl(true);

    payoutsService
      .getConnectedAccountUrl(ConnectedAccountUrlType.AccountOnboarding)
      .then(({ data: { url } }) => {
        window.open(url, '_self');
      })
      .catch((e) => {
        setIsLoadingUrl(false);

        console.error(e);
        toast.error(e);
      });
  };

  const handleLocationSelectChange = (event: any) => {
    setIsLoading(true);

    const value = event.target.value;

    const updatedData = { locationId: +value };

    userService
      .update(updatedData)
      .then(() => {
        dispatch(userUpdated(updatedData));
      })
      .catch((e) => {
        console.error(e);
        toast.error(e);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoadingUrl && <LoadingScreen />}
      <Box
        sx={{
          textAlign: 'center',
          display: 'flex',
          marginBottom: '32px',
          width: '100%',
        }}
      >
        {!isDesktop && (
          <ArrowBackIos
            sx={{
              marginTop: 'auto',
              marginBottom: 'auto',
              cursor: 'pointer',
            }}
            onClick={() => navigate('/ofertas')}
          />
        )}
        <Typography
          variant={'h1'}
          sx={{ width: '100%', marginRight: isDesktop ? 0 : '24px' }}
        >
          {t('title')}
        </Typography>
      </Box>
      <Box
        sx={
          isDesktop
            ? {
                border: `2px solid ${theme.palette.customGrey.dark}`,
                borderRadius: '24px',
                padding: '25px',
                width: '100%',
              }
            : {
                width: '100%',
              }
        }
      >
        <Box
          display="flex"
          alignItems="center"
          gap={isDesktop ? '24px' : '16px'}
          sx={{
            marginBottom: isDesktop ? '50px' : '16px',
            width: '100%',
          }}
        >
          {isLoading ? (
            <Skeleton
              variant="circular"
              width={isDesktop ? 96 : 64}
              height={isDesktop ? 96 : 64}
            />
          ) : (
            <Avatar
              sx={{
                bgcolor: nameToColor(user?.name),
              }}
              src={user?.avatarUrl}
              {...nameToAvatar(user?.name)}
            />
          )}
          {isLoading ? (
            <Skeleton width={200} height={50} />
          ) : (
            <Typography
              variant="h2"
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {user?.nickname}
            </Typography>
          )}
        </Box>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {user?.role === UserRole.Influencer ? (
            isLoading ? (
              <ProfileMenuLoadingItem />
            ) : (
              <ListItemButton
                onClick={() => {
                  navigate('/perfil/influencer-dashboard');
                }}
              >
                <ListItemIcon>
                  <Insights style={{ color: '#FFA132' }} />
                </ListItemIcon>
                <ListItemText
                  id="switch-list-label-dashboard"
                  primary={t('statistics')}
                />
                {!isDesktop && (
                  <IconButton edge="end">
                    <ArrowForwardIosIcon />
                  </IconButton>
                )}
              </ListItemButton>
            )
          ) : null}
          {user?.role === UserRole.Influencer ? (
            isLoading ? (
              <ProfileMenuLoadingItem />
            ) : (
              <ListItemButton onClick={handleOpenConnectedAccountUrl}>
                <ListItemIcon>
                  <CreditCardIcon style={{ color: '#FFA132' }} />
                </ListItemIcon>
                <ListItemText
                  id="switch-list-label-dashboard"
                  primary={t('connectPayoutCard')}
                />
                {!isDesktop && (
                  <IconButton edge="end">
                    <ArrowForwardIosIcon />
                  </IconButton>
                )}
              </ListItemButton>
            )
          ) : null}
          {isLoading ? (
            <ProfileMenuLoadingItem />
          ) : (
            <ListItemButton
              onClick={() => {
                navigate('/perfil/editar');
              }}
            >
              <ListItemIcon>
                <ProfileIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-profile" primary={t('me')} />
              {!isDesktop && (
                <IconButton edge="end">
                  <ArrowForwardIosIcon />
                </IconButton>
              )}
            </ListItemButton>
          )}
          {user?.role === UserRole.User &&
            (isLoading ? (
              <ProfileMenuLoadingItem />
            ) : (
              <ListItemButton onClick={onMySubscriptionClick}>
                <ListItemIcon>
                  <PaymentsIcon />
                </ListItemIcon>
                <ListItemText
                  id="switch-list-label-payments"
                  primary={t('mySubscription')}
                />
                {!isDesktop && (
                  <IconButton edge="end">
                    <ArrowForwardIosIcon />
                  </IconButton>
                )}
              </ListItemButton>
            ))}
          {isLoading ? (
            <ProfileMenuLoadingItem renderThird />
          ) : (
            <ListItem>
              <ListItemIcon>
                <NotificationIcon />
              </ListItemIcon>
              <ListItemText
                id="switch-list-label-notifications"
                primary={t('myNotifications')}
              />
              <Switch
                edge="end"
                inputProps={{
                  'aria-labelledby': 'switch-list-label-notifications',
                }}
                checked={user?.isNotificationEnabled || false}
                onClick={handleChangeNotification}
              />
            </ListItem>
          )}
          {isLoading ? (
            <ProfileMenuLoadingItem renderThird />
          ) : (
            <ListItem>
              <ListItemIcon>
                <ModeIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-label-mode" primary={t('dark')} />
              <Switch
                onClick={toggleColorMode}
                checked={theme.palette.mode === EColorModes.dark}
                edge="end"
                inputProps={{
                  'aria-labelledby': 'switch-list-label-mode',
                }}
              />
            </ListItem>
          )}
          {isLoading ? (
            <ProfileMenuLoadingItem
              renderThird
              thirdProps={{ width: locationWidth, height: 72 }}
              sx={{ height: 72 }}
            />
          ) : (
            <ListItem sx={{ alignItems: 'center' }}>
              <ListItemIcon>
                <MyLocationOutlined color="primary" />
              </ListItemIcon>
              <ListItemText primary={t('location')} />
              <FormControl sx={{ marginBottom: '0' }}>
                <InputLabel disableAnimation shrink={false} focused={false}>
                  {t('location')}
                </InputLabel>
                <LocationSelect
                  sx={{
                    width: locationWidth,
                    marginRight: '-8px',
                  }}
                  itemSx={{ width: locationWidth }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                    transformOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    PaperProps: {
                      sx: {
                        maxHeight: '300px',
                      },
                    },
                  }}
                  name="locationId"
                  value={user?.locationId !== null ? `${user?.locationId}` : ''}
                  onChange={handleLocationSelectChange}
                />
              </FormControl>
            </ListItem>
          )}
        </List>
        <Divider />
        <List>
          {isLoading ? (
            <ProfileMenuLoadingItem />
          ) : (
            <ListItemButton
              onClick={() => {
                navigate('/');
                dispatch(signout());
              }}
            >
              <ListItemIcon>
                <ExitIcon />
              </ListItemIcon>
              <ListItemText
                id="switch-list-label-disconnect"
                sx={{ color: theme.palette.customRed.main }}
              >
                {t('logout')}
              </ListItemText>
            </ListItemButton>
          )}
        </List>
      </Box>
    </>
  );
};

export default ProfilePage;
