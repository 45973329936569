import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe, Stripe } from '@stripe/stripe-js';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GeneralFuctionType } from '../../@types/props.types';
import useAuthContext from '../../hooks/useAuthContext';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import StripeService from '../../services/stripe.service';
import userService from '../../services/user.service';
import {
  fetchStarted,
  resultLoaded,
  setStripeData,
  userUpdated,
} from '../../store/slices/api.slice';
import { useDispatch } from '../../store/store';
import { getCurrentLanguageLocale } from '../../utils/localizations';
import BlockHeader from '../BlockHeader';
import LoadingScreen from '../LoadingScreen';
import PaymentForm from '../payment';
import { useTranslation } from 'react-i18next';
import { PlanRecurrence } from '../../enums/plan-recurrence.enum';

const SignupFour = ({
  handleActiveSectionChange,
}: {
  handleActiveSectionChange: GeneralFuctionType;
}) => {
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();
  const theme = useTheme();
  const { t, i18n } = useTranslation('login');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { stripeCustomerInfo, setStripeInfo } = useAuthContext();
  const [errorMessage, setErrorMessage] = useState('');
  const appearance = {
    theme: 'stripe',
  } as Appearance;
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    let isActive = true;

    if (isActive) {
      setIsLoading(true);
      const locale: any = getCurrentLanguageLocale(i18n.language);

      loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '', {
        locale,
      })
        .then((stripeObject) => setStripe(stripeObject))
        .then(() => setIsLoading(false))
        .catch((error) => console.log(error));
    }

    return () => {
      isActive = false;
    };
  }, [i18n.language]);

  useEffect(() => {
    dispatch(fetchStarted());
    if (!stripeCustomerInfo) {
      StripeService.setupIntents()
        .then((resp) => {
          dispatch(setStripeData(resp.data));
          setStripeInfo({
            ...stripeCustomerInfo,
            ...resp.data,
          });
          handleActiveSectionChange(4);
        })
        .catch((e) => {
          console.error(e);
        });
    }
    dispatch(resultLoaded());
  }, []);

  const options = {
    // passing the client secret obtained in step 3
    clientSecret: stripeCustomerInfo.clientSecret,
    // Fully customizable with appearance API.
    appearance,
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    },
  };

  const childRef = useRef<HTMLFormElement>(null);

  const handleSubmit = () => {
    setErrorMessage('');
    setIsLoading(true);
    dispatch(fetchStarted());
    childRef?.current?.callSubmit();
  };

  const handlePaymentCallback = async (isSuccess: boolean, error?: string) => {
    dispatch(resultLoaded());
    if (!isSuccess) {
      setIsLoading(false);
      return setErrorMessage(error as string);
    }
    const resp = await userService.me();
    dispatch(userUpdated(resp.data));
    setIsLoading(false);
    navigate('/payment-success');
  };

  return (
    <>
      {isDesktop ? (
        <BlockHeader title={t('page', { page: 4 })} />
      ) : (
        <Divider sx={{ color: theme.palette.primary.main, width: '100%' }}>
          {t('page', { page: 4 })}
        </Divider>
      )}
      <Box
        sx={{ textAlign: 'center', marginBottom: '24px', marginTop: '24px' }}
      >
        <Typography variant={'h1'}>{t('signup.stepFourTitle')}</Typography>
        <Typography variant={'h3'}>
          {t('signup.stepFourDescription')}
        </Typography>
      </Box>
      <Box
        display="flex"
        sx={{
          padding: isDesktop ? '24px' : '16px',
          backgroundColor: theme.palette.customGrey.dark,
          borderRadius: '12px',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: isDesktop ? '24px' : '16px',
          gap: '16px',
        }}
      >
        <Typography variant="body2" flex={1}>
          {t('plan.selectedPlan', {
            plan:
              stripeCustomerInfo.selectedPlan.recurrence ===
              PlanRecurrence.Yearly
                ? t('plan.annual')
                : t('plan.monthly'),
          })}
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {`${stripeCustomerInfo.selectedPlan.price}€`}
          </Typography>
          {stripeCustomerInfo?.selectedPlan?.metadata?.trialPeriodDays && (
            <Typography variant="caption" fontSize="10px" textAlign="center">
              ({t('plan.afterTrial')})
            </Typography>
          )}
        </Box>
      </Box>
      <Elements stripe={stripe} options={options}>
        <PaymentForm callback={handlePaymentCallback} ref={childRef} />
      </Elements>
      <Typography variant="caption">
        {t('signup.stepFourCardDescription')}
      </Typography>
      {errorMessage.length > 0 && (
        <Typography sx={{ color: 'primary.main', textAlign: 'center' }}>
          {errorMessage}
        </Typography>
      )}
      <Button
        fullWidth
        data-wait={t('signup.pleaseWaitButtonText')}
        sx={{ marginTop: '10px' }}
        onClick={handleSubmit}
      >
        {t('signup.createSubscriptionButtonText')}
      </Button>
      {isLoading && <LoadingScreen />}
    </>
  );
};

export default SignupFour;
