import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  useMediaQuery,
} from '@mui/material';
import { ThemeProvider } from 'styled-components';
import { StyledEngineProvider } from '@mui/styled-engine';
import * as React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import routes from './routes';
import { setupResponseInterceptor } from './services/http.common';
import WebSocketProvider from './sockets/WebSocket';
import { store } from './store/store';
import components from './theme/components';
import header from './theme/header';
import paletteDark from './theme/paletteDark';
import paletteLight from './theme/paletteLight';
import sidebar from './theme/sidebar';
import typography from './theme/typography';
import { HelmetProvider } from 'react-helmet-async';

setupResponseInterceptor(store);

export enum EColorModes {
  light = 'light',
  dark = 'dark',
}

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const themeColor = localStorage.getItem('colorMode');

  if (!themeColor) {
    localStorage.setItem(
      'colorMode',
      prefersDarkMode ? EColorModes.dark : EColorModes.light
    );
  }

  const [mode, setMode] = React.useState<EColorModes>(
    themeColor
      ? (themeColor as EColorModes)
      : prefersDarkMode
      ? EColorModes.dark
      : EColorModes.light
  );

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        const currentThemeColor = localStorage.getItem('colorMode');
        localStorage.setItem(
          'colorMode',
          (currentThemeColor as EColorModes) === EColorModes.light
            ? EColorModes.dark
            : EColorModes.light
        );
        setMode((prevMode) =>
          prevMode === EColorModes.light ? EColorModes.dark : EColorModes.light
        );
      },
    }),
    [mode]
  );
  const rootRoutes = useRoutes(routes);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === EColorModes.dark ? paletteDark : paletteLight),
        },
        typography,
        components,
        sidebar,
        header,
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ReduxProvider store={store}>
        <HelmetProvider>
          <React.Suspense fallback={<div />}>
            <StyledEngineProvider>
              <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                  <WebSocketProvider>
                    <ToastContainer
                      style={{
                        fontSize: '16px',
                        lineHeight: '16px',
                      }}
                      theme={mode}
                      position="top-right"
                      autoClose={3000}
                    />
                    {rootRoutes}
                  </WebSocketProvider>
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </React.Suspense>
        </HelmetProvider>
      </ReduxProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
