import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserRole } from '../../@types/user';
import userService from '../../services/user.service';
import httpService from '../../utils/axios';

export type PostDataType = {
  id: number;
  text: string;
};

export type ResponseDataType = {
  id: number;
  text: string;
  translate: string;
};

export type UserDataType = {
  id: string;
  email: string;
  isNotificationEnabled: boolean;
  phoneNumber: string;
  nickname: string;
  avatarUrl: string;
  name: string;
  accessToken?: string;
  refreshToken?: string;
  referralCode?: string;
  stripeCustomerId?: string;
  subscriptionPlan?: string;
  subscriptionStart?: string;
  role: UserRole;
  subscription?: {
    id: number | null;
    status: string | null;
    details?: string;
  };
  locationId: number | null;
};

const blankData = [
  {
    text: '',
    translate: '',
  },
];

interface AuthSliceState {
  isLoading: boolean;
  registered: boolean;
  loggedin: boolean;
  data: Array<any>;
  user: UserDataType | null;
  stripeCustomerInfo?: any;
}

const initialState: AuthSliceState = {
  isLoading: false,
  registered: false,
  loggedin: false,
  data: blankData,
  user: null,
  stripeCustomerInfo: null,
};

export function fetchUserData() {
  return userService.get();
}

export function addData(data: PostDataType) {
  return httpService.post(`/add`, data);
}

export const getUserDataAsync = createAsyncThunk('data/fetch', async () => {
  const response: any = await fetchUserData();

  if (response.data.status === 1 && response.data.data.length > 0) {
    return response.data.data.concat(blankData);
  }
  return blankData;
});

export const addDataAsync = createAsyncThunk(
  'data/add',
  async (data: PostDataType) => {
    const response: any = await addData(data);
    if (response.data.status === 1 && response.data.data.length > 0) {
      return response.data.data.concat(blankData);
    }
    return blankData;
  }
);

export const apiSlice = createSlice({
  name: 'apiSlice',
  initialState,
  reducers: {
    resultLoaded: (state) => {
      state.isLoading = false;
    },
    fetchStarted: (state) => {
      state.isLoading = true;
    },
    userRegistered: (state) => {
      state.registered = true;
    },
    userLoggedin: (state, payload) => {
      state.loggedin = true;
      state.user = {
        ...state.user,
        ...payload.payload,
      };

      localStorage.setItem('loggedUser', JSON.stringify(state.user));
    },
    userUpdated: (state, payload) => {
      state.user = {
        ...state.user,
        ...payload.payload,
      };
    },
    setStripeData: (state, payload) => {
      state.stripeCustomerInfo = {
        ...payload.payload,
      };
    },
    signout: (state) => {
      state.loggedin = false;
      state.user = null;
      // Question: duplicated with access_token in LocalStorage
      localStorage.removeItem('loggedUser');
      localStorage.removeItem('doss_token');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
    getUser: (state) => {
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDataAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserDataAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getUserDataAsync.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(addDataAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addDataAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(addDataAsync.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  resultLoaded,
  fetchStarted,
  userRegistered,
  userLoggedin,
  userUpdated,
  signout,
  setStripeData,
} = apiSlice.actions;

export default apiSlice.reducer;
