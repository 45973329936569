import { Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BackIcon, BackIconDark } from '../../assets/svgs';
import FriendPending from '../../components/Friends/FriendPending';
import FriendsLoading from '../../components/Friends/FriendsLoading';
import { desktopOnly } from '../../hooks/useIsDesktop';
import friendsService from '../../services/friends.service';
import { useTranslation } from 'react-i18next';
import EmptyStateLayout from '../../layouts/EmptyStateLayout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 48px 16px;

  ${desktopOnly} {
    padding: 64px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
`;

const FriendsPending = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('friends');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pending, setPending] = useState<any>([]);
  const theme = useTheme();

  const getData = async () => {
    try {
      const res = await friendsService.getPending();
      if (res.data) {
        setPending(res.data?.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <RowContainer>
        <div style={{ flex: 1 }}>
          {theme.palette.mode === 'dark' ? (
            <BackIconDark onClick={() => navigate('/invitaramigos')} />
          ) : (
            <BackIcon onClick={() => navigate('/invitaramigos')} />
          )}
        </div>
        <Typography variant="h1" sx={{ fontSize: '24px', fontWeight: 800 }}>
          {t('pendingInvites')}
        </Typography>
        <div style={{ flex: 1 }} />
      </RowContainer>
      {isLoading ? (
        <FriendsLoading />
      ) : (
        <EmptyStateLayout lengthOfData={pending.length}>
          <>
            {pending.map(
              (
                usr: { email: string; id: number; createdAt: string },
                id: number
              ) => (
                <FriendPending
                  key={'pending-' + id}
                  email={usr?.email}
                  id={usr?.id}
                  createdAt={usr?.createdAt}
                />
              )
            )}
          </>
        </EmptyStateLayout>
      )}
    </Container>
  );
};

export default FriendsPending;
