import { Button } from '@mui/material';
import React, { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TStatus } from '../../@types/offer';
import { DoneIcon } from '../../assets/svgs';
import Timer from '../Offers/Timer';
import offersService from '../../services/offers.service';
import { processError } from '../../utils/process-error';
import { useTranslation } from 'react-i18next';

const OfferButton = ({
                       status,
                       price,
                       endDate,
                       id = '',
                       restId = ''
                     }: {
  status: TStatus;
  price: string | number | undefined;
  id: string | undefined;
  restId: string | number | undefined;
  endDate: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('offers');
  const [isLoading, setIsLoading] = useState(false);

  const codeToMessageMapping = useMemo(
    () => ({
      ACTION_LIMIT: t('validation.actionLimit')
    }),
    [t]
  );

  const activateOffer = useCallback(() => {
    setIsLoading(true);

    offersService
      .submitForOffer(id, restId)
      .then(() => {
        navigate(`/ofertas/${id}/activated`);
      })
      .catch((err) => {
        processError(err, codeToMessageMapping);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id, restId, setIsLoading]);

  const buttons = {
    Activated: (
      <Button color='secondary' disabled startIcon={<DoneIcon />}>
        {t('activatedButtonText')}
      </Button>
    ),
    Stopped: (
      <Button color='secondary' disabled startIcon={<DoneIcon />}>
        {t('activatedButtonText')}
      </Button>
    ),
    Proposed: (
      <Button onClick={activateOffer} disabled={isLoading} endIcon={<Timer endDate={endDate} />}>
        {t('availableButtonText', { price })}
      </Button>
    )
  };

  return buttons[status];
};

export default OfferButton;
