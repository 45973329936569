import React, { ReactElement } from 'react';
import { EnglishLanguage, SpanishLanguage } from '../assets/svgs';

interface ILanguage {
  name: string;
  icon: ReactElement;
  localeCode?: string;
}

export enum SUPPORTED_LANGUAGES_CODE {
  Spanish = 'sp',
  English = 'en',
}

export const SUPPORTED_LANGUAGES_INFO: Record<
  SUPPORTED_LANGUAGES_CODE,
  ILanguage
> = {
  [SUPPORTED_LANGUAGES_CODE.Spanish]: {
    name: 'Español',
    icon: <SpanishLanguage />,
    localeCode: 'es',
  },
  [SUPPORTED_LANGUAGES_CODE.English]: {
    name: 'English',
    icon: <EnglishLanguage />,
    localeCode: 'en-gb',
  },
};

export const DEFAULT_LANGUAGE_CODE = SUPPORTED_LANGUAGES_CODE.Spanish;
