import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaymentsSuccessIcon } from '../assets/svgs/index';
import { useIsDesktop } from '../hooks/useIsDesktop';
import LoadingScreen from '../components/LoadingScreen';
import userService from '../services/user.service';
import { userUpdated } from '../store/slices/api.slice';
import { useDispatch } from '../store/store';
import { useTranslation } from 'react-i18next';

const PaymentSuccess = () => {
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const dispatch = useDispatch();

  const navigateToOffers = async () => {
    setLoading(true);

    const resp = await userService.me();
    dispatch(userUpdated(resp.data));

    navigate('/ofertas');
  };

  return (
    <>
      {isLoading && <LoadingScreen />}
      <Box sx={{ textAlign: 'center' }}>
        <PaymentsSuccessIcon
          style={{
            margin: `0 auto ${isDesktop ? '48px' : '24px'} auto`,
            width: isDesktop ? 'auto' : '94px',
            height: isDesktop ? 'auto' : '94px',
          }}
        />
        <Typography variant="h1">{t('signup.finishedTitle')}</Typography>
        <Typography
          variant="h3"
          sx={{
            paddingTop: isDesktop ? '11px' : '8px',
            paddingBottom: isDesktop ? '32px' : '16px',
          }}
        >
          {t('signup.finishedDescription')}
        </Typography>
        <Button fullWidth onClick={navigateToOffers}>
          {t('signup.initSessionButtonText')}
        </Button>
      </Box>
    </>
  );
};

export default PaymentSuccess;
