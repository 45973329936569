import Http from './http.common';

class StripeService {
  apiUrl = '/subscriptions';

  setupIntents() {
    return Http.post(this.apiUrl + '/setup-intents');
  }

  getSubscriptionUrl(data: any) {
    return Http.post(this.apiUrl + '/sessions', data);
  }

  getSubscriptionStatus() {
    return Http.get(this.apiUrl + '/current');
  }

  subscribe(data: any) {
    return Http.post(this.apiUrl + '/subscribe', data);
  }
}

export default new StripeService();
