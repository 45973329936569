import { InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import GlassIcon from '../../assets/svgs/glass.svg';
import VectorIcon from '../../assets/svgs/Vector.svg';
import VectorIconActive from '../../assets/svgs/Vector_active.svg';

import { SearchContext } from '../../contexts/UserContext';

const useStyles = makeStyles((theme) => ({
  search: {
    '&.MuiFormControl-root': {
      margin: '0',
    },
    '& .MuiOutlinedInput-root': {
      height: '40px',
      '@media (min-width:700px)': {
        height: '64px',
        borderRadius: '16px',
      },
    },

    '& .MuiInputAdornment-positionStart img': {
      height: '18px',
      width: '18px',
      '@media (min-width:700px)': {
        height: '26px',
        width: '26px',
      },
    },

    '& .MuiInputAdornment-positionEnd img': {
      height: '20px',
      width: '20px',
      '@media (min-width:700px)': {
        height: '32px',
        width: '32px',
      },
    },
  },
}));

const Search = () => {
  const { t } = useTranslation('offers');
  const classes = useStyles();
  const [activeGeoSearch, setActiveGeoSearch] = useState(false);

  const { updateSearchValue, searchValue } = useContext(SearchContext);
  const onChange = (e: { target: { value: string } }) => {
    updateSearchValue({ title: e?.target?.value || '' });
  };

  const handleLocationClick = () => {
    setActiveGeoSearch((prev) => !prev);

    if (activeGeoSearch) {
      return updateSearchValue({ title: searchValue.title });
    }

    navigator.geolocation.getCurrentPosition(
      function (position) {
        updateSearchValue({
          ...searchValue,
          lon: position.coords.latitude,
          lat: position.coords.longitude,
        });
      },
      () => {
        toast.error('Geolocation search wont work, because you disabled it');
      }
    );
  };

  return (
    <TextField
      placeholder={t('searchPlaceholder') || undefined}
      variant="outlined"
      fullWidth
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img src={GlassIcon} alt="Search Icon" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            onClick={handleLocationClick}
            sx={{ cursor: 'pointer' }}
          >
            <img
              src={activeGeoSearch ? VectorIconActive : VectorIcon}
              alt="Vector Icon"
            />
          </InputAdornment>
        ),
      }}
      className={classes.search}
    />
  );
};

export default Search;
