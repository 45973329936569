import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Union1 from '../../assets/svgs/Burger.svg';
import Union2 from '../../assets/svgs/Clear.svg';
import LogoBlack from '../../assets/svgs/logo-black.svg';
import LogoWhite from '../../assets/svgs/logo-white.svg';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import { signout } from '../../store/slices/api.slice';
import { useDispatch, useTypedSelector } from '../../store/store';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    paddingRight: '32px',
    paddingLeft: '32px',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'initial',
    paddingBottom: '24px',
    paddingTop: '24px',
  },
  imgContainer: {
    maxWidth: '163px',
  },
  menuList: {
    display: 'flex',
    alignItems: 'center',
    gridColumnGap: '12px',
    gridRowGap: '12px',
  },
}));

const TopLayout = () => {
  const dispatch = useDispatch();
  const [isMobileClicked, updateIsMobileClicked] = useState(false);
  const { loggedin } = useTypedSelector((state) => state.auth);
  const classes = useStyles();
  const isDesktop = useIsDesktop();
  const { t } = useTranslation('landing');
  const handleSignout = () => {
    dispatch(signout());
  };

  const handleClickMobileMenuOpen = () => {
    updateIsMobileClicked(true);
  };

  const handleClickMobileMenuClose = () => {
    updateIsMobileClicked(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <img src={LogoBlack} loading="lazy" alt="Doss logo" />
      </div>
      {isMobileClicked && (
        <div
          style={{
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            padding: '24px 32px',
            backgroundColor: 'rgb(29, 29, 29)',
            color: 'white',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            gap: '24px',
            zIndex: 99,
          }}
        >
          {loggedin ? (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div className={classes.imgContainer}>
                  <img src={LogoWhite} loading="lazy" alt="Doss logo" />
                </div>
                <div id="home-menu-icon--mobile">
                  <img
                    src={Union2}
                    loading="lazy"
                    alt=""
                    onClick={handleClickMobileMenuClose}
                  />
                </div>
              </div>
              <Link
                style={{ fontSize: '1.5rem', paddingTop: '16px' }}
                to="/ofertas"
              >
                {t('Panel')}
              </Link>
              <Link
                style={{ fontSize: '1.5rem', paddingTop: '16px' }}
                to="/"
                onClick={handleSignout}
              >
                {t('Log Out')}
              </Link>
            </>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div className={classes.imgContainer}>
                  <img src={LogoWhite} loading="lazy" alt="Doss logo" />
                </div>
                <div id="home-menu-icon--mobile">
                  <img
                    src={Union2}
                    loading="lazy"
                    alt=""
                    onClick={handleClickMobileMenuClose}
                  />
                </div>
              </div>
              <Link
                style={{ fontSize: '1.5rem', paddingTop: '16px' }}
                to="/signup"
              >
                {t('Sign Up')}
              </Link>
              <Link
                style={{ fontSize: '1.5rem', paddingTop: '16px' }}
                to="/login"
              >
                {t('Login')}
              </Link>
            </>
          )}
        </div>
      )}
      {isDesktop ? (
        <div id="home-menu-list" className="menu-home__menu-list">
          {loggedin ? (
            <>
              <Link to="/ofertas" className="menu-list__button--orange w-button">
                {t('Panel')}
              </Link>
              <Link
                to="/"
                onClick={handleSignout}
                className="menu-list__button--light w-button"
              >
                {t('Log Out')}
              </Link>
            </>
          ) : (
            <>
              <Link to="/signup" className="menu-list__button--orange w-button">
                {t('Sign Up')}
              </Link>
              <Link to="/login" className="menu-list__button--light w-button">
                {t('Login')}
              </Link>
            </>
          )}
        </div>
      ) : (
        <div id="home-menu-icon--mobile">
          {isMobileClicked ? (
            <img
              src={Union2}
              loading="lazy"
              alt=""
              onClick={handleClickMobileMenuClose}
            />
          ) : (
            <img
              src={Union1}
              loading="lazy"
              alt=""
              onClick={handleClickMobileMenuOpen}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TopLayout;
