import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { AlertIcon } from '../../assets/svgs';
import UserEventStatus from '../../enums/user-event-status.enum';
import eventsService from '../../services/events.service';
import { processError } from '../../utils/process-error';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
`;

const codeToErrorMessageMapping = {
  ACTION_LIMIT: 'Solo te puedes registrar a dos eventos por mes'
};

const EventButton = ({
                       id,
                       userEvent,
                       setUpdated
                     }: {
  id: string | number;
  userEvent: any;
  setUpdated: any;
}) => {
  const isParticipant = !!userEvent;
  const initialIsDisabled = userEvent?.status === UserEventStatus.Canceled;

  const { t } = useTranslation(['events', 'common']);
  const [isDisabled, setIsDisabled] = useState<boolean>(initialIsDisabled);
  const [open, setOpen] = useState<boolean>(false);

  const registerToEvent = useCallback(async () => {
    setIsDisabled(true);

    try {
      await eventsService.submitForEvent(id);
      setUpdated(new Date());
    } catch (e) {
      processError(e, codeToErrorMessageMapping);
    } finally {
      setIsDisabled(false);
    }
  }, [id]);

  const handleClose = (isAgree: boolean) => () => {
    setOpen(false);

    if (!isAgree) {
      return;
    }

    eventsService
      .cancelEvent(id)
      .then(() => {
        setIsDisabled(true);
        setUpdated(new Date());
      })
      .catch((e) => {
        processError(e, codeToErrorMessageMapping);
      });
  };
  const cancelEvent = () => {
    setOpen(true);
  };

  return (
    <>
      {isParticipant ? (
        <Container>
          <Button disabled={isDisabled} color='secondary' onClick={cancelEvent}>
            {isDisabled ? t('cancelledButtonText') : t('cancel')}
          </Button>
        </Container>
      ) : (
        <Container>
          <Button disabled={isDisabled} onClick={registerToEvent}>{t('availableButtonText')}</Button>
        </Container>
      )}
      <Dialog
        maxWidth='xs'
        open={open}
        onClose={handleClose(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <div style={{ padding: '32px' }}>
          <DialogTitle id='alert-dialog-title'>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <AlertIcon />
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText align='center' id='alert-dialog-description'>
              {t('cancelDialogText')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: 'auto',
                flexWrap: 'wrap',
                gap: '16px'
              }}
            >
              <Button fullWidth onClick={handleClose(true)} autoFocus>
                {t('confirm')}
              </Button>
              <Button fullWidth color='secondary' onClick={handleClose(false)}>
                {t('cancel')}
              </Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default EventButton;
