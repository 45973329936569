import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily: ['Mulish', 'sans-serif'].join(','),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontSize: '24px',
    fontWeight: 800,
    lineHeight: '120%',
    padding: '8px 0',
    '@media (min-width:700px)': {
      fontSize: '32px',
    },
  },
  h2: {
    fontSize: '20px',
    fontWeight: '700',
    '@media (min-width:700px)': {
      fontSize: '28px',
    },
  },
  h3: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    '@media (min-width:700px)': {
      fontSize: '20px',
    },
  },
  h5: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '150%',
    '@media (min-width:700px)': {
      fontSize: '16px',
    },
  },
  body1: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '150%',
    '@media (min-width:700px)': {
      fontSize: '24px',
    },
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
    '@media (min-width:700px)': {
      fontSize: '20px',
    },
  },
  button: {
    fontSize: '16px',
    fontWeight: 400,
    textTransform: 'none',
    '@media (min-width:700px)': {
      fontSize: '20px',
    },
  },
  caption: {
    fontSize: '12px',
    fontWeight: 500,
    '@media (min-width:700px)': {
      fontSize: '16px',
    },
  },
};

export default typography;
