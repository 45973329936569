import { Box, Skeleton, useTheme } from '@mui/material';
import React from 'react';
import { BackIcon } from '../../assets/svgs';
import {
  Container,
  DesktopContainer,
  OfferValida,
  ProviderContainer,
  RecommendationsContainer,
  TitleContainer,
  ValidationContainer,
} from '../../pages/Offers/OfferPage/components';

export const DesktopOfferLoading = () => {
  return (
    <DesktopContainer>
      <Box display="flex" width="100%" gap="32px">
        <Skeleton
          width="50%"
          height="540px"
          sx={{ borderRadius: '15px', marginTop: '-120px' }}
          component="div"
        />
        <Box
          flex={0.5}
          display="flex"
          flexDirection="column"
          gap="10px"
          width="100%"
        >
          <Skeleton height={60} width={300} />
          <Box display="flex" gap="10px" alignItems="center">
            <Skeleton
              component="div"
              width="48px"
              height="75px"
              sx={{ marginTop: '-15px', marginBottom: '-10px' }}
            />
            <Skeleton width={160} />
          </Box>
          <Box display="flex" gap="10px" alignItems="center">
            <Skeleton
              component="div"
              width="48px"
              height="75px"
              sx={{ marginTop: '-15px', marginBottom: '-10px' }}
            />
            <Skeleton width={160} />
          </Box>

          <>
            <Skeleton height={30} width={420} component="div" />
            <Skeleton
              height={30}
              width={200}
              component="div"
              sx={{ marginTop: '-20px' }}
            />

            <Skeleton width={70} />
          </>
          <ValidationContainer>
            <OfferValida>
              <Skeleton width={80} />
              <Skeleton width={50} height={50} sx={{ borderRadius: '15px' }} />
            </OfferValida>
            <Skeleton />
            <Skeleton
              height={120}
              sx={{ marginBottom: '-20px', marginTop: '-20px' }}
            />
          </ValidationContainer>
        </Box>
      </Box>
      <Skeleton width={300} height={50} />
      <RecommendationsContainer>
        <Skeleton width={210} height={265} sx={{ marginTop: '-60px' }} />
        <Skeleton width={210} height={265} sx={{ marginTop: '-60px' }} />
        <Skeleton width={210} height={265} sx={{ marginTop: '-60px' }} />
        <Skeleton width={210} height={265} sx={{ marginTop: '-60px' }} />
      </RecommendationsContainer>
    </DesktopContainer>
  );
};

export const MobileOfferLoading = () => {
  const theme = useTheme();

  return (
    <Container
      style={{
        backgroundColor: theme.palette.primary.contrastText,
      }}
    >
      <BackIcon
        style={{ position: 'absolute', zIndex: 2, margin: '32px 16px' }}
      />
      <Skeleton
        width="100%"
        height="510px"
        sx={{
          borderRadius: '15px',
          marginTop: '-120px',
          marginBottom: '-120px',
        }}
        component="div"
      />
      <ProviderContainer theme={theme}>
        <TitleContainer theme={theme}>
          <Skeleton width={250} />
          <Skeleton
            component="div"
            width="48px"
            height="75px"
            sx={{ marginTop: '-15px', marginBottom: '-10px' }}
          />
          <Skeleton
            component="div"
            width="48px"
            height="75px"
            sx={{
              marginTop: '-15px',
              marginBottom: '-10px',
              marginLeft: 'auto',
            }}
          />
        </TitleContainer>
        <Skeleton />
        <Skeleton width={200} sx={{ marginTop: '-20px' }} />
        <Skeleton width={100} />

        <Skeleton width={300} height={50} />
        <RecommendationsContainer>
          <Skeleton
            width={111}
            height={202}
            sx={{ marginTop: '-40px', marginBottom: '-20px' }}
          />
          <Skeleton
            width={111}
            height={202}
            sx={{ marginTop: '-40px', marginBottom: '-20px' }}
          />
          <Skeleton
            width={111}
            height={202}
            sx={{ marginTop: '-40px', marginBottom: '-20px' }}
          />
        </RecommendationsContainer>
        <ValidationContainer>
          <OfferValida>
            <Skeleton width={200} height={50} />
            <Skeleton width={50} height={50} sx={{ borderRadius: '15px' }} />
          </OfferValida>
          <Skeleton />
        </ValidationContainer>
        <Skeleton
          height={95}
          sx={{ marginBottom: '-20px', marginTop: '-20px' }}
        />
      </ProviderContainer>
    </Container>
  );
};
