import { makeStyles } from '@mui/styles';
import BackgroundImage from '../../assets/pngs/Frame 203.png';

const useStyles = makeStyles((theme) => ({
  image: {
    objectFit: 'cover',
    maxHeight: '760px',
  },
}));

const CopySection = () => {
  const classes = useStyles();
  return (
    <img
      className={classes.image}
      src={BackgroundImage}
      alt="Background Image"
    />
  );
};

export default CopySection;
