import { PaletteOptions } from '@mui/material/styles/createPalette';

const darkPalette: PaletteOptions = {
  background: {
    paper: '#000000',
    default: '#000000',
  },
  primary: {
    main: '#ea922e',
    dark: '#D0801FFF',
    contrastText: '#0C0C0C',
  },
  secondary: {
    main: '#535150',
    contrastText: '#FFFFFF',
  },
  customRed: {
    main: '#D10000',
    contrastText: '#FFFFFF',
  },
  neutral: {
    main: '#A7A7A7',
    dark: '#FFFFFF',
  },
  error: {
    main: '#FF9D2A',
  },
  warning: {
    main: '#FF9D2A',
  },
  border: {
    main: '#FF9D2A',
  },
  customGrey: {
    main: '#535150',
    dark: '#272727',
    light: '#B3B3B3',
    contrastText: '#FFFFFF',
  },
  customChip: {
    active: '#fff',
    disabled: 'transparent',
    activeTextColor: '#000',
    disabledTextColor: '#fff',
  },
  filterChip: {
    active: '#FFA132',
    disabled: '#1D1D1D',
    activeTextColor: '#fff',
    disabledTextColor: '#A6A6A6',
    borderColor: 'transparent',
  },
};

export default darkPalette;
