import { Button, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useIsDesktop } from '../../hooks/useIsDesktop';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    width: 'calc(100% - 48px)',
    marginBottom: '24px',
    flexDirection: 'column',
    '@media (min-width:1007px)': {
      flexDirection: 'row',
      width: '100%',
      marginBottom: '100px',
      gap: '24px',
    },
  },
}));

const TextWithButtons = () => {
  const classes = useStyles();
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const { t } = useTranslation('landing');

  return (
    <div className={classes.container}>
      <Typography
        sx={{ marginBottom: '32px', marginTop: isDesktop ? '96px' : '24px' }}
        align="center"
        color="neutral.main"
        variant="h3"
      >
        {t('Block2 Header')}
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography
          sx={{
            fontSize: '36px !important',
            fontWeight: 500,
            marginBottom: '56px',
            width: '100%',
            lineHeight: '150%',
          }}
          variant="h1"
          align={isDesktop ? 'left' : 'center'}
        >
          <Trans i18nKey="Title2" t={t} />
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            marginBottom: '32px',
            lineHeight: '150%',
          }}
          color="neutral.dark"
          variant="h3"
          align={isDesktop ? 'left' : 'center'}
        >
          <Trans i18nKey="Subtitle2" t={t} />
        </Typography>
      </Box>
      <div className={classes.buttonsContainer}>
        <Button
          color="primary"
          sx={{
            padding: '16px 24px',
            fontSize: '16px !important',
            fontWeight: 500,
            maxHeight: '52px',
          }}
          onClick={() => navigate('/signup')}
        >
          {t('Sign Up')}
        </Button>
        <Button
          sx={{
            padding: '16px 24px',
            borderColor: '#1D1D1D',
            fontSize: '16px !important',
            fontWeight: 500,
            maxHeight: '52px',
          }}
          color="secondary"
          onClick={() => navigate('/login')}
          size="small"
        >
          {t('Login')}
        </Button>
      </div>
    </div>
  );
};

export default TextWithButtons;
