import { Typography } from '@mui/material';
import React, { FC } from 'react';

const BlockHeader: FC<{ title: string }> = ({ title }) => {
  return (
    <Typography
      component="span"
      position="absolute"
      top={-20}
      marginLeft="auto"
      marginRight="auto"
      color="primary.main"
      bgcolor="background.default"
      px={1}
    >
      {title}
    </Typography>
  );
};

export default BlockHeader;
