import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import PrevArrow from '../../assets/svgs/Arrow Left.svg';
import NextArrow from '../../assets/svgs/Arrow right.svg';
import Case2 from '../../assets/svgs/Frame 229.svg';
import Case3 from '../../assets/svgs/Frame 230.svg';
import Case1 from '../../assets/svgs/Frame 227.svg';
import './index.css';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import { Trans, useTranslation } from 'react-i18next';

const options = {
  type: 'loop',
  pagination: false,
  perPage: 3,
  gap: '32px',
  arrows: false,
  breakpoints: {
    1250: {
      gap: '32px',
    },
    400: {
      gap: '32px',
    },
  },
};

const mobileOptions = {
  type: 'loop',
  pagination: false,
  perPage: 1,
  gap: '32px',
  arrows: false,
  breakpoints: {
    1250: {
      gap: '32px',
    },
    400: {
      gap: '32px',
    },
  },
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 'auto',
    backgroundColor: '#F2F2F2',
  },
  element: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderRadius: '22px',
    borderColor: '#ffffff',
    backgroundColor: '#FFFFFF',
  },
  elementInside: {
    padding: '24px',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    alignItems: 'center',
  },
  image: {
    width: '96px',
    height: '96px',
    borderRadius: '100px',
    objectFit: 'cover',
    marginBottom: '14px',
    position: 'relative',
  },
  buttonsContainer: {
    display: 'flex',
    paddingTop: 32,
    gap: 24,
    justifyContent: 'center',
    marginBottom: 96,
  },
}));

const ReviewsSlider = () => {
  const classes = useStyles();
  const isDesktop = useIsDesktop();
  const { t } = useTranslation('landing');

  return (
    <div className={classes.container}>
      <Typography
        sx={{
          fontSize: isDesktop ? '52px !important' : '2.5rem',
          fontWeight: 800,
          marginBottom: isDesktop ? '27px' : '16px',
          paddingRight: isDesktop ? 'auto' : '16px',
          paddingLeft: isDesktop ? 'auto' : '16px',
          paddingTop: isDesktop ? '96px' : '32px',
          lineHeight: isDesktop ? '62px' : '2.5rem',
        }}
        align="center"
        variant="h1"
      >
        <Trans i18nKey="Reviews Title" t={t} />
      </Typography>
      <Typography
        sx={{
          fontSize: '24px !important',
          fontWeight: 500,
          marginBottom: isDesktop ? '64px' : '24px',
          paddingRight: isDesktop ? 'auto' : '16px',
          paddingLeft: isDesktop ? 'auto' : '16px',
          display: 'flex',
          justifyContent: 'center',
          color: '#5B5B5B',
        }}
        align="center"
        variant="h2"
      >
        <Trans i18nKey="Reviews Description" t={t} />
      </Typography>
      <Splide
        className="section-blog-slider"
        hasTrack={false}
        options={isDesktop ? options : mobileOptions}
      >
        <SplideTrack
          className="w-dyn-list"
          style={{ width: '100%', maxWidth: 'none' }}
        >
          <SplideSlide className="w-dyn-item">
            <div className={classes.element}>
              <div className={classes.elementInside}>
                <div className={classes.imageContainer}>
                  <img
                    src={Case1}
                    loading="lazy"
                    alt="Pre-opening party Copy 3"
                    className={classes.image}
                  />
                  <Typography
                    sx={{
                      fontSize: '24px !important',
                      lineHeight: '28px',
                      fontWeight: 700,
                    }}
                    variant="h1"
                  >
                    Sarah I.
                  </Typography>
                </div>

                <Typography
                  sx={{
                    fontSize: '16px !important',
                    fontWeight: 400,
                    marginBottom: '16px',
                    lineHeight: '24px',
                    color: '#5B5B5B',
                  }}
                  variant="h3"
                >
                  <Trans i18nKey="Review1 Text1" t={t} />
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px !important',
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: '#5B5B5B',
                    paddingBottom: '24px',
                  }}
                  variant="h3"
                >
                  <Trans i18nKey="Review1 Text2" t={t} />
                </Typography>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide className="w-dyn-item">
            <div className={classes.element}>
              <div className={classes.elementInside}>
                <div className={classes.imageContainer}>
                  <img
                    src={Case2}
                    loading="lazy"
                    alt="Pre-opening party Copy 3"
                    className={classes.image}
                  />
                  <Typography
                    sx={{
                      fontSize: '24px !important',
                      lineHeight: '28px',
                      fontWeight: 700,
                    }}
                    variant="h1"
                  >
                    Adrian J.
                  </Typography>
                </div>

                <Typography
                  sx={{
                    fontSize: '16px !important',
                    fontWeight: 400,
                    paddingBottom: '24px',
                    lineHeight: '24px',
                    color: '#5B5B5B',
                  }}
                  variant="h3"
                >
                  <Trans i18nKey="Review1 Text1" t={t} />
                </Typography>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide className="w-dyn-item">
            <div className={classes.element}>
              <div className={classes.elementInside}>
                <div className={classes.imageContainer}>
                  <img
                    src={Case3}
                    loading="lazy"
                    alt="Pre-opening party Copy 3"
                    className={classes.image}
                  />
                  <Typography
                    sx={{
                      fontSize: '24px !important',
                      lineHeight: '28px',
                      fontWeight: 700,
                    }}
                    variant="h1"
                  >
                    Luis A.
                  </Typography>
                </div>

                <Typography
                  sx={{
                    fontSize: '16px !important',
                    fontWeight: 400,
                    marginBottom: '16px',
                    lineHeight: '24px',
                    color: '#5B5B5B',
                  }}
                  variant="h3"
                >
                  <Trans i18nKey="Review1 Text1" t={t} />
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px !important',
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: '#5B5B5B',
                    paddingBottom: '24px',
                  }}
                  variant="h3"
                >
                  <Trans i18nKey="Review1 Text2" t={t} />
                </Typography>
              </div>
            </div>
          </SplideSlide>
        </SplideTrack>
        {isDesktop && (
          <div className={classes.buttonsContainer}>
            <button>
              <img src={PrevArrow} alt="Prev" />
            </button>
            <button>
              <img src={NextArrow} alt="Next" />
            </button>
          </div>
        )}
      </Splide>
    </div>
  );
};

export default ReviewsSlider;
