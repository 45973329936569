import styled from 'styled-components';
import { desktopOnly } from '../../hooks/useIsDesktop';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px 68px 16px;
  gap: 8px;

  ${desktopOnly} {
    padding: 114px 64px;
  }
`;

export const ResposiveContainer = styled.div`
  width: 100%;

  ${desktopOnly} {
    display: flex;
    flex-wrap: wrap;
    flex: 50%;
    gap: 64px;
    justify-content: space-around;
  }
`;

export const ResponsiveElement = styled.div`
  flex: 1;

  ${desktopOnly} {
    flex: 0.5;
  }
`;

export const UsersContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  padding: 12px;
  background-color: #F2F2F2;
`;

export const DesktopUsersList = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.palette.customGrey.dark};
  border-radius: 16px;
  flex-direction: column;
  padding: 24px;
  flex: 1;
`;
