// import IUser from "../types/user.type";
import Http from './http.common';

class AuthService {
  apiUrl = '/auth';

  login(data: any) {
    return Http.post(this.apiUrl + '/login', data);
  }

  create(data: any) {
    return Http.post<any>(this.apiUrl + '/register', data);
  }

  sendVerificationCode(data: any) {
    return Http.post(this.apiUrl + `/send-phone-code`, data);
  }

  verifyCode(data: any) {
    return Http.post(this.apiUrl + `/verify-code`, data);
  }

  refreshToken(data: { headers: { 'Refresh-Token': string } }) {
    return Http.post(this.apiUrl + '/refresh-session', undefined, data);
  }
}

export default new AuthService();
