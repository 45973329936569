// import IUser from "../types/user.type";
import Http from './http.common';

class OffersService {
  apiUrl = '/offers';

  getOffersList(
    searchValue: any,
    filters: string[],
    locationId?: number | null
  ) {
    const options: any = { params: { page: 1, limit: 100, type: filters } };

    if (searchValue.title && searchValue.title.length > 0) {
      options.params.title = searchValue.title;
    }

    if (searchValue.lon && searchValue.lat) {
      options.params.lon = searchValue.lon;
      options.params.lat = searchValue.lat;
    }

    if (typeof locationId === 'number') {
      options.headers = { 'X-Location-Id': locationId };
    }

    return Http.get(this.apiUrl + '/', options);
  }

  getOffer(id: string) {
    return Http.get(this.apiUrl + `/${id}`);
  }

  submitForOffer(id: string, restId: string | number) {
    return Http.post(this.apiUrl + `/${id}/apply`, {
      restaurantId: Number(restId),
    });
  }

  getFilters() {
    return Http.get('/restaurants/types');
  }
}

export default new OffersService();
