import {
  IconButton,
  MenuItem,
  Stack,
  Button,
  Menu,
  MenuItemProps,
  MenuProps,
  ButtonProps,
  IconButtonProps,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { SUPPORTED_LANGUAGES_INFO } from '../../constants/localizations.constants';
import { getCurrentLanguageCode } from '../../utils/localizations';

const menuMinWidth = '140px';
const menuMaxWidth = '160px';

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: '8px',
  minWidth: menuMinWidth,
  maxWidth: menuMaxWidth,
  height: '30px !important',
  color: theme.palette.neutral.main,
  border: `2px solid ${theme.palette.neutral.main}`,
  backgroundColor: 'transparent !important',
  '& .MuiButton-endIcon': {
    '& path': {
      fill: `${theme.palette.neutral.main} !important`,
    },
  },
  fontSize: '1rem !important',
  fontWeight: 'bold',

  // needed to set it as in TopAppLayout
  margin: `0 12px`,
}));

const CustomMenu = styled(Menu)<MenuProps>(() => ({
  '& .MuiMenu-paper': {
    minWidth: menuMinWidth,
    maxWidth: menuMaxWidth,
  },
}));

const CustomIconButton = styled(IconButton)<IconButtonProps>(() => ({
  width: 'auto !important',
  height: 'auto !important',
  '& svg': {
    width: '24px !important',
    height: '24px !important',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const CustomMenuItem = styled(MenuItem)<MenuItemProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  gap: '8px',
}));

interface ILanguagesSelect {
  variant: 'mobile' | 'desktop';
}

const LanguagesSelect: FC<ILanguagesSelect> = ({ variant }) => {
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = async (languageCode: string) => {
    await i18n.changeLanguage(languageCode);
    handleClose();
  };

  const currentLanguageCode = getCurrentLanguageCode(i18n.language);

  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="center">
      {variant === 'desktop' && (
        <CustomButton
          endIcon={<KeyboardArrowDownIcon fontSize="small" />}
          id="language-select-menu-open-button"
          aria-controls={open ? 'language-select-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '1rem !important',
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {SUPPORTED_LANGUAGES_INFO[currentLanguageCode].name}
          </Typography>
        </CustomButton>
      )}
      {variant === 'mobile' && (
        <CustomIconButton
          id="language-select-menu-open-button"
          aria-controls={open ? 'language-select-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          {SUPPORTED_LANGUAGES_INFO[currentLanguageCode].icon}
        </CustomIconButton>
      )}
      <CustomMenu
        id="language-select-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'language-select-menu-open-button',
        }}
      >
        {Object.entries(SUPPORTED_LANGUAGES_INFO).map(([code, lang]) => (
          <CustomMenuItem
            onClick={() => handleChange(code)}
            key={`lang-${code}`}
          >
            <Typography
              sx={{
                fontSize: '1rem !important',
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {lang.name}
            </Typography>
            {currentLanguageCode === code && <CheckOutlinedIcon />}
          </CustomMenuItem>
        ))}
      </CustomMenu>
    </Stack>
  );
};

export default LanguagesSelect;
