import styled from 'styled-components';
import { desktopOnly } from '../../hooks/useIsDesktop';

export const Container = styled.div<{ theme: any }>`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 8px;
  background: ${(props) =>
    props.theme.palette.mode === 'dark'
      ? props.theme.palette.customGrey.dark
      : ''};
  border: 1px solid ${(props) => props.theme.palette.customGrey.dark};

  canvas {
    max-height: 120px;
  }
  ${desktopOnly} {
    align-items: center;
    text-align: center;
    padding: 24px 0;

    canvas {
      max-height: 260px;
    }
  }
`;
