import { Avatar, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { nameToAvatar, nameToColor } from '../../utils/avatar';
import { formatDate, REFERRAL_DATE_FORMAT } from '../../utils/format-date';

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
`;

interface IAcceptedFriendProps {
  usr: {
    avatarUrl: string | undefined;
    referral: any;
    nickname: string;
    createdAt: string;
    email: string;
  };
}

const FriendAccepted: FC<IAcceptedFriendProps> = ({ usr }) => {
  const { i18n } = useTranslation();

  return (
    <RowContainer>
      <Avatar
        alt="Avatar"
        src={usr?.referral?.avatarUrl}
        sx={{
          width: '40px !important',
          height: '40px !important',
          bgcolor: nameToColor(usr?.referral?.name),
        }}
        {...nameToAvatar(usr?.referral?.name)}
      />
      <Typography
        variant="h3"
        color="neutral.dark"
        sx={{
          fontSize: '16px',
          fontWeight: 700,
          flex: 1,
        }}
      >
        {usr?.referral?.name || usr.email}
      </Typography>
      <Typography
        variant="h3"
        color="neutral.main"
        sx={{
          fontSize: '12px',
          fontWeight: 600,
          display: 'flex',
          flexDirection: 'row',
          gap: '6px',
          alignItems: 'center',
        }}
      >
        {` ${formatDate(usr?.createdAt, REFERRAL_DATE_FORMAT, i18n.language)}`}
      </Typography>
    </RowContainer>
  );
};

export default FriendAccepted;
