import { createTheme, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import BenefitsSlider from '../components/BenefitsSlider/BenefitsSlider';
import CopySection from '../components/CopySection/CopySection';
import FeedbackCopy from '../components/FeedbackCopy/FeedbackCopy';
import BottomLayout from '../components/layout/BottomLayout';
import TopLayout from '../components/layout/TopLayout';
import OffersSlider from '../components/OffersSlider/OffersSlider';
import ReviewsSlider from '../components/ReviewsSlider/ReviewsSlider';
import TextWithButtons from '../components/TextWithButtons/TextWithButtons';
import TextWithCopyButton from '../components/TextWithCopyButton/TextWithCopyButton';
import TextWithCopyCentred from '../components/TextWithCopyCentered/TextWithCopyCentred';
import TextWithEmojiButtons from '../components/TextWithEmojiButtons/TextWithEmojiButtons';
import components from '../theme/components';
import header from '../theme/header';
import paletteLight from '../theme/paletteLight';
import sidebar from '../theme/sidebar';
import typography from '../theme/typography';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF !important',
    color: 'black !important',
  },
}));

const NewLanding = () => {
  const classes = useStyles();
  const { t } = useTranslation('landing');
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
          ...paletteLight,
        },
        typography,
        components,
        sidebar,
        header,
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DOSS</title>
        <meta name="description" content="DOSS" />
        <link rel="canonical" href="https://doss.es/" />
      </Helmet>
      <div className={classes.container}>
        <TopLayout />
        <TextWithCopyButton />
        <TextWithButtons />
        <CopySection />
        <TextWithEmojiButtons />
        <FeedbackCopy />
        <OffersSlider />
        <BenefitsSlider />
        <TextWithCopyCentred />
        <ReviewsSlider />
        <BottomLayout />
      </div>
      <CookieConsent
        buttonText={t('Accept')}
        style={{ backgroundColor: 'black' }}
        buttonStyle={{
          marginLeft: '15px',
          height: '56px',
          borderRadius: '8px',
          backgroundColor: '#FFA132',
          color: 'white',
          padding: '8px 24px',
          fontSize: '16px !important',
          fontWeight: 500,
        }}
      >
        {t('Cookie Alert')}
      </CookieConsent>
    </ThemeProvider>
  );
};

export default NewLanding;
