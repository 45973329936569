import { Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EventIcon, EventIconWhite, PersonIcon } from '../../assets/svgs';
import {
  AlignedTypography,
  BadgeContainer,
  CustomPaper,
  ImageWrapper,
  LogoWrapper,
  OfferContainer,
  OfferImage,
} from '../../pages/Events/components';
import { useTranslation } from 'react-i18next';
import { EVENTS_DATE_FORMAT, formatDate } from '../../utils/format-date';

interface EventListItemProps {
  el: {
    imageUrl: string | undefined;
    title: string;
    participantsCount: number;
    participantsLimit: number;
    startDate: string;
    userOffers: any;
    id: string;
    description: string;
    price: string | undefined;
    like: string | undefined;
  };
}

const EventListItem: FC<EventListItemProps> = ({ el }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t, i18n } = useTranslation('events');
  const [loadImage, setLoadImage] = useState(true);

  return (
    <OfferContainer
      style={{
        filter:
          el?.participantsCount === el?.participantsLimit
            ? 'brightness(50%)'
            : 'brightness(100%)',
        cursor:
          el?.participantsCount === el?.participantsLimit
            ? 'default'
            : 'pointer',
        backgroundColor: theme.palette.background.default,
      }}
      onClick={() => {
        if (el?.participantsCount !== el?.participantsLimit) {
          navigate(`/eventos/${el.id}`);
        }
      }}
    >
      {el?.participantsCount === el?.participantsLimit ? (
        <BadgeContainer theme={theme}>
          <PersonIcon />
          <AlignedTypography variant="h5">{t('full')}</AlignedTypography>
        </BadgeContainer>
      ) : (
        <BadgeContainer theme={theme}>
          <PersonIcon />
          <AlignedTypography variant="h5" color="primary.main">
            {t('places', {
              participants: el?.participantsCount || 0,
              places: el?.participantsLimit,
            })}
          </AlignedTypography>
        </BadgeContainer>
      )}
      <ImageWrapper>
        <OfferImage height={500} src={el?.imageUrl} alt={el.title} />
      </ImageWrapper>
      <CustomPaper theme={theme}>
        {theme.palette.mode === 'dark' ? (
          <LogoWrapper>
            <EventIconWhite />
          </LogoWrapper>
        ) : (
          <LogoWrapper>
            <EventIcon />
          </LogoWrapper>
        )}
        <div>
          <Typography variant="h3">{el.title}</Typography>
          <Typography variant="h5" color="primary.main">
            {formatDate(el?.startDate, EVENTS_DATE_FORMAT, i18n.language)}
          </Typography>
        </div>
      </CustomPaper>
    </OfferContainer>
  );
};

export default EventListItem;
