import { Navigate } from 'react-router-dom';
import PhoneInput from '../../components/login/PhoneInput';
import { useTypedSelector } from '../../store/store';

const Login = () => {
  const { user } = useTypedSelector((state) => state.auth);

  return (
    <>{!user?.accessToken ? <PhoneInput /> : <Navigate to={`/ofertas`} />}</>
  );
};

export default Login;
