import { Stack } from '@mui/material';
import styled from 'styled-components';

export const PreSignUpContainer = styled(Stack)``;

export const VideoContainer = styled('video')`
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: 0;
  filter: brightness(30%);
`;
