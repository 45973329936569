import { Box } from '@mui/material';
import { MessageBox } from 'react-chat-elements';
import styled from 'styled-components';
import { desktopOnly } from '../../hooks/useIsDesktop';

export const Container = styled.div`
  padding: 0 16px 20px;
  height: 100dvh;

  ${desktopOnly} {
    padding: 106px 64px 0;
  }
`;

export const ListContainer = styled.div<{ theme: any }>`
  width: 100%;

  .rce-container-clist {
    & .rce-container-citem:last-child .rce-citem {
      margin-bottom: 76px;

      ${desktopOnly} {
        margin-bottom: 0;
      }
    }
  }

  ${desktopOnly} {
    width: 45%;
    border-top: 1px solid ${(props) => props.theme.palette.customGrey.dark};
  }

  .rce-citem-body--top-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    max-width: 60%;
    margin-top: 5px;

    ${desktopOnly} {
      font-size: 24px;
      font-weight: 800;
    }
  }

  .rce-citem-body--bottom-title {
    line-height: 21px;
    font-size: 14px;
    color: ${(props) => props.theme.palette.neutral.main};
    padding-bottom: 16px;
    border-bottom: 1px solid ${(props) => props.theme.palette.customGrey.dark};

    ${desktopOnly} {
      font-size: 20px;
      line-height: 30px;
      border-bottom: none;
    }
  }

  .rce-citem {
    height: auto;
    background-color: ${(props) => props.theme.palette.background.default};
    margin-bottom: 16px;

    &:hover {
      background-color: ${(props) => props.theme.palette.secondary.main};
    }
  }

  .rce-citem-body {
    border-bottom: none;
  }

  .rce-citem-body--top-time {
    color: ${(props) => props.theme.palette.neutral.dark};
    font-size: 10px;
    line-height: 16px;
    margin-top: 6px;

    ${desktopOnly} {
      font-size: 16px;
    }
  }

  .rce-citem-body--bottom-status span {
    min-width: 18px;
    border-radius: 20px;
    padding: 0 5px;
    width: auto;
  }

  .rce-citem-body--top-time {
    margin-left: auto;
  }

  .rce-avatar-container {
    width: 55px;
    height: 55px;

    ${desktopOnly} {
      width: 65px;
      height: 65px;
    }
  }

  & .rce-avatar-letter {
    margin-top: 0;
    width: inherit;
    height: inherit;
    font-size: 1.25rem;
    ${desktopOnly} {
      font-size: 1.5rem;
    }
  }

  & .rce-avatar-letter-background {
    height: inherit;
    width: inherit;
  }
`;

export const ChatContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
  overflow: hidden;

  ${desktopOnly} {
    border: 1px solid ${(props) => props.theme.palette.customGrey.dark};
  }

  .rce-container-input {
    min-width: 90%;
    width: max-content;
    position: absolute;
    bottom: 0;
    padding: 0 10px;
    margin: 0 10px;
    border-radius: 16px;
    background-color: ${(props) => props.theme.palette.customGrey.dark};
    min-height: 48px;

    ${desktopOnly} {
      bottom: 10px;
    }
  }

  .rce-input {
    background-color: ${(props) => props.theme.palette.customGrey.dark};
    color: ${(props) => props.theme.palette.secondary.contrastText};
    min-height: 40px;
  }

  .rce-input-textarea {
    padding: 10px 5px;
    color: ${(props) => props.theme.palette.secondary.contrastText};
    line-height: 20px;
  }
`;

export const MessagesContainer = styled.div`
  ${desktopOnly} {
    //min-height: 550px;
    height: calc(100vh - 215px);
    min-height: 450px;
    max-height: 100vh;
    margin-bottom: 20px;
    padding-bottom: 50px;
  }

  height: 100%;

  max-height: 78%;

  display: flex !important;
  flex-direction: column-reverse;
  padding-bottom: 2px;
  padding-top: 2px;

  margin-top: 5px;

  .rce-container-mbox {
    min-width: 400px;
  }
`;
export const ChatHeader = styled.div<{ theme: any }>`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.palette.customGrey.dark};
  width: 100%;
  padding: 20px 24px;

  .avatar {
    background-color: ${(props) => props.theme.palette.background.default};
    border-radius: 100%;
    margin: auto 24px auto 5px;
  }

  & .rce-avatar-letter {
    margin-top: 0;
    width: inherit;
    height: inherit;
    font-size: 1.25rem;
  }

  & .rce-avatar-letter-background {
    height: inherit;
    width: inherit;
  }
`;

export const Message = styled(MessageBox)`
  position: relative;
  max-width: 85%;

  .rce-mbox-time {
    position: absolute;
    bottom: -15px !important;
  }

  .rce-mbox-text {
    line-height: 17.5px;
    font-size: 14px;
    margin-bottom: 12px;

    ${desktopOnly} {
      line-height: 25px;
      font-size: 20px;
      font-weight: 400;
    }
  }

  &.should-render-avatar.my-message .rce-mbox {
    border-radius: 16px 16px 0 16px !important;
    margin-right: 0;
    margin-bottom: 16px;
  }

  &.should-render-avatar.friend-message .rce-mbox {
    border-radius: 16px 16px 16px 0 !important;
    margin-left: 0;
    margin-bottom: 16px;
  }

  &.my-message .rce-mbox {
    color: white !important;
    background-color: ${(props) => props.theme.palette.primary.main};
    margin-right: 51px;
    padding: 8px;
    box-shadow: none;
    margin-bottom: 4px;
    margin-top: 0;
    float: right;

    ${desktopOnly} {
      margin-right: 71px;
      padding: 16px;
      margin-bottom: 8px;
    }
  }

  &.friend-message .rce-mbox-title span {
    width: 100%;
    overflow-x: clip;
    text-overflow: ellipsis;
  }

  &.friend-message .rce-mbox {
    margin-left: 51px;
    padding: 8px;
    box-shadow: none;
    margin-bottom: 4px;
    margin-top: 0;
    float: left;

    ${desktopOnly} {
      margin-left: 71px;
      padding: 16px;
      margin-bottom: 8px;
    }
  }

  &.my-message .rce-mbox-title {
    display: none;
  }

  &.my-message .rce-mbox-time {
    color: white;
  }

  &.friend-message .rce-mbox-time {
    margin: 0 5px 5px 0;

    ${desktopOnly} {
      margin: 0;
    }
  }

  .rce-mbox {
    background-color: ${(props) => props.theme.palette.customGrey.dark};
    border-radius: 16px !important;
    max-width: 80%;
  }

  .rce-mbox-title {
    text-decoration: none;
    cursor: default;
    color: ${(props) => (props as any).nicknameColor} !important;
    line-height: 17.5px;
    font-size: 14px;
    width: auto;

    ${desktopOnly} {
      line-height: 25px;
      font-size: 20px;
      font-weight: 700;
    }
  }

  .rce-mbox-time {
    line-height: 10px;
    color: ${(props) => props.theme.palette.secondary.contrastText};
  }

  .rce-mbox-status {
    svg {
      color: white !important;
    }
  }

  &.friend-message .rce-mbox-status {
    display: none;
  }
`;
export const MessageContainer = styled(Box)<{ theme: any }>`
  display: flex;

  .avatar {
    background-color: ${(props) => props.theme.palette.background.default};
    border-radius: 100%;
    margin: auto 8px 8px 8px;

    ${desktopOnly} {
      margin: auto 8px 16px 8px;
    }
  }

  & .rce-avatar-letter {
    margin-top: 0;
    width: inherit;
    height: inherit;
  }

  & .rce-avatar-letter-background {
    ${desktopOnly} {
      height: 55px;
      width: 55px;
    }
  }
`;

export const MobileChatContainer = styled.div`
  width: 100dvw;
  height: 100%;
  overflow: hidden;

  .rce-container-input {
    min-width: 90%;
    width: max-content;
    bottom: 0;
    position: relative;
    border-radius: 16px;
    background-color: ${(props) => props.theme.palette.customGrey.dark};
    min-height: 48px;
  }

  .rce-input {
    background-color: ${(props) => props.theme.palette.customGrey.dark};
    color: ${(props) => props.theme.palette.secondary.contrastText};
    min-height: 40px;
  }

  .rce-input-textarea {
    padding: 10px 5px;
    color: ${(props) => props.theme.palette.secondary.contrastText};
    line-height: 20px;
  }
`;

export const MobileMessagesContainer = styled.div`
  height: 75%;

  display: flex !important;
  flex-direction: column-reverse;
  padding-bottom: 2px;
  padding-top: 4px;

  .rce-container-mbox {
    min-width: 250px;
  }
`;

export const MobileChatHeader = styled.div<{ theme: any }>`
  position: sticky;
  z-index: 1;
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.palette.customGrey.dark};
  background-color: ${(props) => props.theme.palette.background.default};
  width: 100%;
  padding: 16px 24px 16px;
  top: 0;
  min-height: 110px;

  .avatar {
    background-color: ${(props) => props.theme.palette.background.default};
    border-radius: 100%;
    margin: auto 24px auto 5px;
  }

  & .rce-avatar-letter {
    margin-top: 0;
    width: inherit;
    height: inherit;
    font-size: 1rem;
  }

  & .rce-avatar-letter-background {
    height: inherit;
    width: inherit;
  }
`;
