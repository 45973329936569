import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProviderContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 28px;
  gap: 16px;
  margin-top: -28px;
  z-index: 2;
  padding: 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const DesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 128px 20% 0;
  gap: 32px;
`;
