import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import authService from '../services/auth.service';
import axiosInstance from '../services/http.common';
import { signout } from '../store/slices/api.slice';
import { setSession } from './jwt';
import { getWithExpiry } from './localStorage';

let isRefreshing = false;
let failedQueue: Array<any> = [];

const processQueue = (error: AxiosError | Error | null, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const handleResponseError = (error: any, store: any) => {
  const { response } = error;
  const refreshToken = getWithExpiry('refreshToken');

  if (!response) {
    return Promise.reject(error);
  }

  const errorMsg = {
    status: response.status,
    message: response.data.message || '',
    statusText: response.statusText,
  };

  if (response.data?.code === 'NO_ACTIVE_SUBSCRIPTION') {
    window.location.replace(
      `http://${window.location.host}/renew-subscription`
    );
    return;
  }

  if (response.status >= 500 && response.status < 600) {
    errorMsg.statusText = 'Internal server error';
    errorMsg.message = 'Server response with error, please try again later';

    toast.info(`${errorMsg.statusText} \n ${errorMsg.message}`);
  }

  if (response.status === 401) {
    if (
      !refreshToken.value ||
      response.config.url === '/auth/refresh-session'
    ) {
      store.dispatch(signout());
      return;
    }

    const originalRequest = error.config;

    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then(() => axiosInstance(originalRequest))
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    isRefreshing = true;

    return new Promise((resolve, reject) => {
      authService
        .refreshToken({
          headers: { 'Refresh-Token': `${refreshToken.value}` },
        })
        .then(({ data }) => {
          setSession({
            accessToken: data.accessToken,
            accessTokenExpiresAt: data.accessTokenExpiresAt,
          });
          processQueue(null, data.accessToken);
          resolve(axiosInstance(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          store.dispatch(signout());
          reject(err);
        })
        .then(() => {
          isRefreshing = false;
        });
    });
  }

  return Promise.reject(error.response.data);
};

export default handleResponseError;
