import moment from 'moment';
// need to import each locale severally in order not to import all of them
import 'moment/locale/es';
import 'moment/locale/en-gb';

import { DateTime } from 'luxon';
import { getCurrentLanguageLocale } from './localizations';

export const DEFAULT_DATE_FORMAT = 'DD MMM YYYY - h:mm';
export const USER_OFFER_DATE_FORMAT = 'DD MMM YYYY - HH:mm';
export const EVENTS_DATE_FORMAT = 'DD MMM YYYY - HH:mm';
export const REFERRAL_DATE_FORMAT = 'DD.MM.YYYY';

export const LAST_PAYOUT_RANGE_DATE_FORMAT = 'DD.MM.YYYY';

export const ES_LOCALE = 'es';
export const formatDate = (
  date: any,
  format = DEFAULT_DATE_FORMAT,
  languageCode = ES_LOCALE
): string => {
  const locale = getCurrentLanguageLocale(languageCode);

  return moment(date).locale(locale).format(format);
};

export const toLocalString = (date: any, languageCode = ES_LOCALE): string => {
  const locale = getCurrentLanguageLocale(languageCode);

  return DateTime.fromMillis(date)
    .setLocale(locale)
    .toLocaleString(DateTime.DATE_FULL);
};

const DEFAULT_LOCALE_DATE_STRING_OPTIONS: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: 'numeric',
};

export const toLocaleDateString = (
  date: any,
  languageCode: string,
  options = DEFAULT_LOCALE_DATE_STRING_OPTIONS
) => {
  const locale = getCurrentLanguageLocale(languageCode);

  return new Date(date).toLocaleDateString(locale, options);
};
