import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ImgSplideOne from '../../assets/pngs/Rectangle 1279.png';
import ImgSplideTwo from '../../assets/pngs/Rectangle 1278.png';
import ImgSplideThree from '../../assets/pngs/Rectangle 1280.png';
import PrevArrow from '../../assets/svgs/Arrow Left.svg';
import NextArrow from '../../assets/svgs/Arrow right.svg';
import './index.css';
import { useIsDesktop } from '../../hooks/useIsDesktop';

const options = {
  type: 'loop',
  pagination: false,
  perPage: 3,
  gap: '32px',
  arrows: false,
  breakpoints: {
    1250: {
      arrows: false,
      gap: '32px',
    },
    400: {
      arrows: false,
      gap: '32px',
    },
  },
};

const mobileOptions = {
  type: 'loop',
  pagination: false,
  perPage: 1,
  gap: '32px',
  arrows: false,
  breakpoints: {
    1250: {
      arrows: false,
      gap: '32px',
    },
    400: {
      arrows: false,
      gap: '32px',
    },
  },
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 'auto',
    backgroundColor: '#1D1D1D',
  },
  element: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderRadius: '22px',
    borderColor: '#ffffff',
  },
  image: {
    width: '100%',
    height: '250px',
    borderRadius: '16px',
    objectFit: 'cover',
    marginBottom: '14px',
  },
  buttonsContainer: {
    display: 'flex',
    paddingTop: 32,
    gap: 24,
    justifyContent: 'center',
    marginBottom: 96,
  },
}));

const BenefitsSlider = () => {
  const classes = useStyles();
  const isDesktop = useIsDesktop();
  const { t } = useTranslation('landing');

  return (
    <div className={classes.container}>
      <Typography
        sx={{
          fontSize: isDesktop ? '52px !important' : '2rem',
          fontWeight: 800,
          marginBottom: isDesktop ? '40px' : '16px',
          paddingRight: isDesktop ? '345px' : '16px',
          paddingLeft: isDesktop ? '345px' : '16px',
          paddingTop: isDesktop ? '64px' : '24px',
        }}
        align="center"
        color="primary.contrastText"
        variant="h2"
      >
        <Trans i18nKey="Benefits Title" t={t} />
      </Typography>
      <Typography
        sx={{
          fontSize: '18px !important',
          fontWeight: 400,
          marginBottom: isDesktop ? '64px' : '16px',
          paddingRight: isDesktop ? 'auto' : '16px',
          paddingLeft: isDesktop ? 'auto' : '16px',
          display: 'flex',
          justifyContent: 'center',
        }}
        align="left"
        color="primary.contrastText"
        variant="h2"
      >
        <Trans i18nKey="Benefits Description" t={t} />
      </Typography>
      <Splide
        className="section-blog-slider"
        hasTrack={false}
        options={isDesktop ? options : mobileOptions}
      >
        <SplideTrack
          className="w-dyn-list"
          style={{ width: '100%', maxWidth: 'none' }}
        >
          <SplideSlide className="w-dyn-item">
            <div className={classes.element}>
              <div>
                <img
                  src={ImgSplideOne}
                  loading="lazy"
                  alt="Pre-opening party Copy 3"
                  className={classes.image}
                />
                <Typography
                  sx={{
                    fontSize: '28px !important',
                    fontWeight: 800,
                    marginBottom: '8px',
                  }}
                  color="primary.contrastText"
                  variant="h2"
                >
                  <Trans i18nKey="Event1 Text1" t={t} />
                </Typography>
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 400,
                    marginBottom: '8px',
                  }}
                  color="primary.contrastText"
                  variant="h3"
                >
                  <Trans i18nKey="Event1 Text2" t={t} />
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px !important',
                    fontWeight: 300,
                    marginBottom: '8px',
                  }}
                  color="primary.contrastText"
                  variant="h2"
                >
                  <Trans i18nKey="Event1 Text3" t={t} />
                </Typography>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide className="w-dyn-item">
            <div className={classes.element}>
              <div>
                <img
                  src={ImgSplideTwo}
                  loading="lazy"
                  alt="Pre-opening party Copy 3"
                  className={classes.image}
                />
                <Typography
                  sx={{
                    fontSize: '28px !important',
                    fontWeight: 800,
                    marginBottom: '8px',
                  }}
                  color="primary.contrastText"
                  variant="h2"
                >
                  <Trans i18nKey="Event2 Text1" t={t} />
                </Typography>
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 400,
                    marginBottom: '8px',
                  }}
                  color="primary.contrastText"
                  variant="h3"
                >
                  <Trans i18nKey="Event2 Text2" t={t} />
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px !important',
                    fontWeight: 300,
                    marginBottom: '8px',
                  }}
                  color="primary.contrastText"
                  variant="h2"
                >
                  <Trans i18nKey="Event2 Text3" t={t} />
                </Typography>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide className="w-dyn-item">
            <div className={classes.element}>
              <div>
                <img
                  src={ImgSplideThree}
                  loading="lazy"
                  alt="Pre-opening party Copy 3"
                  className={classes.image}
                />
                <Typography
                  sx={{
                    fontSize: '28px !important',
                    fontWeight: 800,
                    marginBottom: '8px',
                  }}
                  color="primary.contrastText"
                  variant="h2"
                >
                  <Trans i18nKey="Event2 Text1" t={t} />
                </Typography>
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 400,
                    marginBottom: '8px',
                  }}
                  color="primary.contrastText"
                  variant="h3"
                >
                  <Trans i18nKey="Event2 Text2" t={t} />
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px !important',
                    fontWeight: 300,
                    marginBottom: '8px',
                  }}
                  color="primary.contrastText"
                  variant="h2"
                >
                  <Trans i18nKey="Event2 Text3" t={t} />
                </Typography>
              </div>
            </div>
          </SplideSlide>
        </SplideTrack>
        {isDesktop && (
          <div className={classes.buttonsContainer}>
            <button>
              <img src={PrevArrow} alt="Prev" />
            </button>
            <button>
              <img src={NextArrow} alt="Next" />
            </button>
          </div>
        )}
      </Splide>
    </div>
  );
};

export default BenefitsSlider;
