import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Trans, useTranslation } from 'react-i18next';
import InstagramImage from '../../assets/svgs/Frame 222.svg';
import TikTokImage from '../../assets/svgs/Frame 223.svg';
import LogoBlack from '../../assets/svgs/logo-white.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'left',
    backgroundColor: '#1D1D1D',

    padding: '32px 32px',
    '@media (min-width:1007px)': {
      padding: '32px 64px',
      flexDirection: 'row',
    },
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const BottomLayout = () => {
  const classes = useStyles();
  const { t } = useTranslation('landing');

  return (
    <div className={classes.container}>
      <div className={classes.imgContainer} style={{ maxWidth: '147px' }}>
        <img src={LogoBlack} loading="lazy" alt="Doss logo" />
      </div>
      <div className={classes.imgContainer}>
        <Link
          href="#"
          underline="none"
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            paddingBottom: '16px',
          }}
          align="left"
          variant="h3"
          color="primary.contrastText"
        >
          Doss
        </Link>
        <Link
          href="#"
          underline="none"
          sx={{
            fontSize: '16px !important',
            fontWeight: 400,
            paddingBottom: '16px',
          }}
          align="left"
          variant="h3"
          color="primary.contrastText"
        >
          <Trans i18nKey="About Us" t={t} />
        </Link>
        <Link
          href="#"
          underline="none"
          sx={{
            fontSize: '16px !important',
            fontWeight: 400,
            paddingBottom: '16px',
          }}
          align="left"
          variant="h3"
          color="primary.contrastText"
        >
          <Trans i18nKey="Frequent Questions" t={t} />
        </Link>
        <Link
          href="#"
          underline="none"
          sx={{
            fontSize: '16px !important',
            fontWeight: 400,
            paddingBottom: '16px',
          }}
          align="left"
          variant="h3"
          color="primary.contrastText"
        >
          <Trans i18nKey="Collaborate" t={t} />
        </Link>
        <Link
          href="#"
          underline="none"
          sx={{
            fontSize: '16px !important',
            fontWeight: 400,
            paddingBottom: '16px',
          }}
          align="left"
          variant="h3"
          color="primary.contrastText"
        >
          <Trans i18nKey="Contacts" t={t} />
        </Link>
      </div>
      <div className={classes.imgContainer}>
        <Link
          href="#"
          underline="none"
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            paddingBottom: '16px',
          }}
          align="left"
          variant="h3"
          color="primary.contrastText"
        >
          <Trans i18nKey="Club" t={t} />
        </Link>
        <Link
          href="#"
          underline="none"
          sx={{
            fontSize: '16px !important',
            fontWeight: 400,
            paddingBottom: '16px',
          }}
          align="left"
          variant="h3"
          color="primary.contrastText"
        >
          <Trans i18nKey="Join" t={t} />
        </Link>
        <Link
          href="/login"
          underline="none"
          sx={{
            fontSize: '16px !important',
            fontWeight: 400,
            paddingBottom: '16px',
          }}
          align="left"
          variant="h3"
          color="primary.contrastText"
        >
          <Trans i18nKey="Login" t={t} />
        </Link>
        <Link
          href="#"
          underline="none"
          sx={{
            fontSize: '16px !important',
            fontWeight: 400,
            paddingBottom: '16px',
          }}
          align="left"
          variant="h3"
          color="primary.contrastText"
        >
          <Trans i18nKey="Invite Friend" t={t} />
        </Link>
      </div>
      <div className={classes.imgContainer}>
        <Link
          href="#"
          underline="none"
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            paddingBottom: '16px',
          }}
          align="left"
          variant="h3"
          color="primary.contrastText"
        >
          <Trans i18nKey="Social Networks" t={t} />
        </Link>
        <div>
          <Link
            href="#"
            underline="none"
            sx={{
              fontSize: '20px',
              fontWeight: 700,
              paddingBottom: '16px',
            }}
            align="left"
            variant="h2"
            color="primary.contrastText"
          >
            <img src={InstagramImage} alt="Instagram link" />
          </Link>
          <Link
            href="#"
            underline="none"
            sx={{
              fontSize: '20px',
              fontWeight: 700,
              paddingBottom: '16px',
            }}
            align="left"
            variant="h2"
            color="primary.contrastText"
          >
            <img src={TikTokImage} alt="TikTok link" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BottomLayout;
