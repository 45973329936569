import { Avatar, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { CheckIcon, RenewIcon } from '../../assets/svgs';
import friendsService from '../../services/friends.service';
import { useTranslation } from 'react-i18next';
import { toLocaleDateString } from '../../utils/format-date';

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
`;

const FriendPending = ({
  email,
  id,
  createdAt,
}: {
  email: string;
  id: number;
  createdAt: string;
}) => {
  const [resendStatus, setResendStatus] = React.useState<boolean>(false);
  const { t, i18n } = useTranslation('friends');

  return (
    <RowContainer>
      <Avatar
        alt="Avatar"
        sx={{ width: '40px !important', height: '40px !important' }}
      />
      <div
        style={{
          flex: 1,
          width: '60%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Typography
          variant="h3"
          color="secondary.contrastText"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
            fontSize: '16px',
            fontWeight: 700,
          }}
        >
          {email}
        </Typography>
        <Typography
          variant="h5"
          color="neutral.dark"
          sx={{
            fontSize: '12px',
            fontWeight: 700,
          }}
        >
          {t('sentAt', {
            date: toLocaleDateString(createdAt, i18n.language),
          })}
        </Typography>
      </div>
      {!resendStatus ? (
        <Typography
          variant="h3"
          color="primary.main"
          sx={{
            fontSize: '12px',
            fontWeight: 600,
            display: 'flex',
            flexDirection: 'row',
            gap: '6px',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            friendsService.resendEmail(id);
            setResendStatus(true);
          }}
        >
          <RenewIcon />
          {t('resendInvitation')}
        </Typography>
      ) : (
        <Typography
          variant="h3"
          sx={{
            fontSize: '12px',
            color: '#20D73E',
            fontWeight: 600,
            display: 'flex',
            flexDirection: 'row',
            gap: '6px',
            alignItems: 'center',
          }}
        >
          <CheckIcon />
          {t('inviteSent')}
        </Typography>
      )}
    </RowContainer>
  );
};

export default FriendPending;
