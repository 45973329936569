import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  AsYouType,
  getCountryCallingCode,
  parseIncompletePhoneNumber,
} from 'libphonenumber-js';
import React, { useCallback, useRef, useState } from 'react';

import CountryCodeMenuItem from './CountryCodeMenuItem';

const useLabelStyles = makeStyles({
  root: {
    transform: 'translate(60%, 24px)',
    '&.hasValue': {
      transform: 'translate(0, 2px) scale(0.75)',
    },
  },
  focused: {
    transform: 'translate(0, 2px) scale(0.75)',
  },
});

const useMenuItemStyles = makeStyles({
  menuItem: {
    '& .flag::after': {
      content: 'attr(data-country-code)',
    },
  },
  selected: {
    '& .flag::after': {
      content: "''",
    },
  },
});

const asYouType = new AsYouType('ES');

const MuiPhone: React.FC<any> = ({
  value,
  label,
  name,
  selectedCountryOverride = 'ES', // init selectedCountry state
  countryOptions,
  onChange,
  onBlur,
  error,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(
    selectedCountryOverride
  );
  const [cursor, setCursor] = useState(null);
  const phoneRef = useRef();
  const labelClasses = useLabelStyles();
  const menuItemClasses = useMenuItemStyles();

  const inputPhone = useCallback((phoneNumber: any) => {
    asYouType.reset();
    return asYouType.input(phoneNumber);
  }, []);

  let val = inputPhone(value);

  if (asYouType?.getNumber?.()?.countryCallingCode === '1') {
    val = inputPhone(asYouType.getNationalNumber());
  }

  const selectionStart = (phoneRef?.current as any)?.selectionStart;
  const selectionEnd = (phoneRef?.current as any)?.selectionStart;

  const getParsedPhoneNumber = useCallback(() => {
    let parsed;

    if (asYouType && asYouType.getNumber) {
      parsed = asYouType.getNumber();
    }
    return parsed;
  }, []);

  const getNewPhone = useCallback(
    (phone: any, newCountryCode: any) => {
      let newPhone = phone;
      const parsedPhone = getParsedPhoneNumber();

      if ((!parsedPhone || newPhone.trim() === '') && newCountryCode !== '1') {
        return `+${newCountryCode}`;
      } else if (!parsedPhone) {
        return newPhone;
      }

      newPhone =
        newCountryCode === '1'
          ? parsedPhone.nationalNumber
          : `+${newCountryCode}${parsedPhone.nationalNumber}`;

      return newPhone;
    },
    [getParsedPhoneNumber, value]
  );

  const countryCodeChanged = useCallback(
    (ev: any) => {
      const newCountry = ev.target.value;
      const code = getCountryCallingCode(newCountry);
      const newPhone = getNewPhone(value, code);

      if (newPhone !== value) {
        onChange(name, newPhone);
      }
      setSelectedCountry(newCountry);
    },
    [value, onChange, getNewPhone]
  );

  const phoneNumberChanged = useCallback(
    (ev: any) => {
      setCursor(ev.target.selectionStart);
      const newPhone = parseIncompletePhoneNumber(ev.target.value);
      inputPhone(newPhone);
      const parsedPhone = getParsedPhoneNumber();
      onChange(name, parseIncompletePhoneNumber(ev.target.value));

      if (parsedPhone) {
        let newCountryCode = parsedPhone.country;
        if (parsedPhone.countryCallingCode === '44') {
          newCountryCode = 'GB';
        } else if (parsedPhone.countryCallingCode === '1') {
          newCountryCode = 'US';
        }
        setSelectedCountry(newCountryCode);
      }
    },
    [onChange, inputPhone, getParsedPhoneNumber, value]
  );

  const menuItems = Object.keys(countryOptions).map((k) => {
    const countryData = {
      ...countryOptions[k],
      countryAbrv: k,
    };

    return (
      <MenuItem value={k} key={k} className={menuItemClasses.menuItem}>
        <CountryCodeMenuItem {...countryData} />
      </MenuItem>
    );
  });

  return (
    <FormControl fullWidth>
      <InputLabel
        sx={{ marginLeft: '70px' }}
        htmlFor={name}
        shrink={false}
        classes={labelClasses}
        className={value.trim() !== '' ? 'hasValue' : ''}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        name={name}
        fullWidth
        value={val}
        onChange={phoneNumberChanged}
        type="tel"
        onBlur={onBlur}
        error={!!error}
        startAdornment={
          <InputAdornment position="start">
            <Select
              fullWidth
              disableUnderline={true}
              value={selectedCountry}
              onChange={countryCodeChanged}
            >
              {menuItems}
            </Select>
          </InputAdornment>
        }
        inputProps={{
          ref: phoneRef,
        }}
      />
    </FormControl>
  );
};

export default MuiPhone;
