import { Box, Typography, useTheme } from '@mui/material';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { useLocation, useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { TStatus } from '../../../@types/offer';
import {
  BackIcon,
  CallIcon,
  MapIcon,
  WhiteCallIcon,
  WhiteMapIcon,
} from '../../../assets/svgs';
import PrevArrow from '../../../assets/svgs/Arrow Left.svg';
import NextArrow from '../../../assets/svgs/Arrow right.svg';
import OfferButton from '../../../components/OfferButton/OfferButton';
import {
  DesktopOfferLoading,
  MobileOfferLoading,
} from '../../../components/Offers/OfferLoading';
import { EOfferType } from '../../../enums/offer-type.enum';
import { useIsDesktop } from '../../../hooks/useIsDesktop';
import offersService from '../../../services/offers.service';
import '../index.css';
import { setOfferTypeAndValue } from '../utils';
import {
  Container,
  DaysTypography,
  DesktopContainer,
  OfferValida,
  ProviderContainer,
  RecommendationContainer,
  RecommendationName,
  RecommendationsContainer,
  TitleContainer,
  ValidationContainer,
} from './components';
import { useTranslation } from 'react-i18next';
import { openLocationOnMap } from '../../../utils/open-location-on-map';
import { openPhone } from '../../../utils/open-phone';
import { formatDays } from './utils';

const optionsDesktop = {
  type: 'loop',
  pagination: false,
  perPage: 4,
  gap: '22px',
};

const optionsMobile = {
  type: 'loop',
  pagination: false,
  perPage: 3,
  gap: '22px',
};

interface IValidDate {
  days: number[];
  startTime: string;
  endTime: string;
}

interface IOfferState {
  shortDescription: string;
  id: string;
  title: string;
  restaurant: any;
  description: string;
  recommendations: any;
  discount: string;
  userOffer: any;
  endDate: string;
  validTime: IValidDate;
  offerType?: EOfferType;
  minTicketAmount: number;
}

const Offer = () => {
  const { t } = useTranslation('offers');
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [fullDescription, setFullDescription] = useState<boolean>(false);
  const [status, setStatus] = useState<TStatus>('Activated');
  const isDesktop = useIsDesktop();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [offer, setOffer] = useState<IOfferState>();

  const onMapIconClick = useMemo(() => {
    return () =>
      openLocationOnMap(
        offer?.restaurant?.geo?.coordinates[0],
        offer?.restaurant?.geo?.coordinates[1]
      );
  }, [offer]);
  const onPhoneIconClick = useMemo(() => {
    return () => openPhone(offer?.restaurant?.phoneNumber);
  }, [offer]);

  const getData = () => {
    offersService.getOffer(pathname.split('/')[2]).then((res) => {
      if (res.data) {
        const shortDescriptionArr = res.data?.description.split(' ', 10) || '';

        let shortDescription = shortDescriptionArr.join(' ');
        if (shortDescriptionArr.length === 10) {
          shortDescription += '...';
        }
        setIsLoading(false);
        setOffer({ ...res.data, shortDescription });

        if (res.data?.userOffer) {
          setStatus('Activated');
        } else {
          setStatus('Proposed');
        }
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const offerValidTime = useMemo(() => {
    return (
      offer?.validTime || {
        days: [0, 1, 2, 3, 4, 5, 6],
        startTime: '00:00',
        endTime: '24:00',
      }
    );
  }, [offer]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{offer?.title}</title>
        <meta name="description" content={offer?.description} />
        <link
          rel="canonical"
          href={'https://app.doss.es/ofertas/' + offer?.id}
        />
      </Helmet>

      {isDesktop ? (
        isLoading ? (
          <DesktopOfferLoading />
        ) : (
          <DesktopContainer>
            <div style={{ display: 'flex', width: '100%', gap: '32px' }}>
              <Box flex={0.5}>
                <Carousel
                  showArrows
                  statusFormatter={(curr, total) => `${curr} / ${total}`}
                  showThumbs={false}
                  infiniteLoop
                >
                  {offer?.restaurant?.images?.map(
                    (image: string | undefined, id: number) => (
                      <img
                        style={{
                          borderRadius: '25px',
                          maxHeight: '340px',
                          objectFit: 'cover',
                        }}
                        src={image}
                        key={'carousel-img-' + id}
                      />
                    )
                  )}
                </Carousel>
              </Box>
              <div
                style={{
                  flex: 0.5,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <Typography variant="h1">{offer?.title}</Typography>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  <WhiteMapIcon onClick={onMapIconClick} />
                  <Typography variant="h5" color="neutral.main">
                    {offer?.restaurant?.address || 'Calle Velazquez, 96.'}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  <WhiteCallIcon onClick={onPhoneIconClick} />
                  <Typography variant="h5" color="neutral.main">
                    {offer?.restaurant?.phoneNumber}
                  </Typography>
                </div>
                {!fullDescription ? (
                  <>
                    <Typography
                      sx={{ fontSize: '14px', fontWeight: 400 }}
                      variant="h5"
                    >
                      {offer?.shortDescription}
                    </Typography>
                    <Typography
                      color="primary.main"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 400,
                        cursor: 'pointer',
                      }}
                      variant="h5"
                      onClick={() => setFullDescription(true)}
                    >
                      {t('viewMore')}
                    </Typography>
                  </>
                ) : (
                  <Typography fontSize="14px" fontWeight={400} variant="h5">
                    {offer?.description}
                  </Typography>
                )}
                <ValidationContainer>
                  <OfferValida>
                    <Typography fontSize="14px" fontWeight={400} variant="h5">
                      {t('validDue')}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        padding: '4.5px 12px',
                        borderRadius: '40px',
                        fontWeight: 400,
                        backgroundColor: '#FF9D2A',
                      }}
                      color="primary.contrastText"
                      variant="h5"
                    >
                      {formatDays(offerValidTime.days).join('-')}
                    </Typography>
                  </OfferValida>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      marginBottom: '32px',
                    }}
                    color="neutral.dark"
                    variant="h5"
                  >
                    {t('validation.details', {
                      startTime: offerValidTime.startTime,
                      endTime: offerValidTime.endTime,
                      discount: offer?.minTicketAmount || 0,
                    })}
                  </Typography>
                  <OfferButton
                    endDate={offer?.endDate || ''}
                    status={status}
                    restId={offer?.restaurant?.id}
                    price={setOfferTypeAndValue(
                      offer?.offerType,
                      offer?.discount
                    )}
                    id={offer?.id}
                  />
                </ValidationContainer>
              </div>
            </div>
            <Typography sx={{ fontWeight: 800 }} variant="h2">
              {t('recommendedDishes')}
            </Typography>
            <RecommendationsContainer>
              <Splide
                className="section-blog-slider splide_width"
                hasTrack={false}
                options={optionsDesktop}
              >
                <SplideTrack className="w-dyn-list">
                  {offer?.restaurant?.recommendations?.map(
                    (
                      rec: { imageUrl: string | undefined; name: string },
                      id: number
                    ) => (
                      <SplideSlide
                        className="w-dyn-item"
                        key={'offer-image-' + id}
                      >
                        <RecommendationContainer theme={theme}>
                          <img src={rec?.imageUrl} width={96} />
                          <RecommendationName variant="h5">
                            {rec?.name}
                          </RecommendationName>
                        </RecommendationContainer>
                      </SplideSlide>
                    )
                  )}
                </SplideTrack>
                <div className="splide__arrows">
                  <button className="splide__arrow splide__arrow--prev">
                    <img src={PrevArrow} alt="Prev" />
                  </button>
                  <button className="splide__arrow splide__arrow--next">
                    <img src={NextArrow} alt="Next" />
                  </button>
                </div>
              </Splide>
            </RecommendationsContainer>
          </DesktopContainer>
        )
      ) : isLoading ? (
        <MobileOfferLoading />
      ) : (
        <Container
          style={{
            backgroundColor: theme.palette.primary.contrastText,
          }}
        >
          <BackIcon
            style={{ position: 'absolute', zIndex: 2, margin: '32px 16px' }}
            onClick={() => navigate('/ofertas')}
          />
          <Carousel
            statusFormatter={(curr, total) => `${curr} / ${total}`}
            showArrows={false}
            showThumbs={false}
            showIndicators={false}
            infiniteLoop
            swipeable
            emulateTouch
          >
            {offer?.restaurant?.images?.map(
              (image: string | undefined, id: number) => (
                <img
                  src={image}
                  key={'image-' + id}
                  style={{ height: '270px', objectFit: 'cover' }}
                />
              )
            )}
          </Carousel>
          <ProviderContainer theme={theme}>
            <TitleContainer theme={theme}>
              <Typography
                fontSize="24px"
                fontWeight={800}
                sx={{ flex: 1 }}
                variant="h2"
              >
                {offer?.title}
              </Typography>
              <CallIcon onClick={onPhoneIconClick} />
              <MapIcon onClick={onMapIconClick} />
            </TitleContainer>
            {!fullDescription ? (
              <>
                <Typography fontWeight={400} fontSize="14px" variant="h5">
                  {offer?.shortDescription}
                </Typography>
                <Typography
                  color="primary.main"
                  fontWeight={400}
                  fontSize="14px"
                  sx={{
                    cursor: 'pointer',
                  }}
                  variant="h5"
                  onClick={() => setFullDescription(true)}
                >
                  {t('viewMore')}
                </Typography>
              </>
            ) : (
              <Typography fontSize="14px" fontWeight={400} variant="h5">
                {offer?.description}
              </Typography>
            )}
            <Typography variant="h2" fontWeight={800}>
              {t('recommendedDishes')}
            </Typography>
            <RecommendationsContainer>
              <Splide
                className="section-blog-slider splide_width"
                hasTrack={false}
                options={optionsMobile}
              >
                <SplideTrack className="w-dyn-list">
                  {offer?.restaurant?.recommendations?.map(
                    (rec: any, id: number) => (
                      <SplideSlide className="w-dyn-item" key={'slide-' + id}>
                        <RecommendationContainer theme={theme}>
                          <img src={rec?.imageUrl} width={40} />
                          <RecommendationName variant="h3">
                            {rec.name}
                          </RecommendationName>
                        </RecommendationContainer>
                      </SplideSlide>
                    )
                  )}
                </SplideTrack>
                <div className="splide__arrows splide__arrows-mob">
                  <button className="splide__arrow splide__arrow--prev">
                    <img src={PrevArrow} alt="Prev" />
                  </button>
                  <button className="splide__arrow splide__arrow--next">
                    <img src={NextArrow} alt="Next" />
                  </button>
                </div>
              </Splide>
            </RecommendationsContainer>
            <ValidationContainer>
              <OfferValida>
                <Typography fontSize="14px" fontWeight={400} variant="h3">
                  {t('validDue')}
                </Typography>
                <DaysTypography color="primary.contrastText" variant="h3">
                  {formatDays(offerValidTime.days).join('-')}
                </DaysTypography>
              </OfferValida>
              <Typography
                fontSize="14px"
                fontWeight={400}
                color="neutral.dark"
                variant="h3"
              >
                {t('validation.details', {
                  startTime: offerValidTime.startTime,
                  endTime: offerValidTime.endTime,
                  discount: offer?.minTicketAmount || 0,
                })}
              </Typography>
            </ValidationContainer>
            <OfferButton
              endDate={offer?.endDate || ''}
              status={status}
              price={setOfferTypeAndValue(offer?.offerType, offer?.discount)}
              id={offer?.id}
              restId={offer?.restaurant?.id}
            />
          </ProviderContainer>
        </Container>
      )}
    </>
  );
};

export default Offer;
