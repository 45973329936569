import { toast } from 'react-toastify';

export const processError = (
  error: any,
  errorMapping: Record<string, string>
) => {
  let errorMessage = String(error);

  if (error?.code && errorMapping[error.code]) {
    errorMessage = errorMapping[error.code];
  }

  toast.error(errorMessage);
};
