import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GeneralFuctionType } from '../../@types/props.types';
import useAuthContext from '../../hooks/useAuthContext';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import PlansService from '../../services/plans.service';
import StripeService from '../../services/stripe.service';
import { setStripeData } from '../../store/slices/api.slice';
import { useDispatch } from '../../store/store';
import { getCurrentLanguageCode } from '../../utils/localizations';
import BlockHeader from '../BlockHeader';
import LoadingScreen from '../LoadingScreen';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { StartSubscriptionAt } from '../../enums/start-subscription-at.enum';

const SignupThree = ({
  handleActiveSectionChange,
}: {
  handleActiveSectionChange: GeneralFuctionType;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation('login');
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();
  const { setStripeInfo, stripeCustomerInfo } = useAuthContext();
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [selectedStart, setSelectedStart] = useState(StartSubscriptionAt.Today);
  const [loading, setLoading] = useState(true);

  const setPlansTranslations = (plans: any[]) => {
    const locale = getCurrentLanguageCode(i18n.language);

    const translationMapping = plans.reduce((res, currentItem) => {
      const id = currentItem.id;

      if (currentItem?.metadata?.locales) {
        res[id] = currentItem.metadata.locales[locale];
      }

      return res;
    }, {});

    i18n.addResource(locale, 'login', 'plans', translationMapping);
  };

  useEffect(() => {
    setLoading(true);
    PlansService.getPlans()
      .then((resp) => {
        setStripeInfo({ ...stripeCustomerInfo, plans: resp.data });
        setSelectedPlan(
          resp.data.find((plan) => plan?.metadata?.trialPeriodDays)
        );
        setPlansTranslations(resp.data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => setLoading(false));
  }, []);
  const onApply = async () => {
    setLoading(true);
    try {
      const resp = await StripeService.setupIntents();
      dispatch(setStripeData(resp.data));
      setStripeInfo({
        ...resp.data,
        selectedPlan: selectedPlan,
        planStart: selectedStart,
      });
      handleActiveSectionChange(4);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          {isDesktop ? (
            <BlockHeader title={t('page', { page: 3 })} />
          ) : (
            <Divider sx={{ color: theme.palette.primary.main, width: '100%' }}>
              {t('page', { page: 3 })}
            </Divider>
          )}
          <Box
            sx={{
              textAlign: 'center',
              marginBottom: '24px',
              marginTop: '24px',
            }}
          >
            <Typography variant={'h1'}>{t('signup.stepThreeTitle')}</Typography>
            <Typography variant={'h3'}>
              {t('signup.stepThreeDescription')}
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            {stripeCustomerInfo?.plans?.map((plan: any) => (
              <Box
                key={`plan-${plan?.id}`}
                className={
                  selectedPlan?.id === plan?.id
                    ? classes.cardActive
                    : classes.cardInactive
                }
                onClick={() => {
                  setSelectedPlan(plan);
                }}
              >
                <Box display="flex">
                  <Typography
                    variant="h5"
                    className={classes.planTitle}
                    color={
                      selectedPlan?.id === plan?.id
                        ? 'white'
                        : theme.palette.neutral.dark
                    }
                  >
                    {t(`plans.${plan?.id}.title`)}
                  </Typography>
                  {plan?.discount && (
                    <Box className={classes.discountLabel}>
                      <Typography variant="h5" sx={{ fontWeight: 700 }}>
                        -{Math.round(plan?.discount)}%
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box display="flex">
                  <Typography
                    variant="h2"
                    sx={{
                      marginRight: '8px',
                    }}
                  >
                    {plan?.metadata?.trialPeriodDays ? (
                      <>
                        <s>{plan?.price}€</s>
                        {` 0.00€`}
                      </>
                    ) : (
                      `${plan?.price}€`
                    )}
                  </Typography>
                  <Typography
                    variant="caption"
                    color={
                      selectedPlan?.id === plan?.id
                        ? 'white'
                        : theme.palette.neutral.dark
                    }
                  >
                    ({t(`plans.${plan?.id}.caption`)})
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Box width="100%" marginBottom="0.5rem">
            <Typography
              variant="h3"
              sx={{ marginBottom: '28px', visibility: 'hidden' }}
            >
              {t('signup.startSubscriptionAtTitle')}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              sx={{ marginLeft: '5px', display: 'none' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    onClick={() => setSelectedStart(StartSubscriptionAt.Today)}
                    checked={selectedStart === StartSubscriptionAt.Today}
                  />
                }
                label={t('signup.startNow') as any}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    onClick={() =>
                      setSelectedStart(StartSubscriptionAt.NextMonth)
                    }
                    checked={selectedStart === StartSubscriptionAt.NextMonth}
                  />
                }
                label={t('signup.startAtTheBeginningOfTheNextMonth') as any}
              />
            </Box>
            <Typography
              variant="body2"
              className={classes.bottomDescription}
              textAlign="start"
              width="100%"
            >
              {t('signup.startNowDescription')}
            </Typography>
          </Box>
          <Button
            fullWidth
            data-wait={t('signup.pleaseWaitButtonText')}
            onClick={onApply}
          >
            {t('signup.submitButtonText')}
          </Button>
        </>
      )}
    </>
  );
};

export default SignupThree;
