// import IUser from "../types/user.type";
import { AxiosRequestConfig } from 'axios';
import Http from './http.common';

class EventsService {
  apiUrl = '/events';

  getEventsList(locationId?: number | null) {
    const options: AxiosRequestConfig = { params: { page: 1, limit: 100 } };

    if (typeof locationId === 'number') {
      options.headers = { 'X-Location-Id': locationId };
    }

    return Http.get(this.apiUrl + '/', options);
  }

  getEvent(id: string) {
    return Http.get(this.apiUrl + `/${id}`);
  }

  submitForEvent(id: string | number) {
    return Http.post(this.apiUrl + `/${id}/participate`, { id: Number(id) });
  }

  cancelEvent(id: string | number) {
    return Http.delete(this.apiUrl + `/${id}/quit`);
  }
}

export default new EventsService();
