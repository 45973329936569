import { Box, CssBaseline, GlobalStyles, useTheme } from '@mui/material';
import React from 'react';
import { SearchContext } from '../contexts/UserContext';

const MuiStyledLayout: React.FC<any> = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = React.useState<any>({});

  return (
    <>
      <GlobalStyles
        styles={{
          a: { color: theme.palette.primary.main, textDecoration: 'underline' },
        }}
      />
      <CssBaseline />
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'background.default',
          color: 'text.primary',
        }}
      >
        <SearchContext.Provider
          value={{
            searchValue,
            updateSearchValue: (value) => setSearchValue(value),
          }}
        >
          {children}
        </SearchContext.Provider>
      </Box>
    </>
  );
};

export default MuiStyledLayout;
