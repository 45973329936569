import { useTheme } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChatsIcon, EventsIcon, FriendsIcon, HomeIcon } from '../assets/svgs';

enum ERoutesNames {
  offers = '/ofertas',
  events = '/eventos',
  chats = '/chats',
  friends = '/invitaramigos',
}

const BottomAppBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();

  return Object.values(ERoutesNames).includes(pathname as ERoutesNames) ? (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        borderTop: `1px solid ${theme.palette.customGrey.dark}`,
      }}
      elevation={3}
    >
      <BottomNavigation
        value={pathname}
        onChange={(event, newValue) => {
          navigate(newValue);
        }}
        sx={{ paddingBottom: '10px', height: '65px' }}
      >
        <BottomNavigationAction
          value={ERoutesNames.offers}
          icon={<HomeIcon />}
        />
        <BottomNavigationAction
          value={ERoutesNames.events}
          icon={<EventsIcon />}
        />
        <BottomNavigationAction
          value={ERoutesNames.chats}
          icon={<ChatsIcon />}
        />
        <BottomNavigationAction
          value={ERoutesNames.friends}
          icon={<FriendsIcon />}
        />
      </BottomNavigation>
    </Paper>
  ) : null;
};

export default BottomAppBar;
