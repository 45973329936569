import { useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UserContextType } from '../../@types/user';
import LoadingScreen from '../../components/LoadingScreen';
import SignupFour from '../../components/signup/SignupFour';
import SignupOne from '../../components/signup/SignupOne';
import SignupThree from '../../components/signup/SignupThree';
import SignupTwo from '../../components/signup/SignupTwo';
import { UserContext } from '../../contexts/UserContext';
import { MobileLogoAppBarContext } from '../../layouts/AuthLayout';
import { useTypedSelector } from '../../store/store';

const Signup = ({ option }: { option: string }) => {
  const [searchParams] = useSearchParams();
  const step = searchParams.get('step');
  const code = searchParams.get('referralCode');
  const email = searchParams.get('email');
  const [activeSection, setActiveSection] = useState(step ? Number(step) : 1);
  const { isLoading, user } = useTypedSelector((state) => state.auth);
  const { setShouldShowArrow, setFunctionOnBack } = useContext(
    MobileLogoAppBarContext
  );

  const changeActiveSection = useCallback((index: number) => {
    setActiveSection(index);
  }, []);

  const [userSignupData, setUserSignupData] = useState<UserContextType | null>(
    null
  );
  const [stripeCustomerInfo, setStripeCustomerInfo] = useState<any | null>(
    null
  );

  useEffect(() => {
    if (!setShouldShowArrow || !setFunctionOnBack) {
      return;
    }

    setShouldShowArrow(activeSection !== 1 && activeSection !== 3);

    if (activeSection !== 1 && activeSection !== 3) {
      setFunctionOnBack(() => {
        return () => changeActiveSection(activeSection - 1);
      });
    }
  }, [activeSection, setFunctionOnBack, setShouldShowArrow]);

  return (
    <>
      <UserContext.Provider
        value={{
          userSignupData,
          setUserSignupData: setUserSignupData,
          stripeCustomerInfo,
          setStripeInfo: setStripeCustomerInfo,
        }}
      >
        {activeSection === 1 && (
          <SignupOne
            code={code || ''}
            email={email || ''}
            handleActiveSectionChange={changeActiveSection}
          />
        )}
        {activeSection === 2 && (
          <SignupTwo handleActiveSectionChange={changeActiveSection} />
        )}
        {activeSection === 3 && (
          <SignupThree handleActiveSectionChange={changeActiveSection} />
        )}
        {activeSection === 4 && (
          <SignupFour handleActiveSectionChange={changeActiveSection} />
        )}
        {isLoading && <LoadingScreen />}
      </UserContext.Provider>
    </>
  );
};

export default Signup;
