import { Box, Skeleton } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import { desktopOnly, useIsDesktop } from '../../hooks/useIsDesktop';

const CustomSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${desktopOnly} {
    flex-basis: 22%;
  }
`;

const PaperContainer = styled(Box)`
  border-radius: 15px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 16px);
  margin: 8px;

  ${desktopOnly} {
    width: calc(100% - 32px);
    margin: 16px;
  }
`;

export const EventSkeleton = () => {
  const isDesktop = useIsDesktop();

  return (
    <CustomSkeletonContainer>
      <Skeleton
        height={335}
        width="100%"
        sx={{ marginTop: '-75px', marginBottom: '-30px', borderRadius: '15px' }}
        component="div"
      >
        <Skeleton
          animation="wave"
          component="div"
          height={96}
          width={86}
          sx={{
            position: 'absolute',
            zIndex: 2,
            top: '5px',
            left: '10px',
            visibility: 'initial !important',
          }}
        />
        <PaperContainer>
          <Skeleton
            animation="wave"
            component="div"
            width="100%"
            height={isDesktop ? 230 : 195}
            sx={{
              display: 'flex',
              alignItems: 'center',
              zIndex: 2,
              visibility: 'initial',
              padding: '0 10px',
              marginBottom: '-15px',
            }}
          >
            <Skeleton
              animation="wave"
              variant="circular"
              component="div"
              width="45px"
              height={100}
              sx={{
                zIndex: 2,
                visibility: 'initial',
              }}
            />
            <Box sx={{ width: '100%', marginLeft: '16px' }}>
              <Skeleton
                animation="wave"
                component="div"
                height={80}
                sx={{
                  zIndex: 2,
                  visibility: 'initial',
                }}
              />
              <Skeleton
                animation="wave"
                component="div"
                height={40}
                width="100%"
                sx={{
                  marginTop: '-10px',
                  zIndex: 2,
                  visibility: 'initial',
                }}
              />
            </Box>
          </Skeleton>
        </PaperContainer>
      </Skeleton>
    </CustomSkeletonContainer>
  );
};

const EventsLoading = () => {
  return (
    <>
      <EventSkeleton />
      <EventSkeleton />
      <EventSkeleton />
      <EventSkeleton />
      <EventSkeleton />
      <EventSkeleton />
      <EventSkeleton />
      <EventSkeleton />
      <EventSkeleton />
      <EventSkeleton />
    </>
  );
};
export default EventsLoading;
