import { DateTime } from 'luxon';
import { getCurrentLanguageLocale } from '../../utils/localizations';

export const dateStringForDate = (
  date: string = new Date().toISOString(),
  language: string
): string => {
  const dateObj = DateTime.fromISO(date).toLocal();
  const today = DateTime.now().toLocal();

  if (dateObj.startOf('day').valueOf() === today.startOf('day').valueOf()) {
    return dateObj.toLocaleString(DateTime.TIME_24_SIMPLE);
  }

  return dateObj.toRelativeCalendar({
    locale: getCurrentLanguageLocale(language),
  }) as string;
};

export const messageAdapter = (
  message: any,
  language: string,
  defaultValue: string,
  userId?: string | number
) => {
  const { id, user, text, createdAt } = message;
  return {
    id: id,
    userId: user?.id,
    position: String(user?.id) === String(userId) ? 'right' : 'left',
    type: 'text',
    title: user?.nickname,
    text: text,
    avatar: user?.avatarUrl,
    date: createdAt,
    dateString: dateStringForDate(createdAt, language),
    focus: false,
    titleColor: 'black',
    forwarded: false,
    replyButton: false,
    removeButton: false,
    status: 'read',
    notch: false,
    retracted: false,
    nicknameColor: user.color,
  };
};

export const userNickname = (id: number, nickname: string): string => {
  if (nickname) {
    return nickname;
  }

  return `User #${id}`;
};
