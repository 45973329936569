import { PaletteOptions } from '@mui/material/styles/createPalette';

const paletteLight: PaletteOptions = {
  primary: {
    main: '#FFA132',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#CDCDCD',
    contrastText: '#000000',
  },
  customRed: {
    main: '#EF0C0C',
    contrastText: '#FFFFFF',
  },
  neutral: {
    main: '#A6A6A6',
    dark: '#5B5B5B',
  },
  error: {
    main: '#FFA800',
  },
  warning: {
    main: '#FFA132',
  },
  border: {
    main: '#FFA132',
  },
  customGrey: {
    main: '#CDCDCD',
    light: '#A6A6A6',
    dark: '#F2F2F2',
    contrastText: '#000',
  },
  customChip: {
    active: '#000',
    disabled: 'transparent',
    activeTextColor: '#fff',
    disabledTextColor: '#000',
  },
  filterChip: {
    active: '#FFA132',
    disabled: 'transparent',
    activeTextColor: '#fff',
    disabledTextColor: '#A6A6A6',
    borderColor: '#A6A6A6',
  },
};

export default paletteLight;
