const audioSrc = require('../assets/audio/simple_notification.mp3');

const useNotifications = () => {
  const checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  };

  const askNotificationPermission = () => {
    if (!('Notification' in window)) {
      console.warn('This browser does not support notifications.');
    } else if (checkNotificationPromise()) {
      Notification.requestPermission();
    } else {
      Notification.requestPermission((permission) => {
        return;
      });
    }
  };

  const setNewFavicon = (favicon: string) => {
    let link: HTMLLinkElement | null =
      document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link') as HTMLLinkElement;
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = favicon;
  };

  const notify = async (
    message: string,
    withSound: boolean = false,
    audioSource: any = undefined,
    withNotification: boolean = true,
    title?: string
  ) => {
    //askNotificationPermission();
    const audio = new Audio(audioSource || audioSrc);
    if (withNotification && document.visibilityState === 'hidden') {
      // System web notification
      // maybe usefull in future
      // const notification = new Notification(title || 'DOSS Notification', {
      //   body: message,
      //   icon: BrandLogo,
      // });
      //
      // notification.onclick = (_) => {
      //   window.focus();
      //   notification.close();
      // };
      // change tab favicon
      //setNewFavicon('favicon_notification.ico');
    }

    if (withSound) {
      await audio.play();
    }
  };

  return {
    askNotificationPermission,
    notify,
    setNewFavicon,
  };
};

export default useNotifications;
