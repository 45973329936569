import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useTypedSelector } from '../store/store';
import { UserRole } from '../@types/user';

export const AuthGuard: React.FC<any> = ({
  children,
  redirectTo,
}: {
  children: JSX.Element;
  redirectTo?: string;
}) => {
  const { loggedin } = useTypedSelector((state) => state.auth);
  const { user } = useTypedSelector((state) => state.auth);
  const location = useLocation();

  if (!loggedin || !user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (user.role === UserRole.User && !user.subscription?.status) {
    return <Navigate to="/signup?step=3" state={{ from: location }} />;
  }

  if (
    user.role === UserRole.User &&
    user.subscription?.status !== 'scheduled' &&
    user.subscription?.status !== 'created' &&
    user.subscription?.status !== 'active'
  ) {
    return <Navigate to="/renew-subscription" state={{ from: location }} />;
  }

  if (!user.nickname) {
    return <Navigate to="/complete-signup" state={{ from: location }} />;
  }

  return <>{children}</>;
};
