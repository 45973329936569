import { ArrowBackIos } from '@mui/icons-material';
import { Avatar, Box, Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ChartBlock from '../../components/influencer/ChartBlock';
import { Container } from '../../components/influencer/components';
import PayoutBlock from '../../components/influencer/PayoutBlock';
import ReferralUsersBlock from '../../components/influencer/ReferralUsersBlock';
import WeekPayoutBlock from '../../components/influencer/WeekPayoutBlock';
import LoadingScreen from '../../components/LoadingScreen';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import influencerService from '../../services/influencer.service';
import { useTypedSelector } from '../../store/store';
import { nameToAvatar, nameToColor } from '../../utils/avatar';
import {
  formatDate,
  REFERRAL_DATE_FORMAT,
  toLocalString,
} from '../../utils/format-date';

const InfluencerDashboardPage = () => {
  const { user } = useTypedSelector((state) => state.auth);
  const theme = useTheme();
  const { t } = useTranslation('profile');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [statistics, setStatistics] = useState<any>(null);
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();

  const getInfluencerInfo = async () => {
    setIsLoading(true);
    try {
      const res = await influencerService.getAllData();
      setStatistics(res.data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getInfluencerInfo();
  }, []);

  return (
    <>
      {isLoading && <LoadingScreen />}
      {!isLoading && (
        <>
          <Box
            sx={{
              padding: isDesktop ? '0' : '16px',
              textAlign: 'center',
              display: 'flex',
              width: '100%',
            }}
          >
            {!isDesktop && (
              <ArrowBackIos
                sx={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/perfil')}
              />
            )}
          </Box>
          <Box
            sx={
              isDesktop
                ? {
                    padding: '96px 64px 64px 64px',
                    width: '100%',
                  }
                : {
                    padding: '16px',
                    width: '100%',
                  }
            }
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{ marginBottom: isDesktop ? '32px' : '16px' }}
            >
              <Avatar
                sx={{
                  marginRight: isDesktop ? '24px' : '16px',
                  bgcolor: nameToColor(user?.name),
                }}
                src={user?.avatarUrl}
                {...nameToAvatar(user?.name)}
              />
              <Typography variant="h2">{user?.nickname}</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={isDesktop ? 6 : 12}>
                <PayoutBlock
                  total={statistics?.balance}
                  label={t('influencer.payout')}
                />
              </Grid>
              {isDesktop && <Grid item xs={6} />}
              <Grid item xs={isDesktop ? 6 : 12}>
                <Container
                  theme={theme}
                  style={{
                    minHeight: isDesktop ? '131px' : '83px',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Typography fontWeight="bold">
                    {t('influencer.totalInvites')}
                  </Typography>
                  <Typography variant="h2" color={'#20D73E'}>
                    {statistics?.totalReferrals || 0}
                  </Typography>
                </Container>
              </Grid>
              <Grid item xs={isDesktop ? 6 : 12}>
                <Container
                  theme={theme}
                  style={{
                    minHeight: isDesktop ? '131px' : '83px',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Typography fontWeight="bold">
                    {t('influencer.payoutDate')}
                  </Typography>
                  <Typography variant="h2" color={'#20D73E'}>
                    {statistics?.nextPayout?.conditionsSatisfied
                      ? toLocalString(statistics.nextPayout.nextPayoutTimestamp)
                      : t('influencer.payoutCriteriaNotSatisfied')}
                  </Typography>
                </Container>
              </Grid>
              <Grid item xs={isDesktop ? 6 : 12}>
                <ChartBlock />
              </Grid>
              <Grid item xs={isDesktop ? 3 : 6}>
                <ReferralUsersBlock
                  users={statistics?.users}
                  label={t('influencer.referralsTitle')}
                />
              </Grid>
              <Grid item xs={isDesktop ? 3 : 6}>
                <WeekPayoutBlock
                  data={statistics?.lastPayouts}
                  label={t('influencer.weekPayout')}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default InfluencerDashboardPage;
