import { Box } from '@mui/material';
import styled from 'styled-components';
import { desktopOnly } from '../../hooks/useIsDesktop';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;

  ${desktopOnly} {
    padding: 96px;
  }
`;

export const Notification = styled.div<{ theme: any, isNewMessage: boolean }>`
    width: 100%;
    display: flex;
    align-content: center;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid ${(props) => props.theme.palette.customGrey.dark};
    margin-bottom: 16px;
    background-color: ${(props) =>
            props.isNewMessage
                    ? props.theme.palette.customGrey.main
                    : "inherit"};

    &:hover {
        background-color: ${(props) => props.theme.palette.customGrey.dark};
    }
`;

export const Icon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 12px;

  ${desktopOnly} {
    width: 64px;
    height: 64px;
    margin-right: 24px;
  }
`;

export const TrashBox = styled(Box)`
  cursor: pointer;
  margin: auto 10px auto auto;

  ${desktopOnly} {
    margin: auto 24px auto auto;
  }

  svg {
    width: 17px;
    height: 18px;

    ${desktopOnly} {
      width: 24px;
      height: 26px;
    }
  }
`;
