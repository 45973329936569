import { Avatar, Typography, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BackIcon,
  BlackCallIcon,
  BlackMapIcon,
  CalendarIcon,
  CalendarIconBlack,
  CallIcon,
  MapIcon,
  WhiteCallIcon,
  WhiteMapIcon,
} from '../../../assets/svgs';
import EventButton from '../../../components/Events/EventButton';
import {
  DesktopEventLoading,
  MobileEventLoading,
} from '../../../components/Events/EventLoading';
import UserEventStatus from '../../../enums/user-event-status.enum';
import { useIsDesktop } from '../../../hooks/useIsDesktop';
import eventsService from '../../../services/events.service';
import userService from '../../../services/user.service';
import {
  Container,
  DesktopContainer,
  ProviderContainer,
  TitleContainer,
} from './components';
import { useTranslation } from 'react-i18next';
import { DEFAULT_DATE_FORMAT, formatDate } from '../../../utils/format-date';
import { openPhone } from '../../../utils/open-phone';
import { openLocationOnMap } from '../../../utils/open-location-on-map';
import { nameToAvatar, nameToColor } from '../../../utils/avatar';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: `${(theme as Theme).palette.primary.contrastText}`,
  },
}));

interface IEventState {
  id: string;
  title: string;
  imageUrl: string;
  address: string;
  restaurant: any;
  geo: any;
  description: string;
  shortDescription: string;
  phoneNumber: string;
  recommendations: any;
  discount: number | string;
  startDate: string;
  userOffers: any;
  participantsLimit: number;
  userEvent: any;
  userEvents: any;
}

const eventDateFormat = 'DD MMM YYYY - h:mm';
const Event = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation(['events', 'common']);

  const classes = useStyles();
  const isDesktop = useIsDesktop();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fullDescription, setFullDescription] = useState<boolean>(false);

  const [updated, setUpdated] = useState<Date>(new Date());
  const [event, setEvent] = useState<IEventState>();

  const getData = () => {
    eventsService
      .getEvent(pathname.split('/')[2])
      .then((res) => {
        if (res.data) {
          const shortDescriptionArr =
            res.data?.description.split(' ', 10) || '';

          let shortDescription = shortDescriptionArr.join(' ');
          if (shortDescriptionArr.length === 10) {
            shortDescription += '...';
          }

          setEvent({ ...res.data, shortDescription });
        }

        return userService.me();
      })
      .then((res) => {
        if (res?.data) {
          // @ts-ignore
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [updated]);

  const activeUserEvents = React.useMemo(
    () =>
      event?.userEvents?.filter(
        (userEvent: any) => userEvent.status !== UserEventStatus.Canceled
      ) || [],
    [event]
  );
  const openPhoneNumber = React.useMemo(() => {
    return () => openPhone(event?.phoneNumber);
  }, [event]);
  const openLocation = React.useMemo(() => {
    return () => openLocationOnMap(event?.geo.lat, event?.geo.lon);
  }, [event]);

  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{event?.title}</title>
        <meta name="description" content={event?.description} />
        <link
          rel="canonical"
          href={'https://app.doss.es/eventos/' + event?.id}
        />
      </Helmet>
      {isDesktop ? (
        isLoading ? (
          <DesktopEventLoading />
        ) : (
          <DesktopContainer>
            <div style={{ display: 'flex', width: '100%', gap: '32px' }}>
              <div style={{ flex: 0.5 }}>
                <img
                  alt={'Event image'}
                  style={{
                    borderRadius: '25px',
                    maxHeight: '340px',
                    objectFit: 'cover',
                    width: '100%',
                  }}
                  src={event?.imageUrl}
                />
              </div>
              <div
                style={{
                  flex: 0.5,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <Typography variant="h1">{event?.title}</Typography>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  {theme.palette.mode === 'dark' ? (
                    <CalendarIconBlack />
                  ) : (
                    <CalendarIcon />
                  )}
                  <Typography variant="h5" color="neutral.main">
                    {formatDate(
                      event?.startDate,
                      DEFAULT_DATE_FORMAT,
                      i18n.language
                    )}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  {theme.palette.mode === 'dark' ? (
                    <BlackMapIcon onClick={openLocation} />
                  ) : (
                    <WhiteMapIcon onClick={openLocation} />
                  )}
                  <Typography variant="h5" color="neutral.main">
                    {event?.address || 'Calle Velazquez, 96.'}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  {theme.palette.mode === 'dark' ? (
                    <BlackCallIcon onClick={openPhoneNumber} />
                  ) : (
                    <WhiteCallIcon onClick={openPhoneNumber} />
                  )}
                  <Typography variant="h5" color="neutral.main">
                    {event?.phoneNumber}
                  </Typography>
                </div>
                <EventButton
                  setUpdated={setUpdated}
                  userEvent={event?.userEvent}
                  id={event?.id || 0}
                />
              </div>
            </div>
            {!fullDescription ? (
              <>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 400 }}
                  variant="h5"
                >
                  {event?.shortDescription}
                </Typography>
                <Typography
                  color="primary.main"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    cursor: 'pointer',
                  }}
                  variant="h5"
                  onClick={() => setFullDescription(true)}
                >
                  {t('viewMore')}
                </Typography>
              </>
            ) : (
              <Typography
                sx={{ fontSize: '14px', fontWeight: 400 }}
                variant="h5"
              >
                {event?.description}
              </Typography>
            )}
            <Typography sx={{ fontSize: '16px', fontWeight: 800 }} variant="h3">
              {t('participants', { participants: activeUserEvents.length })}
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                marginBottom: '48px',
              }}
            >
              {activeUserEvents.map((ev: { user: any }) => {
                return (
                  <Avatar
                    key={`participant-${ev?.user?.id}`}
                    sx={{
                      height: '48px !important',
                      width: '48px !important',
                      bgcolor: nameToColor(ev?.user?.name),
                    }}
                    src={ev?.user?.avatarUrl}
                    alt={ev?.user?.nickname}
                    {...nameToAvatar(ev?.user?.name)}
                  />
                );
              })}
            </div>
          </DesktopContainer>
        )
      ) : isLoading ? (
        <MobileEventLoading />
      ) : (
        <Container className={classes.container}>
          <BackIcon
            style={{
              position: 'absolute',
              zIndex: 2,
              margin: '32px 16px',
            }}
            onClick={() => navigate('/eventos')}
          />
          <img
            alt={'Event image'}
            src={event?.imageUrl}
            style={{ maxHeight: '270px', objectFit: 'cover' }}
          />
          <ProviderContainer className={classes.container}>
            <TitleContainer className={classes.container}>
              <Typography
                sx={{ fontSize: '24px', fontWeight: 800, flex: 1 }}
                variant="h2"
              >
                {event?.title}
              </Typography>
              <CallIcon onClick={openPhoneNumber} />
              <MapIcon onClick={openLocation} />
            </TitleContainer>
            <div
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              {theme.palette.mode === 'dark' ? (
                <CalendarIconBlack />
              ) : (
                <CalendarIcon />
              )}
              <Typography variant="h5" color="neutral.main">
                {formatDate(
                  event?.startDate,
                  DEFAULT_DATE_FORMAT,
                  i18n.language
                )}
              </Typography>
            </div>
            {!fullDescription ? (
              <>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 400 }}
                  variant="h5"
                >
                  {event?.shortDescription}
                </Typography>
                <Typography
                  color="primary.main"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    cursor: 'pointer',
                  }}
                  variant="h5"
                  onClick={() => setFullDescription(true)}
                >
                  {t('viewMore')}
                </Typography>
              </>
            ) : (
              <Typography
                sx={{ fontSize: '14px', fontWeight: 400 }}
                variant="h5"
              >
                {event?.description}
              </Typography>
            )}
            <Typography sx={{ fontSize: '16px', fontWeight: 800 }} variant="h3">
              {t('participants', { participants: activeUserEvents.length })}
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                marginBottom: '48px',
              }}
            >
              {activeUserEvents.map((ev: { user: any }) => {
                return (
                  <Avatar
                    sx={{
                      height: '32px',
                      width: '32px',
                      bgcolor: nameToColor(ev?.user?.name),
                    }}
                    src={ev?.user?.avatarUrl}
                    alt={ev?.user?.nickname}
                    {...nameToAvatar(ev?.user?.name)}
                  />
                );
              })}
            </div>
            <EventButton
              setUpdated={setUpdated}
              userEvent={event?.userEvent}
              id={event?.id || 0}
            />
          </ProviderContainer>
        </Container>
      )}
    </Container>
  );
};

export default Event;
