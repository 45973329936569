import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SendIcon from '@mui/icons-material/Send';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import chatsService from '../../services/chats.service';

interface ISendDMButtonProps {
  userId: number;
  onSuccess: (chatId: number) => void;
  iconOnly: boolean;
}

const SendDMButton: FC<ISendDMButtonProps> = ({
  userId,
  onSuccess,
  iconOnly,
}) => {
  const { t } = useTranslation('chats');

  const isDesktop = useIsDesktop();

  const handleDMButtonClick = async (userId: number) => {
    try {
      const res = await chatsService.getDMChat(userId);
      if (res.data) {
        const chatId = res.data.id;

        return onSuccess(chatId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Typography
      variant="h3"
      color="primary.main"
      sx={{
        fontSize: `${isDesktop ? '26px' : '18px'} !important`,
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'row',
        gap: '6px',
        alignItems: 'center',
        cursor: 'pointer',
        textAlign: 'center',
      }}
      onClick={async () => handleDMButtonClick(userId)}
    >
      <SendIcon fontSize="small" />
      {!iconOnly && t('sendDirectMessage')}
    </Typography>
  );
};

export default SendDMButton;
