import { Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import LikeImage from '../../assets/svgs/Like.svg';
import LikedImage from '../../assets/svgs/Liked.svg';
import {
  ActivatedOfferContainer,
  HorizontalContainer,
  LikeImg,
  OfferContainer,
  OfferImage,
} from '../../pages/Offers/components';
import { useTranslation } from 'react-i18next';
import { IOfferListItem } from '../../pages/Offers/OffersList';
import { setOfferTypeAndValue } from '../../pages/Offers/utils';
import { formatDate, USER_OFFER_DATE_FORMAT } from '../../utils/format-date';

interface IOfferListItemProps {
  el: IOfferListItem;
}

const OfferListItem: FC<IOfferListItemProps> = ({ el }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t, i18n } = useTranslation('offers');
  const shortDescriptionArr = el.description.split(' ', 10);

  let shortDescription = shortDescriptionArr.join(' ');
  if (shortDescriptionArr.length === 10) {
    shortDescription += '...';
  }
  return (
    <OfferContainer>
      <OfferImage
        src={el?.imageUrl}
        onClick={() => navigate(`/ofertas/${el.id}`)}
        style={{
          filter: el?.userOffer ? 'brightness(50%)' : 'brightness(100%)',
          backgroundColor: theme.palette.background.default,
        }}
        alt={el.title}
      />
      <HorizontalContainer>
        {el?.userOffer && (
          <ActivatedOfferContainer>
            <Typography
              variant="h2"
              color="customRed.contrastText"
              align="center"
            >
              {t('activatedText')}
            </Typography>
            <Typography
              variant="h3"
              color="customRed.contrastText"
              align="center"
            >
              {formatDate(
                el?.userOffer?.createdAt,
                USER_OFFER_DATE_FORMAT,
                i18n.language
              )}
            </Typography>
            <Typography
              variant="h3"
              color="primary.main"
              align="center"
              sx={{ cursor: 'pointer' }}
              onClick={() => navigate(`${el?.id}/activated`)}
            >
              {t('viewInfo')}
            </Typography>
          </ActivatedOfferContainer>
        )}
        <LikeImg src={el?.like ? LikedImage : LikeImage} />
        <Typography
          onClick={() => navigate(`/ofertas/${el.id}`)}
          variant="h2"
          sx={{ fontWeight: 800, cursor: 'pointer', wordBreak: 'break-word' }}
        >
          {el.title}
        </Typography>
        <Typography
          onClick={() => navigate(`/ofertas/${el.id}`)}
          variant="caption"
          color="customRed.contrastText"
          bgcolor="primary.main"
          sx={{
            padding: '4.5px 12px',
            borderRadius: '40px',
            cursor: 'pointer',
            minHeight: '29px',
            minWidth: '55px',
            textAlign: 'center',
          }}
        >
          {setOfferTypeAndValue(el.offerType, el.discount)}
        </Typography>
      </HorizontalContainer>
      <Typography
        onClick={() => navigate(`/ofertas/${el.id}`)}
        sx={{ cursor: 'pointer' }}
        variant="body2"
        color="neutral.dark"
      >
        {shortDescription}
      </Typography>
    </OfferContainer>
  );
};

export default OfferListItem;
