import { EDaysSpanish } from '../../../enums/days.enum';

export const formatDays = (dayNumbers: number[] | undefined): string[] => {
  if (!dayNumbers) return [''];

  return dayNumbers.map((dayNumber) => {
    if (dayNumber >= 0 && dayNumber <= 6) {
      return dayNumber === 3 ? 'X' : EDaysSpanish[dayNumber].charAt(0);
    } else {
      return '';
    }
  });
};
