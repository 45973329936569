import axios, { AxiosResponse } from 'axios';

import handleResponseError from '../utils/errorInterceptor';
import { getWithExpiry } from '../utils/localStorage';

// const token = JSON.parse(localStorage.getItem('doss-root') ? localStorage.getItem('doss-root') : `{ auth: { user: { accessToken: '' } } }`);
// const localstorage = localStorage.getItem('doss-root');
// const authData = localstorage ? JSON.parse(localstorage) : `{ auth: { user: { accessToken: '' } } }`;
// console.log(JSON.parse(authData.auth))
// const token = JSON.parse(authData.auth)?.user.accessToken ? JSON.parse(authData.auth).user.accessToken : '';
export interface CustomResponse extends AxiosResponse {
  statusCode?: number;
  timestamp?: string;
  category?: string;
  code?: string;
  details: any;
}

const axiosInstance = axios.create({
  // baseURL: "http://localhost:5000/api",
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

const getToken = () => {
  const itemStr = getWithExpiry('accessToken');
  return itemStr.value || '';
};

const handleRequestFulfilled = (config: any) => {
  const accessToken = getToken();
  config.headers['ngrok-skip-browser-warning'] = `true`;

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
};

const handleResponseFulfilled = (response: AxiosResponse) => {
  return response as CustomResponse;
};

export const setupResponseInterceptor = (store: any) => {
  axiosInstance.interceptors.request.use(handleRequestFulfilled);
  axiosInstance.interceptors.response.use(
    (response) => handleResponseFulfilled(response),
    (error) => handleResponseError(error, store)
  );
};

export default axiosInstance;
