// import IUser from "../types/user.type";
import { AxiosRequestConfig } from 'axios';
import Http from './http.common';

class ChatsService {
  apiUrl = '/chats';

  getChatsList(locationId?: number | null) {
    const options: AxiosRequestConfig = { params: { page: 1, limit: 100 } };

    if (typeof locationId === 'number') {
      options.headers = { 'X-Location-Id': locationId };
    }

    return Http.get(this.apiUrl + '/', options);
  }

  getCurrentChat(id: number) {
    return Http.get(this.apiUrl + `/${id}`);
  }

  getCurrentChatMessages(id: number, limit: number = 30, page: number = 1) {
    return Http.get(
      this.apiUrl + `/${id}/messages?page=${page}&limit=${limit}`
    );
  }

  sendMessage(id: number | null, message: string) {
    return Http.post(this.apiUrl + `/${id}/message`, { text: message });
  }

  readChatMessages(chatId: number, messageId: number) {
    return Http.post(this.apiUrl + `/${chatId}/messages/read`, { messageId });
  }

  getDMChat(userId: number) {
    return Http.get(this.apiUrl + `/dm/${userId}`);
  }
}

export default new ChatsService();
