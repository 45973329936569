import { Theme } from '@mui/material/styles';
import { Components } from '@mui/material/styles/components';

const components: Components = {
  MuiBottomNavigationAction: {
    styleOverrides: {
      root: ({ theme }) => ({
        paddingTop: 0,
        color: `${(theme as Theme).palette.neutral.main}`,
        '&.Mui-selected': {
          '& path': {
            fill: `${(theme as Theme).palette.customRed.main} !important`,
          },
          paddingTop: `0 !important`,
        },
      }),
    },
  },
  MuiToolbar: {
    defaultProps: {
      disableGutters: true,
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        marginBottom: '10px',
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: '4px',
        borderRadius: '8px',
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: ({ theme }) => ({
        height: '17px',
        minWidth: '27px',
        '& svg': {
          height: '17px',
          width: '17px',
        },
        '@media (min-width:700px)': {
          height: '30px',
          minWidth: '46px',
          '& svg': {
            height: '30px',
            width: '30px',
          },
        },
      }),
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: (theme as Theme).palette.primary.main,
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: (theme as Theme).palette.primary.main,
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              (theme as Theme).palette.mode === 'light'
                ? (theme as Theme).palette.grey[100]
                : (theme as Theme).palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: (theme as Theme).palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor:
            (theme as Theme).palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: (theme as Theme).transitions.create(
            ['background-color'],
            {
              duration: 500,
            }
          ),
        },
      }),
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        width: '64px',
        height: '64px',
        '@media (min-width:700px)': {
          width: '96px',
          height: '96px',
        },
        '&.small': {
          width: '32px',
          height: '32px',
          '@media (min-width:700px)': {
            width: '64px',
            height: '64px',
          },
        },
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        width: '17px',
        height: '17px',
        '& svg': {
          height: '17px',
          width: '17px',
        },
        '@media (min-width:700px)': {
          width: '30px',
          height: '30px',
          '& svg': {
            height: '30px',
            width: '30px',
          },
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        height: '56px',
        borderRadius: '8px',
        '@media (min-width:700px)': {
          height: '72px',
        },
        ...(ownerState.color === 'primary' && {
          backgroundColor: `${(theme as Theme).palette.primary.main}`,
          color: 'white',
          '&:hover': {
            backgroundColor: `${(theme as Theme).palette.primary.dark}`,
          },
          '&:disabled': {
            backgroundColor: `${(theme as Theme).palette.customGrey.main}`,
            cursor: 'not-allowed',
            color: 'white',
          },
        }),
        ...(ownerState.color === 'secondary' && {
          backgroundColor: `transparent`,
          color: `${(theme as Theme).palette.secondary.contrastText}`,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: `${(theme as Theme).palette.border.main}`,
        }),
      }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        '@media (min-width:700px)': {
          marginTop: '7px',
          fontSize: `20px !important`,
        },
        '&.Mui-focused': {
          display: 'none',
        },
        '&.MuiFormLabel-filled': {
          display: 'none',
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        height: '48px',
        borderRadius: '8px',
        backgroundColor: `${(theme as Theme).palette.customGrey.dark}`,
        color: `${(theme as Theme).palette.customGrey.contrastText}`,
        borderColor: `${(theme as Theme).palette.customGrey.dark}`,

        '& input': {
          padding: '0 16px',
          height: '100%',
          '&:-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 100px ${
              (theme as Theme).palette.customGrey.dark
            } inset !important`,
            backgroundColor: `${
              (theme as Theme).palette.customGrey.dark
            } !important`,
          },
        },
        '& fieldset': {
          borderColor: `${(theme as Theme).palette.customGrey.dark} !important`,
          '& legend': {
            width: '0',
          },
        },
        '&.Mui-focused fieldset': {
          borderColor: `${(theme as Theme).palette.customGrey.main} !important`,
          borderWidth: '1px !important',
        },
        '& .MuiInputAdornment-root fieldset': {
          border: 'none !important',
        },
        '@media (min-width:700px)': {
          height: '72px',
          fontSize: '20px',
        },
      }),
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.active': {
          borderRadius: '2.88rem',
          backgroundColor: `${(theme as Theme).palette.customChip.active}`,
          color: `${(theme as Theme).palette.customChip.activeTextColor}`,
          padding: '0.31rem 0.63rem',
          fontSize: '0.75rem',
          fontWeight: 400,
          opacity: 1,
        },
        '&:hover': {
          backgroundColor: `${(theme as Theme).palette.customChip.active}`,
        },
        '&.disabled': {
          borderRadius: '2.88rem',
          backgroundColor: `${(theme as Theme).palette.customChip.disabled}`,
          color: `${(theme as Theme).palette.customChip.disabledTextColor}`,
          padding: '0.31rem 0.63rem',
          fontSize: '0.75rem',
          fontWeight: 400,
          opacity: 1,
        },
      }),
    },
  },
};

export default components;
