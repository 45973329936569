import { Avatar, Box, Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BackIcon, BackIconDark } from '../../assets/svgs';
import BigAvatarDialog from '../../components/BigAvatarDialog';
import ChatMembersLoading from '../../components/Chats/ChatMembersLoading';
import SendDMButton from '../../components/Chats/SendDMButton';
import { desktopOnly } from '../../hooks/useIsDesktop';
import { useIsMobile } from '../../hooks/useIsMobile';
import EmptyStateLayout from '../../layouts/EmptyStateLayout';
import chatsService from '../../services/chats.service';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../store/store';
import { nameToAvatar, nameToColor } from '../../utils/avatar';
import { userNickname } from './functions';
import { IAvatarModalProps } from './types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 48px 16px;

  ${desktopOnly} {
    padding: 64px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
`;

const modifyName = (name: string, isMe: boolean): string => {
  return isMe ? `${name} (Mí)` : name;
};

const generateChatsPathWithInitial = (initialChatId: string): string => {
  return `/chats?${new URLSearchParams({ initialChatId: initialChatId })}`;
};

const ChatMembers = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('chats');
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [members, setMembers] = useState<any>([]);
  const theme = useTheme();
  const [backButtonPath, setBackButtonPath] = useState<string>('');
  const { user } = useTypedSelector((state) => state.auth);
  const isMobile = useIsMobile();
  const [avatarModalOptions, setAvatarModalOptions] =
    useState<IAvatarModalProps>({
      open: false,
    });

  const getData = async (chatId: string) => {
    try {
      const res = await chatsService.getCurrentChat(+chatId);
      if (res.data) {
        setMembers(res.data?.users);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const urlChatId = pathname.split('/')[2];

    const path = generateChatsPathWithInitial(urlChatId);

    setBackButtonPath(path);
    getData(urlChatId);
  }, []);

  const handleSendDmSuccess = (chatId: number) => {
    const path = generateChatsPathWithInitial(chatId.toString());
    navigate(path);
  };

  const handleOpenAvatarModal = (member: any, nickname: string) => {
    setAvatarModalOptions({
      open: true,
      avatar: member?.user?.avatarUrl,
      title: nickname,
      userId: member?.user?.id,
      letter: nickname[0].toUpperCase(),
      showSendDmButton: Number(user?.id) !== member?.user?.id,
    });
  };

  return (
    <>
      <Container>
        <RowContainer>
          <div style={{ flex: 1 }}>
            {theme.palette.mode === 'dark' ? (
              <BackIconDark onClick={() => navigate(backButtonPath)} />
            ) : (
              <BackIcon onClick={() => navigate(backButtonPath)} />
            )}
          </div>
          <Typography variant="h1" sx={{ fontSize: '24px', fontWeight: 800 }}>
            {t('membersList')}
          </Typography>
          <div style={{ flex: 1 }} />
        </RowContainer>
        {isLoading ? (
          <ChatMembersLoading />
        ) : (
          <EmptyStateLayout lengthOfData={members.length}>
            <Box display="flex" flexDirection="column" gap="24px">
              {members.map(
                (member: {
                  user: {
                    id: number;
                    nickname: string;
                    color: string;
                    avatarUrl: string;
                  };
                }) => {
                  const nickname = userNickname(
                    member?.user?.id,
                    member?.user?.nickname
                  );

                  return (
                    <Grid container key={'members-' + member?.user?.id}>
                      <Grid
                        item
                        xs={isMobile ? 10 : 7}
                        display="flex"
                        flexDirection="row"
                        gap="8px"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Box
                          sx={{ display: 'flex' }}
                          onClick={() =>
                            handleOpenAvatarModal(member, nickname)
                          }
                        >
                          <Avatar
                            alt="Avatar"
                            src={member?.user?.avatarUrl}
                            sx={{
                              width: '40px !important',
                              height: '40px !important',
                              bgcolor:
                                member?.user?.color || nameToColor(nickname),
                            }}
                            {...nameToAvatar(nickname)}
                          />
                        </Box>
                        <Typography
                          variant="h3"
                          color="secondary.contrastText"
                          sx={{
                            width: 'calc(100% - 40px)',
                            overflowX: 'clip',
                            textOverflow: 'ellipsis',
                            fontSize: '16px',
                            fontWeight: 700,
                          }}
                        >
                          {modifyName(
                            nickname,
                            Number(user?.id) === member?.user?.id
                          )}
                        </Typography>
                      </Grid>
                      {Number(user?.id) !== member?.user?.id && (
                        <Grid
                          item
                          xs={isMobile ? 2 : 5}
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <SendDMButton
                            userId={member?.user?.id}
                            onSuccess={handleSendDmSuccess}
                            iconOnly={isMobile}
                          />
                        </Grid>
                      )}
                    </Grid>
                  );
                }
              )}
            </Box>
          </EmptyStateLayout>
        )}
      </Container>
      {avatarModalOptions?.open && (
        <BigAvatarDialog
          isOpen={avatarModalOptions.open}
          onClose={() => setAvatarModalOptions({ open: false })}
          avatar={avatarModalOptions?.avatar}
          title={avatarModalOptions?.title as string}
          userId={avatarModalOptions?.userId as number}
          showSendDmButton={!!avatarModalOptions?.showSendDmButton}
          onSendDm={handleSendDmSuccess}
          letter={avatarModalOptions?.letter}
          isDesktop={!isMobile}
        />
      )}
    </>
  );
};

export default ChatMembers;
