import React, { createContext, FC, useEffect, useState } from 'react';
import { useIsDesktop } from '../hooks/useIsDesktop';
import BottomAppBar from './BottomAppBar';
import TopAppLayout from './TopAppLayout';
import { useLocation } from 'react-router-dom';

interface IAuthLayout {
  children: React.ReactElement;
  withTopBar?: boolean;
  withBottomBar?: boolean;
}

interface IBottomBarContext {
  shouldShow?: boolean;
  setShouldShow?: (prop: boolean) => void;
}

const BottomBarContext = createContext<IBottomBarContext>({});
export { BottomBarContext };

const DefaultLayout: FC<IAuthLayout> = ({
  children,
  withBottomBar = true,
  withTopBar = true,
}: IAuthLayout) => {
  const [showBottomBar, setShowBottomBar] = useState(true);
  const isDesktop = useIsDesktop();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.includes('ofertas') ||
      location.pathname.includes('eventos')
    ) {
      setShowBottomBar(true);
    }
  }, [location]);

  return (
    <BottomBarContext.Provider
      value={{
        shouldShow: showBottomBar,
        setShouldShow: setShowBottomBar,
      }}
    >
      {isDesktop && withTopBar && <TopAppLayout />}
      {children}
      {!isDesktop && withBottomBar && showBottomBar && <BottomAppBar />}
    </BottomBarContext.Provider>
  );
};

export default DefaultLayout;
