import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BackgroundImage from '../../assets/pngs/table-cover.png';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '620px',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '24px',
    width: 'calc(100% - 48px)',
    paddingLeft: '48px',
    flexDirection: 'column',
    '@media (min-width:1007px)': {
      gap: '48px',
      width: '100%',
      flexDirection: 'row',
    },
  },
  copyButtonContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    marginTop: '5%',
    fontSize: '52px !important',
    lineHeight: '67.2px !important',
    marginBottom: '24px !important',
    fontWeight: '800 !important',
  },
  description: {
    fontSize: '24px !important',
    lineHeight: '30px !important',
    marginBottom: '24px !important',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '300 !important',
    paddingLeft: '24px',
    paddingRight: '24px',
    '@media (min-width:1007px)': {
      paddingLeft: 'auto',
      paddingRight: 'auto',
    },
  },
}));

const TextWithCopyCentred = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation('landing');

  return (
    <div className={classes.container}>
      <div className={classes.copyButtonContainer}>
        <Typography
          align="center"
          className={classes.title}
          color="primary.contrastText"
          variant="h2"
        >
          <Trans i18nKey="Copy Centered Title" t={t} />
        </Typography>
        <Typography
          align="left"
          className={classes.description}
          color="primary.contrastText"
          variant="h3"
        >
          <Trans i18nKey="Copy Centered Description" t={t} />
        </Typography>
        <div className={classes.buttonsContainer}>
          <Button
            color="primary"
            sx={{
              padding: '16px 24px',
              fontSize: '16px !important',
              fontWeight: 500,
              maxHeight: '52px',
            }}
            onClick={() => navigate('/signup')}
          >
            <Trans i18nKey="Sign Up" t={t} />
          </Button>
          <Button
            sx={{
              padding: '16px 24px',
              borderColor: '#E2E2E2',
              color: '#E2E2E2',
              fontSize: '16px !important',
              fontWeight: 500,
              maxHeight: '52px',
            }}
            color="secondary"
            onClick={() => navigate('/login')}
            size="small"
          >
            <Trans i18nKey="Login" t={t} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TextWithCopyCentred;
