import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import React, { useState, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorIcon, SuccessIcon } from '../../assets/svgs';
import UserImage, { EStatuses } from '../../components/avatar/UserImage';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import userService from '../../services/user.service';
import {
  fetchStarted,
  resultLoaded,
  userUpdated
} from '../../store/slices/api.slice';
import { dispatch, useTypedSelector } from '../../store/store';
import { useTranslation } from 'react-i18next';

const ProfileSetup = () => {
  const { user } = useTypedSelector((state) => state.auth);
  const isDesktop = useIsDesktop();
  const [errorMessage, setErrorMessage] = useState(null);
  const [status, setStatus] = useState<EStatuses>(EStatuses.initial);
  const { t } = useTranslation(['profile', 'common']);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        nickname: Yup.string()
          .min(3, t('validation.notValidNickname') as any)
          .required(t('validation.nicknameRequired') as any)
      }),
    [t]
  );

  const handleSubmit = async (
    values: { nickname: string },
    actions: FormikHelpers<{ nickname: string }>
  ) => {
    setErrorMessage(null);
    dispatch(fetchStarted());
    userService
      .update({
        nickname: values.nickname
      })
      .then((res) => {
        dispatch(userUpdated(res.data));
        dispatch(resultLoaded());
        setStatus(EStatuses.success);
      })
      .catch((err) => {
        console.error(err);
        setStatus(EStatuses.failed);
        const { status, details, message } = err;
        if (status !== 400 && status !== 409) {
          return setErrorMessage(message);
        }

        if (!details || !details.fields || details.fields.length === 0) {
          return setErrorMessage(message);
        }

        if (details.fields.includes('nickname')) {
          return actions.setFieldError(
            'nickname',
            t('validation.nicknameAlreadyInUse') as any
          );
        }
        dispatch(resultLoaded());
      });
  };

  return (
    <>
      {user?.accessToken && !user.nickname ? (
        <>
          <Box
            sx={{
              textAlign: 'center',
              marginBottom: '56px'
            }}
          >
            <Typography variant={'h1'}>{t('welcome')}</Typography>
            <Box
              sx={{
                margin: '32px auto 24px auto',
                width: '-webkit-fit-content'
              }}
            >
              <UserImage />
            </Box>
            <Typography variant={'body2'}>
              {t('initialSetup.nicknameDescription')}
            </Typography>
          </Box>
          <Formik
            initialValues={{
              nickname: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
          >
            {({
                values,
                errors,
                touched,
                handleSubmit,
                isSubmitting,
                isValid,
                dirty,
                handleChange,
                handleBlur
              }) => (
              <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                <FormControl
                  fullWidth
                  error={!!errors.nickname && !!touched.nickname}
                >
                  <Typography
                    variant='body1'
                    fontWeight={700}
                    sx={{ marginBottom: isDesktop ? '24px' : '8px' }}
                  >
                    {t('initialSetup.selectNickname')}
                  </Typography>
                  <TextField
                    name='nickname'
                    placeholder={t('initialSetup.nickname') as any}
                    fullWidth
                    label={t('initialSetup.nickname')}
                    value={values.nickname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.nickname && !!touched.nickname}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position='end'
                          sx={{
                            svg: {
                              width: '15px',
                              height: '15px'
                            }
                          }}
                        >
                          {status === EStatuses.success && <SuccessIcon />}
                          {status === EStatuses.failed && <ErrorIcon />}
                        </InputAdornment>
                      )
                    }}
                  />
                  {errors.nickname && touched.nickname && (
                    <FormHelperText id='nickname' error>
                      {errors.nickname}
                    </FormHelperText>
                  )}
                </FormControl>
                {!!errorMessage && (
                  <FormHelperText id='form' error>
                    {errorMessage}
                  </FormHelperText>
                )}
                <Button
                  type='submit'
                  disabled={!(isValid && dirty && !isSubmitting)}
                  fullWidth
                  color='primary'
                >
                  {isSubmitting ? t('processing') : t('next')}
                </Button>
              </form>
            )}
          </Formik>
        </>
      ) : (
        <Navigate to={`/login`} />
      )}
    </>
  );
};

export default ProfileSetup;
