import { useMediaQuery } from 'react-responsive';

import breakpoints from '../theme/breakpoints';

export const desktopOnly = `@media (min-width: ${breakpoints.desktop})`;

export const useIsDesktop = () =>
  useMediaQuery({
    query: `(min-width: ${breakpoints.desktop})`,
  });
