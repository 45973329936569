import { Skeleton } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import { desktopOnly, useIsDesktop } from '../../hooks/useIsDesktop';

const CustomSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 32px;

  ${desktopOnly} {
    flex-basis: 22%;
  }
`;
const OfferSkeleton = () => {
  const isDesktop = useIsDesktop();

  return (
    <CustomSkeletonContainer>
      <Skeleton
        height={335}
        sx={{ borderRadius: '15px', marginTop: '-75px' }}
      />
      <Skeleton height={50} sx={{ marginTop: '-53px' }} />
      <Skeleton height={30} />
      <Skeleton height={30} />
      {isDesktop && <Skeleton height={30} />}
    </CustomSkeletonContainer>
  );
};

const OffersLoading = () => {
  return (
    <>
      <OfferSkeleton />
      <OfferSkeleton />
      <OfferSkeleton />
      <OfferSkeleton />
      <OfferSkeleton />
      <OfferSkeleton />
      <OfferSkeleton />
      <OfferSkeleton />
      <OfferSkeleton />
      <OfferSkeleton />
    </>
  );
};
export default OffersLoading;
