import { EOfferType } from '../../enums/offer-type.enum';

export const setOfferTypeAndValue = (
  type?: EOfferType,
  discount?: string
): string => {
  if (!discount) return '';

  const offerType = type || EOfferType.Integer;

  const offerMapper = {
    INTEGER: '€',
    PERCENT: '%',
  };

  return offerMapper[offerType]
    ? `${discount}${offerMapper[offerType]}`
    : `${discount}${offerMapper.INTEGER}`
};
