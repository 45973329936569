import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  cardActive: {
    backgroundColor: `${(theme as Theme).palette.primary.main}`,
    padding: '8px 12px',
    borderRadius: '8px',
    width: '100%',
    marginBottom: '16px',
    cursor: 'pointer',
    color: 'white !important',
  },
  cardInactive: {
    backgroundColor: `${(theme as Theme).palette.secondary.main}`,
    padding: '8px 12px',
    borderRadius: '8px',
    width: '100%',
    marginBottom: '16px',
    cursor: 'pointer',
  },
  discountLabel: {
    borderRadius: '8px',
    backgroundColor: `${(theme as Theme).palette.customRed.main}`,
    color: `${(theme as Theme).palette.customRed.contrastText}`,
    padding: '3px',
    textAlign: 'center',
    marginLeft: 'auto',
  },
  planTitle: {
    fontWeight: '700 !important',
    opacity: 0.8,
    color: `${(theme as Theme).palette.neutral.dark}`,
  },
  bottomDescription: {
    width: '80%',
    marginBottom: '16px !important',
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.3125rem",
    marginTop: '16px !important',
    color: `${(theme as Theme).palette.customGrey.light}`,
  },
}));
