import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import EventListItem from '../../components/Events/EventListItem';
import EventsLoading from '../../components/Events/EventsLoading';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import EmptyStateLayout from '../../layouts/EmptyStateLayout';
import TopAuthMobileLayout, {
  ETopAuthMobileLayoutVariant,
} from '../../layouts/TopAuthMobileLayout';
import eventsService from '../../services/events.service';
import { useTypedSelector } from '../../store/store';
import { Container } from './components';

const EventsList = () => {
  const { user } = useTypedSelector((state) => state.auth);
  const isDesktop = useIsDesktop();
  const { t } = useTranslation('events');

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [events, setEvents] = useState([]);

  const getData = async () => {
    try {
      setIsLoading(true);
      const res = await eventsService.getEventsList(user?.locationId);

      if (res.data) {
        setEvents(res.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('title')}</title>
        <meta name="description" content={t('description') as any} />
        <link rel="canonical" href={'https://app.doss.es/eventos/'} />
      </Helmet>
      {!isDesktop && (
        <TopAuthMobileLayout variant={ETopAuthMobileLayoutVariant.logo} />
      )}
      <>
        <Typography
          variant="h1"
          align={isDesktop ? 'center' : 'left'}
          sx={{
            width: isDesktop ? '100%' : 'auto',
          }}
        >
          {t('title')}
        </Typography>
        {isLoading ? (
          <EventsLoading />
        ) : (
          <EmptyStateLayout lengthOfData={events.length}>
            <>
              {events.map(
                (
                  el: {
                    imageUrl: string | undefined;
                    title: string;
                    participantsCount: number;
                    participantsLimit: number;
                    startDate: string;
                    userOffers: any;
                    id: string;
                    description: string;
                    price: string | undefined;
                    like: string | undefined;
                  },
                  id
                ) => (
                  <EventListItem el={el} key={'event-' + id} />
                )
              )}
            </>
          </EmptyStateLayout>
        )}
      </>
    </Container>
  );
};

export default EventsList;
