import { FC, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserRole } from '../@types/user';
import { SubscriptionStatus } from '../enums/subscription-status.enum';
import { useTypedSelector } from '../store/store';

export const NotAuthGuard: FC<PropsWithChildren> = ({ children }) => {
  const { loggedin, user, isLoading } = useTypedSelector((state) => state.auth);
  const location = useLocation();

  if (
    !isLoading &&
    loggedin &&
    user?.nickname &&
    (user?.subscription?.status === SubscriptionStatus.Active ||
      user?.subscription?.status === SubscriptionStatus.Created ||
      user?.role === UserRole.Influencer)
  ) {
    return <Navigate to="/ofertas" state={{ from: location }} />;
  }

  return <>{children}</>;
};
