import {
  DEFAULT_LANGUAGE_CODE,
  SUPPORTED_LANGUAGES_CODE,
  SUPPORTED_LANGUAGES_INFO,
} from '../constants/localizations.constants';

export const getCurrentLanguageCode = (
  language: string
): SUPPORTED_LANGUAGES_CODE => {
  const code = language as SUPPORTED_LANGUAGES_CODE;

  if (SUPPORTED_LANGUAGES_INFO[code]) {
    return code;
  }

  return DEFAULT_LANGUAGE_CODE;
};

export const getCurrentLanguageLocale = (language: string): string => {
  const code = getCurrentLanguageCode(language);

  return SUPPORTED_LANGUAGES_INFO[code].localeCode || code;
};
