import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AvatarImage from '../../assets/svgs/Ellipse 176.svg';
import { useIsDesktop } from '../../hooks/useIsDesktop';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    padding: '0 24px',
    '@media (min-width:1007px)': {
      paddingLeft: '212px',
      paddingRight: '212px',
      marginBottom: '55px',
    },
  },
  feedbackContainer: {
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width:1007px)': {
      width: '100%',
      flexDirection: 'row',
    },
  },
  image: {
    marginBottom: '24px',
    '@media (min-width:1007px)': {
      marginRight: '32px',
      marginBottom: 'auto',
    },
  },
  copiesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const FeedbackCopy = () => {
  const classes = useStyles();
  const isDesktop = useIsDesktop();
  const { t } = useTranslation('landing');

  return (
    <div className={classes.container}>
      <Typography
        sx={{
          fontWeight: 800,
          fontSize: '52px !important',
          marginBottom: isDesktop ? '67px' : '16px',
        }}
        align="center"
        variant="h2"
      >
        {t('Title4')}
      </Typography>
      <div className={classes.feedbackContainer}>
        <img className={classes.image} src={AvatarImage} alt="Avatar" />
        <div>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '20px',
              marginBottom: '16px',
              lineHeight: '30px',
            }}
            color="neutral.dark"
            variant="h3"
          >
            <Trans i18nKey="Feedback Title" t={t} />
          </Typography>
          <Typography
            sx={{ fontWeight: 300, fontSize: '24px' }}
            color="primary"
            variant="h2"
          >
            {t('Feedback Name')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default FeedbackCopy;
