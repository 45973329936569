import Http from './http.common';
import { ConnectedAccountUrlType } from '../enums/connected-account-url-type.enum';

class PayoutsService {
  apiUrl = '/payouts';

  getConnectedAccountUrl(type: ConnectedAccountUrlType) {
    return Http.post<any>(this.apiUrl + `/connected-account-url`, {
      type,
      returnUrl: `${window.location.origin}/perfil`,
      refreshUrl: `${window.location.origin}/perfil/stripe-payout-refresh`,
    });
  }

  getAllPayouts() {
    return Http.get(this.apiUrl);
  }
}

export default new PayoutsService();
