import {
  Box,
  CircularProgress,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  AmigosIcon,
  ArrowRightIcon,
  CopyIcon,
  OrangeCheckMark,
  UsersAcceptedIcon,
  UsersPendingIcon,
} from '../../assets/svgs';
import FriendAccepted from '../../components/Friends/FriendAccepted';
import FriendPending from '../../components/Friends/FriendPending';
import FriendsLoading from '../../components/Friends/FriendsLoading';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import TopAuthMobileLayout, {
  ETopAuthMobileLayoutVariant,
} from '../../layouts/TopAuthMobileLayout';
import friendsService from '../../services/friends.service';
import { useTypedSelector } from '../../store/store';
import {
  Container,
  DesktopUsersList,
  ResponsiveElement,
  ResposiveContainer,
  UsersContainer,
} from './components';
import { useTranslation } from 'react-i18next';

enum ESendingInviteState {
  initial = 'initial',
  sending = 'sending',
  sent = 'sent',
}

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: `${(theme as Theme).palette.primary.contrastText} !important`,
  },
}));

const Friends = () => {
  const { t } = useTranslation([ 'friends', 'common' ]);
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const theme = useTheme();
  const classes = useStyles();
  const { user } = useTypedSelector((state) => state.auth);
  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ sendingInviteState, setSendingInviteState ] =
    useState<ESendingInviteState>(ESendingInviteState.initial);
  const [ referralCode, setReferralCode ] = useState<string>(
    user?.referralCode || ''
  );
  const [ emailInput, setEmailInput ] = useState<string>('');
  const [ emailInputError, setEmailInputError ] = useState<string | null>(null);
  const [ pendingCount, setPendingCount ] = useState<number>(0);
  const [ pending, setPending ] = useState([]);
  const [ acceptedCount, setAcceptedCount ] = useState<number>(0);
  const [ accepted, setAccepted ] = useState([]);

  const getReferralCode = async () => {
    const resp = await friendsService.getReferralCode();
    setReferralCode(resp.data?.referralCode);
  };

  const getPendingUsers = async () => {
    const pendingResp = await friendsService.getPendingCount();
    if (pendingResp.data) {
      setPendingCount(pendingResp.data?.total);
      setPending(pendingResp.data?.data);
    }
  };

  const getAcceptedUsers = async () => {
    const acceptedResp = await friendsService.getReferrals();
    if (acceptedResp.data) {
      setAcceptedCount(acceptedResp.data?.total);
      setAccepted(acceptedResp.data?.data);
    }
  };
  const getData = async () => {
    try {
      setIsLoading(true);
      if (referralCode.length === 0) {
        getReferralCode();
      }
      getPendingUsers();
      getAcceptedUsers();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const sendRequest = async () => {
    try {
      setEmailInputError(null);

      if (
        !String(emailInput)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        return setEmailInputError(t('validation.notValidEmail'));
      }

      setSendingInviteState(ESendingInviteState.sending);
      setEmailInput('');
      await friendsService.sendEmail(emailInput);
      getPendingUsers();
    } catch (e) {
      toast.error(e as string);
    } finally {
      setSendingInviteState(ESendingInviteState.sent);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('title')}</title>
        <meta name="description" content={t('title') as any} />
        <link rel="canonical" href={'https://app.doss.es/invitaramigos/'} />
      </Helmet>
      {!isDesktop && (
        <Box sx={{ padding: '0px 16px' }}>
          <TopAuthMobileLayout
            customContainerStyles={{ padding: '12px 0 0' }}
            showBell={false}
            variant={ETopAuthMobileLayoutVariant.logo}
          />
        </Box>
      )}
      <Container>
        <AmigosIcon
          style={{
            maxWidth: isDesktop ? '416px' : '50%',
            maxHeight: isDesktop ? '100%' : '110px',
          }}
        />
        <Typography
          variant="h1"
          sx={{ fontSize: '24px', paddingTop: '8px', fontWeight: 800 }}
        >
          {t('inviteText')}
        </Typography>
        <Typography
          variant="h3"
          color="neutral.dark"
          align="center"
          sx={{ fontSize: '14px', fontWeight: 400, maxWidth: '65%' }}
        >
          {t('bonusText')}
        </Typography>
        <ResposiveContainer>
          <ResponsiveElement>
            <Typography
              variant="h3"
              color="neutral.dark"
              sx={{
                fontSize: '16px',
                fontWeight: 800,
                width: '100%',
              }}
            >
              {t('inviteLink')}
            </Typography>
            <TextField
              placeholder={referralCode}
              variant="outlined"
              disabled
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyIcon
                      style={{
                        cursor: 'pointer',
                        width: '20px',
                        height: '20px',
                      }}
                      onClick={async () => {
                        try {
                          await navigator.clipboard.writeText(referralCode);

                          toast.success(t('copied'));
                        } catch (e) {
                          toast.error(e as any);
                        }
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                color: '#A6A6A6',
                borderRadius: '8px',
                border: '1.5px dashed #FFA132',
                strokeDasharray: 5,
                width: isDesktop ? '90%' : '100%',
              }}
            />
          </ResponsiveElement>
          <ResponsiveElement>
            <Typography
              variant="h3"
              color="neutral.dark"
              sx={{
                fontSize: '16px',
                fontWeight: 800,
                width: '100%',
              }}
            >
              {t('inviteByEmailText')}
            </Typography>
            <TextField
              placeholder={t('email') as any}
              variant="outlined"
              type="email"
              onFocus={() => setSendingInviteState(ESendingInviteState.initial)}
              onChange={(e) => setEmailInput(e.target.value)}
              value={emailInput}
              InputProps={{
                endAdornment:
                  sendingInviteState === ESendingInviteState.sending ? (
                    <InputAdornment position="end">
                      <CircularProgress
                        style={{
                          cursor: 'pointer',
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    </InputAdornment>
                  ) : sendingInviteState === ESendingInviteState.initial ? (
                    <InputAdornment position="end">
                      <ArrowRightIcon
                        style={{ cursor: 'pointer', width: '20px' }}
                        onClick={sendRequest}
                      />
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <OrangeCheckMark
                        style={{ cursor: 'pointer', width: '20px' }}
                      />
                    </InputAdornment>
                  ),
              }}
              sx={{
                backgroundColor: '#F2F2F2',
                color: '#A6A6A6',
                borderRadius: '8px',
                width: isDesktop ? '90%' : '100%',
              }}
            />
            {emailInputError && (
              <FormHelperText id="email" error>
                {emailInputError}
              </FormHelperText>
            )}
          </ResponsiveElement>
        </ResposiveContainer>
        <Typography
          align={isDesktop ? 'center' : 'left'}
          variant="h3"
          color="neutral.dark"
          sx={{
            fontSize: '16px',
            fontWeight: 800,
            width: '100%',
          }}
        >
          {t('statistics')}
        </Typography>
        {isDesktop ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '32px',
              width: '100%',
            }}
          >
            <DesktopUsersList className={classes.paper} theme={theme}>
              <div
                style={{ display: 'flex', cursor: 'pointer' }}
                onClick={() => navigate('/invitaramigos/accepted')}
              >
                <Typography
                  variant="h3"
                  color="neutral.dark"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 800,
                    width: '100%',
                  }}
                >
                  {t('registeredFriends')}
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: '12px',
                    color: '#FFFFFF',
                    backgroundColor: '#20D73E',
                    padding: '4.5px 12px',
                    borderRadius: '40px',
                    fontWeight: 700,
                  }}
                >
                  {acceptedCount}
                </Typography>
              </div>
              {isLoading ? (
                <FriendsLoading />
              ) : (
                accepted.map(
                  (
                    usr: {
                      avatarUrl: string | undefined;
                      referral: any;
                      nickname: string;
                      createdAt: string;
                      email: string;
                    },
                    id
                  ) => <FriendAccepted usr={usr} key={'accepted-' + id} />
                )
              )}
            </DesktopUsersList>
            <DesktopUsersList className={classes.paper} theme={theme}>
              <div
                style={{ display: 'flex', cursor: 'pointer' }}
                onClick={() => navigate('/invitaramigos/pending')}
              >
                <Typography
                  variant="h3"
                  color="neutral.dark"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 800,
                    width: '100%',
                  }}
                >
                  {t('pendingInvites')}
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: '12px',
                    color: '#FFFFFF',
                    backgroundColor: '#FFA132',
                    padding: '4.5px 12px',
                    borderRadius: '40px',
                    fontWeight: 700,
                  }}
                >
                  {pendingCount}
                </Typography>
              </div>
              {isLoading ? (
                <FriendsLoading />
              ) : (
                pending.map(
                  (
                    usr: { email: string; id: number; createdAt: string },
                    id
                  ) => (
                    <FriendPending
                      key={'pending-' + id}
                      email={usr?.email}
                      id={usr?.id}
                      createdAt={usr?.createdAt}
                    />
                  )
                )
              )}
            </DesktopUsersList>
          </div>
        ) : (
          <>
            <UsersContainer
              className={classes.paper}
              onClick={() => navigate('/invitaramigos/accepted')}
            >
              <UsersAcceptedIcon />
              <Typography
                variant="h3"
                color="neutral.dark"
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  flex: 1,
                }}
              >
                {t('registeredFriends')}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontSize: '12px',
                  color: '#FFFFFF',
                  backgroundColor: '#20D73E',
                  padding: '4.5px 12px',
                  borderRadius: '40px',
                  fontWeight: 700,
                }}
              >
                {acceptedCount}
              </Typography>
            </UsersContainer>
            <UsersContainer
              className={classes.paper}
              onClick={() => navigate('/invitaramigos/pending')}
            >
              <UsersPendingIcon />
              <Typography
                variant="h3"
                color="neutral.dark"
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  flex: 1,
                }}
              >
                {t('pendingInvites')}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontSize: '12px',
                  color: '#FFFFFF',
                  backgroundColor: '#FFA132',
                  padding: '4.5px 12px',
                  borderRadius: '40px',
                  fontWeight: 700,
                }}
              >
                {pendingCount}
              </Typography>
            </UsersContainer>
          </>
        )}
      </Container>
    </>
  );
};

export default Friends;
