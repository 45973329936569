import { useTheme } from '@mui/material';
import { useLottie } from 'lottie-react';
import la8g9sbk from '../assets/animations/animation_la8g9sbk.json';
import la8ga6dh from '../assets/animations/animation_la8ga6dh.json';

const LoadingScreen = () => {
  const theme = useTheme();
  const style = {
    height: 100,
    width: 100,
    margin: 'auto',
    marginRight: 'auto',
  };
  const options = {
    animationData: theme.palette.mode === 'light' ? la8ga6dh : la8g9sbk,
    loop: true,
  };

  const { View } = useLottie(options, style);
  return (
    <div className="tw-w-full tw-h-full tw-fixed tw-top-0 tw-left-0 tw-opacity-75 tw-z-50 tw-flex">
      {/* <BallTriangle
        wrapperClass="tw-m-auto"
        height={100}
        width={100}
        radius={5}
        color="#4fa94d"
        ariaLabel="ball-triangle-loading"
        visible={true}
      /> */}
      {View}
    </div>
  );
};

export default LoadingScreen;
