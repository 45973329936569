import { Avatar, Badge, Typography, Box, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { EColorModes } from '../App';
import BellIcon from '../assets/svgs/bell.svg';
import LogoBlack from '../assets/svgs/logo-black.svg';
import LogoWhite from '../assets/svgs/logo-white.svg';
import LanguagesSelect from '../components/localizations/LanguagesSelect';
import { useTypedSelector } from '../store/store';
import { useTranslation } from 'react-i18next';
import { nameToAvatar, nameToColor } from '../utils/avatar';
import { UNREAD_COUNT_BADGE_LIMIT } from '../utils/notifications';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 12px 0 24px;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
`;

const NotificationsIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export enum ETopAuthMobileLayoutVariant {
  logo = 'logo',
  photo = 'photo',
}

interface ITopAuthMobileLayout {
  variant?: ETopAuthMobileLayoutVariant;
  showBell?: boolean;
  customContainerStyles?: React.ClassType<any, any, any>;
}

const TopAuthMobileLayout: FC<ITopAuthMobileLayout> = ({
  variant = ETopAuthMobileLayoutVariant.photo,
  showBell = true,
  customContainerStyles,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { user } = useTypedSelector((state) => state.auth);
  const theme = useTheme();
  const { unreadCount } = useTypedSelector((state) => state.notification);

  return (
    <Container style={customContainerStyles}>
      {variant === ETopAuthMobileLayoutVariant.logo && (
        <img
          src={theme.palette.mode === EColorModes.light ? LogoBlack : LogoWhite}
          alt="DOSS Logo"
          style={{ maxHeight: '32px', maxWidth: '147px' }}
          onClick={() => navigate('/ofertas')}
        />
      )}
      {variant === ETopAuthMobileLayoutVariant.photo && (
        <Box
          display="flex"
          flexDirection="row"
          gap="8px"
          alignItems="center"
          flex={1}
          sx={{ overflowX: 'hidden' }}
        >
          <Avatar
            sx={{
              cursor: 'pointer',
              width: '32px',
              height: '32px',
              bgcolor: nameToColor(user?.name),
            }}
            src={user?.avatarUrl}
            alt="Avatar"
            onClick={() => navigate('/perfil')}
            {...nameToAvatar(user?.name)}
          />
          <Typography
            onClick={() => navigate('/perfil')}
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              flex: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            variant="h3"
          >
            {t('greeting', { name: user?.name })}
          </Typography>
        </Box>
      )}
      <Box display="flex" flexDirection="row" gap="24px" alignItems="center">
        <LanguagesSelect variant="mobile" />
        {showBell &&
          (unreadCount ? (
            <Badge
              sx={{
                '& .MuiBadge-badge': {
                  color: theme.palette.customRed.contrastText,
                  backgroundColor: theme.palette.customRed.main,
                },
              }}
              badgeContent={unreadCount}
              max={UNREAD_COUNT_BADGE_LIMIT}
            >
              <NotificationsIcon
                src={BellIcon}
                alt="Notifications Icon"
                onClick={() => navigate('/notificaciones')}
              />
            </Badge>
          ) : (
            <NotificationsIcon
              src={BellIcon}
              alt="Notifications Icon"
              onClick={() => navigate('/notificaciones')}
            />
          ))}
      </Box>
    </Container>
  );
};

export default TopAuthMobileLayout;
