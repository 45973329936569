import { Box, Skeleton } from '@mui/material';

const FriendSkeleton = () => {
  return (
    <Box display="flex" gap={1} marginTop={2}>
      <Skeleton height={42} width={42} variant="circular" />
      <Box width="50%">
        <Skeleton height={30} sx={{ marginTop: '-5px' }} />
        <Skeleton height={25} sx={{ marginTop: '-5px' }} />
      </Box>
      <Box width="35%" marginLeft="auto">
        <Skeleton height={30} sx={{ marginTop: '5px' }} />
      </Box>
    </Box>
  );
};

const FriendsLoading = () => {
  return (
    <>
      <FriendSkeleton />
      <FriendSkeleton />
      <FriendSkeleton />
      <FriendSkeleton />
      <FriendSkeleton />
    </>
  );
};
export default FriendsLoading;
