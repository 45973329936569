import { createSlice } from '@reduxjs/toolkit';

export enum ENotificationType {
  SIGNUP = 'SIGNUP',
  OFFER_ACTIVATED = 'OFFER_ACTIVATED',
  EVENT_APPLIED = 'EVENT_APPLIED',
  EVENT_CANCELED = 'EVENT_CANCELED',
  FRIEND_JOINED = 'FRIEND_JOINED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  SUBSCRIPTION_CANCELED = 'SUBSCRIPTION_CANCELED',
  EVENT_NEW = 'EVENT_NEW',
  NEW_OFFER = 'OFFER_NEW',
}

export enum ENotificationStatus {
  NEW = 'NEW',
  READ = 'READ',
  DELETED = 'DELETED',
}

export interface NotificationType {
  id: string;
  type: ENotificationType;
  status: ENotificationStatus;
  meta: any;
  createdAt: string;
}

export type NotificationsSliceState = {
  data: Array<NotificationType>;
  loading: boolean;

  unreadCount: number;
};

const initialState: NotificationsSliceState = {
  data: [],
  loading: false,
  unreadCount: 0,
};

export const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    setUnreadCount: (state, action) => {
      state.unreadCount = action.payload.count;
    },
    setNotifications: (state, action) => {
      state.data = action.payload.map((item: any) => ({
        ...item.notification,
        status: item.status,
      }));
      state.unreadCount = state.data.filter(
        (n) => n.status === ENotificationStatus.NEW
      ).length;
    },
    pushNotification: (state, action) => {
      state.data = [...state.data, { ...action.payload }];
      state.unreadCount += 1;
    },
    deleteNotification: (state, action) => {
      state.data = [...state.data.filter((item) => item.id !== action.payload)];
    },
  },
});

export const {
  setUnreadCount,
  setNotifications,
  pushNotification,
  deleteNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;
