import styled from 'styled-components';

export const Selector = styled.select<{ theme: any }>`
  margin-left: auto;
  background: ${(props) =>
    props.theme.palette.mode === 'dark'
      ? props.theme.palette.customGrey.dark
      : ''};
  border: 2px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 16px;
  appearance: unset;
  padding: 8px 20px 8px 8px;
  font-size: 12px !important;
  line-height: 12px !important;
  color: ${(props) => props.theme.palette.primary.main};
`;
