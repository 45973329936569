import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';

import { GeneralFuctionType } from '../../@types/props.types';
import countryOptions from '../../components/MuiPhoneInput/data.json';
import useAuthContext from '../../hooks/useAuthContext';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import authService from '../../services/auth.service';
import { fetchStarted, resultLoaded } from '../../store/slices/api.slice';
import { useDispatch } from '../../store/store';
import BlockHeader from '../BlockHeader';
import LocationSelect from '../LocationSelect/LocationSelect';
import MuiPhone from '../MuiPhoneInput/MuiPhone';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ISignUpForm {
  phoneNumber: string;
  email: string;
  fullName: string;
  invitationCode: string;
  locationId: string;
}

const SignupOne = ({
  handleActiveSectionChange,
  code = '',
  email = '',
}: {
  handleActiveSectionChange: GeneralFuctionType;
  code?: string;
  email?: string;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('login');
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const [agreement, setAgreement] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { userSignupData, setUserSignupData } = useAuthContext();
  const navigate = useNavigate();

  const initialValues = useMemo<ISignUpForm>(() => {
    const regData = localStorage.getItem('registration-data');

    const res: ISignUpForm = {
      phoneNumber: '+34',
      email: email || '',
      fullName: '',
      invitationCode: code || '',
      locationId: '',
    };

    if (userSignupData && Object.keys(userSignupData).length) {
      return { ...res, ...userSignupData };
    }

    if (regData) {
      const prevData = JSON.parse(regData || '{}');
      return { ...res, ...prevData };
    }

    return res;
  }, [userSignupData, code]);
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        phoneNumber: Yup.string()
          .min(11, t('validation.wrongPhone') as any)
          .max(13, t('validation.wrongPhone') as any)
          .matches(/^\+\d+$/, t('validation.wrongPhone') as any)
          .required(t('validation.phoneRequired') as any),
        fullName: Yup.string()
          .min(3, t('validation.shortName') as any)
          .required(t('validation.nameRequired') as any),
        email: Yup.string()
          .email(t('validation.notValidEmail') as any)
          .required(t('validation.emailRequired') as any),
        invitationCode: Yup.string().required(
          t('validation.invitationCodeRequired') as any
        ),
        locationId: Yup.string().required(
          t('validation.locationRequired') as any
        ),
      }),
    [t]
  );

  const handleSubmit = (
    values: ISignUpForm,
    actions: FormikHelpers<ISignUpForm>
  ) => {
    setErrorMessage(null);
    dispatch(fetchStarted());

    localStorage.setItem('registration-data', JSON.stringify(values));

    authService
      .create({
        inviteCode: values.invitationCode,
        email: values.email,
        name: values.fullName,
        phoneNumber: values.phoneNumber,
        locationId: values.locationId,
      })
      .then((res) => {
        handleActiveSectionChange(2);
        navigate('?step=2');
      })
      .catch((err) => {
        const { status, details, message } = err;

        if (status === 429) {
          return setErrorMessage(t('validation.tooManyAttempts') as any);
        }

        if (status !== 400 && status !== 409) {
          return setErrorMessage(message);
        }

        if (!details || !details.fields || details.fields.length === 0) {
          return setErrorMessage(message);
        }

        if (details.fields.includes('invitationCode')) {
          return actions.setFieldError(
            'invitationCode',
            t('validation.notValidInvitationCode') as any
          );
        }

        if (details.fields.includes('email')) {
          return actions.setFieldError(
            'email',
            t('validation.emailAlreadyInUse') as any
          );
        }

        if (details.fields.includes('nickname')) {
          return actions.setFieldError(
            'fullName',
            t('validation.fullNameAlreadyInUse') as any
          );
        }

        if (details.fields.includes('phoneNumber')) {
          return actions.setFieldError(
            'phoneNumber',
            t('validation.phoneNumberAlreadyInUse') as any
          );
        }

        if (details.fields.includes('locationId')) {
          return actions.setFieldError(
            'locationId',
            t('validation.notValidLocation') as any
          );
        }
      })
      .finally(() => {
        dispatch(resultLoaded());
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      {isDesktop ? (
        <BlockHeader title={t('page', { page: 1 })} />
      ) : (
        <Divider sx={{ color: theme.palette.primary.main, width: '100%' }}>
          {t('page', { page: 1 })}
        </Divider>
      )}
      <Box
        sx={{ textAlign: 'center', marginBottom: '56px', marginTop: '24px' }}
      >
        <Typography variant={'h1'}>{t('signup.stepOneTitle')}</Typography>
        <Typography variant={'h3'}>{t('signup.stepOneDescription')}</Typography>
      </Box>
      <Formik<ISignUpForm>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <FormControl
              fullWidth
              error={!!errors.fullName && !!touched.fullName}
            >
              <TextField
                name="fullName"
                placeholder={t('signup.fullName') as any}
                fullWidth
                label={t('signup.fullName')}
                value={values.fullName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.fullName && !!touched.fullName}
              />
              {errors.fullName && touched.fullName && (
                <FormHelperText id="nickname" error>
                  {errors.fullName}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth error={!!errors.email && !!touched.email}>
              <TextField
                name="email"
                placeholder={t('signup.email') as any}
                fullWidth
                label={t('signup.emailLabel')}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={email !== ''}
                error={!!errors.email && !!touched.email}
              />
              {errors.email && touched.email && (
                <FormHelperText id="email" error>
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              error={!!errors.phoneNumber && !!touched.phoneNumber}
            >
              <MuiPhone
                name="phoneNumber"
                value={values.phoneNumber}
                label={t('signup.phone')}
                countryOptions={countryOptions}
                onChange={setFieldValue}
                onBlur={handleBlur}
                error={!!errors.phoneNumber && !!touched.phoneNumber}
              />
              {errors.phoneNumber && touched.phoneNumber && (
                <FormHelperText id="phone" error>
                  {errors.phoneNumber}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              error={!!errors.invitationCode && !!touched.invitationCode}
            >
              <TextField
                name="invitationCode"
                placeholder={t('signup.inviteCode') as any}
                fullWidth
                label={t('signup.inviteCodeLabel')}
                value={values.invitationCode}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={code !== ''}
                error={!!errors.invitationCode && !!touched.invitationCode}
              />
              {errors.invitationCode && touched.invitationCode && (
                <FormHelperText id="fullName" error>
                  {errors.invitationCode}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              error={!!errors.locationId && !!touched.locationId}
            >
              <InputLabel disableAnimation shrink={false} focused={false}>
                {t('signup.location')}
              </InputLabel>
              <LocationSelect
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  PaperProps: {
                    sx: {
                      maxHeight: '300px',
                    },
                  },
                }}
                name="locationId"
                fullWidth
                value={values.locationId}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.locationId && !!touched.locationId}
              />
              {errors.locationId && touched.locationId && (
                <FormHelperText id="location" error>
                  {errors.locationId}
                </FormHelperText>
              )}
            </FormControl>
            {!!errorMessage && (
              <FormHelperText id="form" error>
                {errorMessage}
              </FormHelperText>
            )}
            <Box display="flex" sx={{ marginBottom: '10px' }}>
              <Checkbox
                sx={{ margin: 'auto 10px auto 0' }}
                size="small"
                onClick={() => setAgreement((prev) => !prev)}
                checked={agreement}
              />
              <Typography variant="caption">
                {t('signup.acceptTermsText')}
              </Typography>
            </Box>
            <Button
              type="submit"
              disabled={!(isValid && !isSubmitting && agreement)}
              fullWidth
              color="primary"
            >
              {isSubmitting
                ? t('signup.waitingButtonText')
                : t('signup.submitButtonText')}
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SignupOne;
