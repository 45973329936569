// import IUser from "../types/user.type";
import Http from './http.common';

class PlansService {
  apiUrl = '/plans';

  getPlans() {
    return Http.get<Array<any>>(this.apiUrl);
  }
}

export default new PlansService();
