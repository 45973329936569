import { ArrowBackIos } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import UserImage from '../../components/avatar/UserImage';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import userService from '../../services/user.service';
import {
  fetchStarted,
  resultLoaded,
  UserDataType,
  userUpdated,
} from '../../store/slices/api.slice';
import { dispatch, useTypedSelector } from '../../store/store';
import { useTranslation } from 'react-i18next';

interface IProfileEditForm {
  name: string;
  email: string;
  nickname: string;
}

const ProfileEditPage = () => {
  const { user } = useTypedSelector((state) => state.auth);
  const theme = useTheme();
  const { t } = useTranslation('profile');
  const [errorMessage, setErrorMessage] = useState(null);
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .min(3, t('validation.notValidFullName') as any)
          .required(t('validation.fullNameRequired') as any),
        nickname: Yup.string()
          .min(3, t('validation.notValidNickname') as any)
          .required(t('validation.nicknameRequired') as any),
        email: Yup.string()
          .email(t('validation.notValidEmail') as any)
          .required(t('validation.emailRequired') as any),
      }),
    [t]
  );

  const handleSubmit = (
    values: IProfileEditForm,
    actions: FormikHelpers<IProfileEditForm>
  ) => {
    setErrorMessage(null);
    dispatch(fetchStarted());

    if (!user) {
      return;
    }

    const valuesToUpdate = Object.keys(values).reduce((acc, key) => {
      if (
        values[key as keyof IProfileEditForm] !==
        user[key as keyof UserDataType]
      ) {
        acc[key] = values[key as keyof IProfileEditForm];
      }
      return acc;
    }, {} as any);

    userService
      .update({ ...valuesToUpdate })
      .then((res) => {
        dispatch(userUpdated(valuesToUpdate));
      })
      .catch((err) => {
        const { status, details, message } = err;
        if (status !== 400) {
          return setErrorMessage(message);
        }

        if (!details || !details.fields || details.fields.length === 0) {
          return setErrorMessage(message);
        }

        if (details.fields.includes('name')) {
          return actions.setFieldError('name', message);
        }

        if (details.fields.includes('email')) {
          return actions.setFieldError(
            'email',
            t('validation.emailAlreadyInUse') as any
          );
        }

        if (details.fields.includes('nickname')) {
          return actions.setFieldError(
            'email',
            t('validation.nicknameAlreadyInUse') as any
          );
        }
      })
      .finally(() => {
        dispatch(resultLoaded());
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
          display: 'flex',
          marginBottom: '32px',
        }}
      >
        {!isDesktop && (
          <ArrowBackIos
            sx={{
              marginTop: 'auto',
              marginBottom: 'auto',
              cursor: 'pointer',
            }}
            onClick={() => navigate('/perfil')}
          />
        )}
        <Typography
          variant={'h1'}
          sx={{ width: '100%', marginRight: isDesktop ? 0 : '24px' }}
        >
          {t('me')}
        </Typography>
      </Box>
      <Box
        sx={
          isDesktop
            ? {
                border: `2px solid ${theme.palette.customGrey.dark}`,
                borderRadius: '24px',
                padding: '32px 80px 80px 80px',
                width: '100%',
              }
            : {
                width: '100%',
              }
        }
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            margin: `0 auto ${isDesktop ? '50px' : '16px'} auto`,
            width: '-webkit-fit-content',
          }}
        >
          <UserImage />
        </Box>
        <Formik
          initialValues={{
            name: user ? user.name : '',
            email: user ? user.email : '',
            nickname: user ? user.nickname : '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            handleChange,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth error={!!errors.name && !!touched.name}>
                <Typography variant="h3">{t('fullName')}</Typography>
                <TextField
                  name="name"
                  placeholder={t('fullName') as any}
                  fullWidth
                  label={t('fullName')}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.name && !!touched.name}
                  disabled={true}
                />
                {errors.name && touched.name && (
                  <FormHelperText id="name" error>
                    {errors.name}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth error={!!errors.email && !!touched.email}>
                <Typography variant="h3">{t('email')}</Typography>
                <TextField
                  name="email"
                  placeholder={t('email') as any}
                  fullWidth
                  label={t('emailLabel')}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.email && !!touched.email}
                />
                {errors.email && touched.email && (
                  <FormHelperText id="email" error>
                    {errors.email}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                fullWidth
                error={!!errors.nickname && !!touched.nickname}
              >
                <Typography variant="h3">
                  {t('initialSetup.nickname')}
                </Typography>
                <TextField
                  name="nickname"
                  placeholder={t('initialSetup.nickname') as any}
                  fullWidth
                  label={t('initialSetup.nickname')}
                  value={values.nickname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.nickname && !!touched.nickname}
                />
                {errors.nickname && touched.nickname && (
                  <FormHelperText id="nickname" error>
                    {errors.nickname}
                  </FormHelperText>
                )}
              </FormControl>
              {!!errorMessage && (
                <FormHelperText id="form" error>
                  {errorMessage}
                </FormHelperText>
              )}
              <Button
                type="submit"
                disabled={!(isValid && dirty && !isSubmitting)}
                fullWidth
                color="primary"
              >
                {isSubmitting ? t('processingButtonText') : t('saveButtonText')}
              </Button>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default ProfileEditPage;
