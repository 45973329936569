import { ArrowBackIos } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TrashIcon } from '../../assets/svgs';
import LoadingScreen from '../../components/LoadingScreen';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import NotificationService from '../../services/notification.service';
import {
  deleteNotification,
  ENotificationStatus,
  setNotifications,
  setUnreadCount,
} from '../../store/slices/notifications.slice';
import { useDispatch, useTypedSelector } from '../../store/store';
import { toLocaleDateString } from '../../utils/format-date';
import { Container, Icon, Notification, TrashBox } from './components';
import { notificationConfig } from './constants';
import { useTranslation } from 'react-i18next';
import EmptyStateLayout from '../../layouts/EmptyStateLayout';

const Notifications = () => {
  const { t, i18n } = useTranslation('notifications');
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [newMsgIds, setNewMsgIds] = useState<number[]>([]);

  const { data: notifications } = useTypedSelector(
    (state) => state.notification
  );

  useEffect(() => {
    setIsLoading(true);
    NotificationService.getAllNotifications()
      .then((resp) => {
        dispatch(setNotifications(resp.data));

        const ids = resp.data
          .filter((item: any) => item.status === ENotificationStatus.NEW)
          .map((item: any) => item.id);

        const newNotificationIds = resp.data
          .filter((item: any) => item.status === ENotificationStatus.NEW)
          .map((item: any) => item.notification.id);

        setNewMsgIds(newNotificationIds);

        if (ids.length) {
          NotificationService.readMany(ids)
            .then((resp) => {
              dispatch(setUnreadCount(0));
            })
            .catch((e) => console.error(e));
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsLoading(false));
  }, []);

  const handleNotificationDelete = (id: string) => {
    NotificationService.deleteNotification(id)
      .then(() => {
        dispatch(deleteNotification(id));
      })
      .catch((e) => {
        toast.error(e);
        console.error(e);
      });
  };

  const isNewMessage = (id: string): boolean => {
    return newMsgIds.includes(Number(id));
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Container>
          {!isDesktop && (
            <Box
              sx={{
                textAlign: 'center',
                display: 'flex',
                marginBottom: '32px',
                width: '100%',
              }}
            >
              <ArrowBackIos
                sx={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  cursor: 'pointer',
                }}
                onClick={() => navigate(-1)}
              />
              <Typography
                variant={'h1'}
                sx={{ width: '100%', marginRight: isDesktop ? 0 : '24px' }}
              >
                {t('title')}
              </Typography>
            </Box>
          )}

          <EmptyStateLayout lengthOfData={notifications.length}>
            <>
              {notifications.map(({ type, meta, createdAt, id }) => (
                <Notification
                  theme={theme}
                  key={`notification-${id}`}
                  isNewMessage={isNewMessage(id)}
                >
                  <Icon src={notificationConfig[type]?.icon} />
                  <Box
                    width="70%"
                    display="flex"
                    alignItems="center"
                    justifyContent={isDesktop ? 'start' : 'center'}
                  >
                    {isDesktop ? (
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                      >
                        {t(`${notificationConfig[type]?.text}`, {
                          meta: meta?.name,
                        })}
                      </Typography>
                    ) : (
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        align="center"
                      >
                        {t(`${notificationConfig[type]?.text}`, {
                          meta: meta?.name,
                        })}
                      </Typography>
                    )}
                    {isDesktop && (
                      <Typography variant="body2">
                        {'\u00A0'}
                        {toLocaleDateString(createdAt, i18n.language)}
                      </Typography>
                    )}
                  </Box>
                  <TrashBox onClick={() => handleNotificationDelete(id)}>
                    <TrashIcon />
                  </TrashBox>
                </Notification>
              ))}
            </>
          </EmptyStateLayout>
        </Container>
      )}
    </>
  );
};

export default Notifications;
