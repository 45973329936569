const header = {
  primary: '#7E84A6',
  color: '#7E84A6',
  background: '#293951',
  indicator: {
    background: '#FFA800',
  },
  info: {
    greetings: {
      color: '#C9C7E4',
    },
  },
};

export default header;
