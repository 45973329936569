import { Avatar, Box, CircularProgress, useTheme } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { EditIcon, ErrorIcon, SuccessIcon } from '../../assets/svgs';
import userService from '../../services/user.service';
import { userUpdated } from '../../store/slices/api.slice';
import { dispatch, useTypedSelector } from '../../store/store';
import { nameToAvatar, nameToColor } from '../../utils/avatar';
import { FileInput } from './components';
import ImageCropperModal from './ImageCropModal';
import { useTranslation } from 'react-i18next';

const MAX_AVATAR_IMAGE_SIZE = 5242880; // 5 Mb
export enum EStatuses {
  initial = 'initial',
  success = 'success',
  failed = 'failed',
}

const UserImage: React.FC<any> = () => {
  const { user } = useTypedSelector((state) => state.auth);
  const theme = useTheme();
  const { t } = useTranslation('profile');
  const fileInput = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<string | null>(null);
  const [isOpenCropper, setCropperState] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [status, setStatus] = useState<EStatuses>(EStatuses.initial);

  const onUploadBtnClick = useCallback(() => {
    setStatus(EStatuses.initial);
    if (fileInput?.current) {
      fileInput.current.click();
    }
  }, [fileInput]);

  const onImageUpload = useCallback((e: any) => {
    e.preventDefault();
    const files = e.target.files;
    if (files[0] && files[0].size < MAX_AVATAR_IMAGE_SIZE) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as any);
        setCropperState(true);
      };
      reader.readAsDataURL(files[0]);
    } else {
      toast.warn(t('validation.bigImage'));
    }
  }, []);

  const handleCropperClose = useCallback(
    async (image?: string, blob?: Blob) => {
      if (fileInput?.current?.value) {
        fileInput.current.value = '';
      }

      if (blob) {
        setSubmitting(true);
        const formData = new FormData();
        formData.append('avatar', blob as Blob, 'avatar_image.jpeg');
        try {
          const resp = await userService.uploadAvatar(formData);
          dispatch(userUpdated({ avatarUrl: resp.data.url }));
          setStatus(EStatuses.success);
        } catch (error: any) {
          console.error(error);
          setStatus(EStatuses.failed);
        }
        setSubmitting(false);
      }
      setCropperState(false);
    },
    []
  );

  return (
    <Box sx={{ cursor: 'pointer' }}>
      <FileInput
        type="file"
        accept=".png, .jpeg, .jpg, .PNG, .JPEG, .JPG"
        ref={fileInput}
        onChange={onImageUpload}
      />
      {isSubmitting ? (
        <Box
          height={212}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box position="relative">
          <Avatar
            onClick={onUploadBtnClick}
            sx={{
              bgcolor: nameToColor(user?.name),
            }}
            src={user?.avatarUrl}
            {...nameToAvatar(user?.name)}
          />
          <Box
            sx={{
              padding: status === EStatuses.initial ? '8px' : '0px',
              marginTop: '-30%',
              marginLeft: '65%',
              borderRadius: '50px',
              position: 'absolute',
              backgroundColor:
                status === EStatuses.initial
                  ? theme.palette.primary.main
                  : 'none',
              border:
                status === EStatuses.initial
                  ? `2px solid ${theme.palette.background.default}`
                  : 'none',
            }}
            onClick={onUploadBtnClick}
          >
            {status === EStatuses.initial && <EditIcon />}
            {status === EStatuses.success && <SuccessIcon />}
            {status === EStatuses.failed && <ErrorIcon />}
          </Box>
        </Box>
      )}
      <ImageCropperModal
        visible={isOpenCropper}
        image={image || ''}
        close={handleCropperClose}
      />
    </Box>
  );
};

export default UserImage;
