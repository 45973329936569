import { Typography, useTheme } from '@mui/material';
import React from 'react';
import { useIsDesktop } from '../../../hooks/useIsDesktop';
import { Container } from '../components';

interface IPayoutBlockProps {
  total: number;
  label: string;
}

const PayoutBlock: React.FC<IPayoutBlockProps> = ({ total, label }) => {
  const theme = useTheme();
  const isDesktop = useIsDesktop();

  return (
    <Container
      theme={theme}
      style={{
        minHeight: isDesktop ? '131px' : '83px',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Typography fontWeight="bold">{label}</Typography>
      <Typography variant="h2" color={'#20D73E'}>{`${total}€`}</Typography>
    </Container>
  );
};

export default PayoutBlock;
