import { Paper, Typography } from '@mui/material';
import styled from 'styled-components';
import { desktopOnly } from '../../../hooks/useIsDesktop';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProviderContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 28px;
  gap: 16px;
  margin-top: -28px;
  z-index: 2;
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
`;

export const RecommendationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  max-width: 100%;
  overflow-x: scroll;
`;

export const ValidationContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.5px #d4d4d4 solid;
  padding: 8px 16px;
  border-radius: 8px;

  ${desktopOnly} {
    padding: 24px;
  }
`;

export const OfferValida = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: row;
`;

export const DesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 128px 20% 0;
  gap: 32px;
`;

export const DaysTypography = styled(Typography)`
  font-size: 12px;
  padding: 4.5px 12px;
  border-radius: 40px;
  font-weight: 400;
  background-color: #ff9d2a;
  color: #fff;
`;

export const RecommendationName = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  overflow-wrap: break-word;
  max-width: 100%;
`;
export const RecommendationContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 8px;
  flex: 0.33;
  gap: 24px;
  background-color: ${({ theme }) => theme.palette.customGrey.dark} !important;
`;
