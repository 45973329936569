import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useIsDesktop } from '../../hooks/useIsDesktop';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    '@media (min-width:1007px)': {
      paddingLeft: '140px',
      paddingRight: '140px',
    },
  },
  emojiContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '32px',
    width: '100%',
    flexDirection: 'column',
    marginBottom: '24px',
    '@media (min-width:1007px)': {
      flexDirection: 'row',
      marginBottom: '57px',
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '24px',
    width: 'calc(100% - 48px)',
    flexDirection: 'column',
    marginBottom: '24px',
    '@media (min-width:1007px)': {
      marginBottom: '100px',
      flexDirection: 'row',
      width: '100%',
    },
  },
  copyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  emoji: {
    fontSize: '78px',
    lineHeight: '66px',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '30px',
  },
}));

const TextWithEmojiButtons = () => {
  const classes = useStyles();
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const { t } = useTranslation('landing');

  return (
    <div className={classes.container}>
      <Typography
        sx={{
          fontWeight: 400,
          marginBottom: isDesktop ? '32px' : '24px',
          marginTop: isDesktop ? '96px' : '24px',
          lineHeight: '25px',
        }}
        align="center"
        color="neutral.main"
        variant="h3"
      >
        {t('Block3 Header')}
      </Typography>
      <Typography
        align="center"
        sx={{
          fontSize: '52px !important',
          fontWeight: 800,
          marginBottom: isDesktop ? '100px' : '24px',
          lineHeight: '62.5px',
        }}
        variant="h2"
      >
        {t('Title3')}
      </Typography>
      <div className={classes.emojiContainer}>
        <div className={classes.copyContainer}>
          <div className={classes.emoji}>💌</div>
          <Typography
            align="center"
            sx={{
              fontSize: '18px',
              fontWeight: 400,
            }}
            variant="h3"
          >
            <Trans i18nKey="Feature1" t={t} />
          </Typography>
        </div>
        <div className={classes.copyContainer}>
          <div className={classes.emoji}>💸</div>
          <Typography
            align="center"
            sx={{
              fontSize: '18px',
              fontWeight: 400,
            }}
            variant="h3"
          >
            <Trans i18nKey="Feature2" t={t} />
          </Typography>
        </div>
        <div className={classes.copyContainer}>
          <div className={classes.emoji}>🤩</div>
          <Typography
            align="center"
            sx={{
              fontSize: '18px',
              fontWeight: 400,
            }}
            variant="h3"
          >
            <Trans i18nKey="Feature3" t={t} />
          </Typography>
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        <Button
          color="primary"
          sx={{
            padding: '16px 24px',
            fontSize: '16px !important',
            fontWeight: 500,
            maxHeight: '52px',
          }}
          onClick={() => navigate('/signup')}
        >
          {t('Sign Up')}
        </Button>
        <Button
          sx={{
            padding: '16px 24px',
            borderColor: '#1D1D1D',
            fontSize: '16px !important',
            fontWeight: 500,
            maxHeight: '52px',
          }}
          color="secondary"
          onClick={() => navigate('/login')}
          size="small"
        >
          {t('Login')}
        </Button>
      </div>
    </div>
  );
};

export default TextWithEmojiButtons;
