import { Box, Typography, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import moment from 'moment/moment';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MasterCardIcon, VisaIcon, ValidFieldIcon } from '../../assets/svgs';
import useAuthContext from '../../hooks/useAuthContext';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import StripeService from '../../services/stripe.service';
import { CardImage, FieldCorrectImage, Form } from './components';
import { StartSubscriptionAt } from '../../enums/start-subscription-at.enum';

const useOptions = (theme: any) => {
  return {
    style: {
      base: {
        height: '48px',
        borderRadius: '8px',
        backgroundColor: `${(theme as Theme).palette.customGrey.dark}`,
        color: `${(theme as Theme).palette.customGrey.contrastText}`,
        borderColor: `${(theme as Theme).palette.customGrey.dark}`,
        fontSize: '18px',
        fontFamily: 'sans-serif',

        '::placeholder': {
          color: `${(theme as Theme).palette.neutral.dark}`,
          fontWeight: 300,
        },

        '@media (min-width:700px)': {
          height: '62px',
          fontSize: '20px',
        },
      },
      invalid: {
        color: `${(theme as Theme).palette.error.main}`,
        borderColor: `${(theme as Theme).palette.error.main}`,
        borderWidth: '1px',
      },
    },
  };
};
const PaymentForm = forwardRef((callback: any, ref) => {
  const { stripeCustomerInfo } = useAuthContext();
  const theme = useTheme();
  const options = useOptions(theme);
  const stripe = useStripe();
  const elements = useElements();
  const isDesktop = useIsDesktop();
  const [cardType, setCardType] = useState(null);
  const [isFieldCompleteCvv, setIsFieldCompleteCvv] = useState(false);
  const [isFieldCompleteExpiry, setIsFieldCompleteExpiry] = useState(false);
  const { t } = useTranslation('login');

  const handleCardChange = (event: any) => {
    if (event.empty) {
      return setCardType(null);
    }

    const card = event.brand;
    setCardType(card);
  };

  const handleFieldCorrect = (event: any) => {
    if (!event.complete) {
      return event.elementType === 'cardCvc'
        ? setIsFieldCompleteCvv(false)
        : setIsFieldCompleteExpiry(false);
    }

    event.elementType === 'cardCvc'
      ? setIsFieldCompleteCvv(true)
      : setIsFieldCompleteExpiry(true);
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      return callback.callback(false, error.message as string);
    }

    try {
      const returnUrl = new URL(`${window.location.origin}/payment-success`);

      const { data } = await StripeService.subscribe({
        paymentMethod: paymentMethod?.id,
        planId: Number(stripeCustomerInfo.selectedPlan.id),
        startSubscriptionAt:
          stripeCustomerInfo.planStart === StartSubscriptionAt.Today
            ? moment().add('month', 1).unix()
            : moment().endOf('month').add('minute', 1).unix(),
        returnUrl: returnUrl.toString(),
      });

      if (data?.redirectUrl) {
        window.location.replace(data.redirectUrl);
      } else {
        callback.callback(true);
      }
    } catch (e: any) {
      callback.callback(false, e.message);
    }
  };

  useImperativeHandle(ref, () => ({
    callSubmit() {
      handleSubmit();
    },
  }));

  return (
    <Form theme={theme}>
      <Typography
        variant="body1"
        sx={{ fontWeight: 700, marginBottom: isDesktop ? '16px' : '8px' }}
      >
        <Trans i18nKey="payment.cardNumber" t={t} />
      </Typography>
      <Box position="relative">
        <CardNumberElement
          onChange={handleCardChange}
          options={{
            ...options,
            placeholder: '',
          }}
          className="card-elem"
        />
        {cardType && (
          <CardImage>
            {cardType === 'visa' ? <VisaIcon /> : <MasterCardIcon />}
          </CardImage>
        )}
      </Box>
      <Box display="flex" sx={{ margin: '10px 0' }}>
        <Box sx={{ marginRight: '10px', width: '50%' }}>
          <Typography
            variant="body1"
            sx={{ fontWeight: 700, marginBottom: isDesktop ? '16px' : '8px' }}
          >
            <Trans i18nKey="payment.expiration" t={t} />
          </Typography>
          <Box position="relative">
            <CardExpiryElement
              options={{
                ...options,
                placeholder: '',
              }}
              onChange={handleFieldCorrect}
              className="card-elem"
            />
            {isFieldCompleteExpiry && (
              <FieldCorrectImage>
                <ValidFieldIcon />
              </FieldCorrectImage>
            )}
          </Box>
        </Box>
        <Box width="50%">
          <Typography
            variant="body1"
            sx={{ fontWeight: 700, marginBottom: isDesktop ? '16px' : '8px' }}
          >
            <Trans i18nKey="payment.cvv" t={t} />
          </Typography>
          <Box position="relative">
            <CardCvcElement
              options={{
                ...options,
                placeholder: '',
              }}
              onChange={handleFieldCorrect}
              className="card-elem cvc-element"
            />
            {isFieldCompleteCvv && (
              <FieldCorrectImage>
                <ValidFieldIcon />
              </FieldCorrectImage>
            )}
          </Box>
        </Box>
      </Box>
    </Form>
  );
});

export default PaymentForm;
