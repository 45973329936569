// import IUser from "../types/user.type";
import Http from './http.common';
import { DateTime } from 'luxon';

export enum EChartGrouping {
  day = 'DAY',
  week = 'WEEK',
  month = 'MONTH',
}

class InfluencerService {
  apiUrl = '/referrals';

  getPayout() {
    return Http.get<Array<any>>(this.apiUrl + '/payout');
  }

  getChart(grouping: EChartGrouping = EChartGrouping.day) {
    return Http.get<any>(this.apiUrl + `/chart?grouping=${grouping}`);
  }

  getUsers(data: any) {
    return Http.post<any>(this.apiUrl + '/users', data);
  }

  getWeeksPayout(id: any) {
    return Http.get<any>(this.apiUrl + `/weeks-payout`);
  }

  getAllData() {
    return Http.get<any>(this.apiUrl + '/all', {
      params: {
        grouping: 'WEEK',
        lastPayoutsInterval: {
          startTimestamp: DateTime.now().minus({week: 1}).startOf('week').valueOf(),
          endTimestamp: DateTime.now().endOf('week').valueOf(),
        },
      },
    });
  }
}

export default new InfluencerService();
