import styled from 'styled-components';
import { desktopOnly } from '../../hooks/useIsDesktop';

export const Form = styled.form<{ theme: any }>`
  width: 100%;

  .card-elem {
    height: 48px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.customGrey.dark};
    color: ${({ theme }) => theme.palette.customGrey.contrastText};
    padding: 15px 16px;
    margin-bottom: 16px;
    font-size: 14px !important;

    &.StripeElement--invalid {
      border-color: ${({ theme }) => theme.palette.error.main} !important;
      border-width: 2px !important;
    }

    @media (min-width: 700px) {
      height: 72px;
      padding: 24px 24px;
      font-size: 20px !important;
      margin-bottom: 24px;
    }

    .ElementsApp {
      font-size: 20px !important;
    }

    .InputElement {
      font-size: 14px !important;

      ${desktopOnly} {
        font-size: 20px !important;
      }
    }

    &.cvc-element {
      .ElementsApp {
        -webkit-text-security: disc !important;
      }
    }
  }
`;

export const CardImage = styled.div`
  position: absolute;
  right: 10px;
  top: 15px;
  width: 32px;
  height: 18px;

  svg {
    width: 32px;
    height: 18px;
  }

  ${desktopOnly} {
    width: 56px;
    height: 32px;
    top: 19px;

    svg {
      width: 56px;
      height: 32px;
    }
  }
`;

export const FieldCorrectImage = styled.div`
  position: absolute;
  right: 10px;
  top: 12px;
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
  }

  ${desktopOnly} {
    width: 56px;
    height: 32px;
    top: 19px;

    svg {
      width: 56px;
      height: 32px;
    }
  }
`;
