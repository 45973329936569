import { ReactElement, FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EmptyStateImage from '../assets/svgs/Empty-State.svg';
import styled from 'styled-components';
import { desktopOnly } from '../hooks/useIsDesktop';

interface IEmptyStateLayout {
  lengthOfData: number;
  children: ReactElement;
}

const ImageContainer = styled.div`
  margin: 5% 25% 5% 25%;

  ${desktopOnly} {
    margin: 5% 40% 5% 40%;
  }
`;

const EmptyStateLayout: FC<IEmptyStateLayout> = ({
  lengthOfData,
  children,
}) => {
  const { t } = useTranslation('emptyState');
  if (!lengthOfData) {
    return (
      <Stack
        width="100%"
        height="auto"
        justifyContent="center"
        alignItems="center"
      >
        <ImageContainer><img src={EmptyStateImage} alt='Empty State'/></ImageContainer>
        <Typography variant="h2">{t('emptyState')}</Typography>
      </Stack>
    );
  }
  return <>{children}</>;
};

export default EmptyStateLayout;
